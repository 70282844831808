import React, { useState } from 'react'
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getEmailID } from './core/_request';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Footer.css'
import InstagramIcon from '@mui/icons-material/Instagram';

function Footer() {
  const [subscribeMessage, setSubscribeMessage] = useState('')
  const [email, setEmail] = useState('');
  const onClickSubmit = () => {
    var body = {
      "email": email
    }
    getEmailID(body).then(resp => {
      // console.log("resp", resp)
      toast(resp?.ResponseMessage);
      if (resp.ResponseCode == 200) {
        setEmail('')
      }
    });
  }

  const onSubscribeClick = () => {
    fetch(`https://odoo.unitedwecare.ca/new-uwc/community/newsletter/subscribe/${email}`)
      .then(response => response)
      .then(data => setSubscribeMessage(data))
  }

  return (
    <div className='pt-1 pt-md-2 footer--container d-none' style={{ background: "", /*marginTop: "-10px" */ }}>
      <footer className='py-1'>
        <div className='row justify-content-between pb-3 py-2'>
          <Col className='my-2' md={3}>
            <Row className='col-12 d-block mx-auto'>
              <a href="https://www.unitedwecare.com/" className="text-decoration-none">
                <img
                  src="/assets/img/kareify/kareifyLarge.png"
                  alt=""
                  width={150}
                  height={65}
                  style={{ objectFit: "contain" }}
                />
              </a>
            </Row>
            <Row>
              <div className='col-12'>
                {/* <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-1'>Download</h6>
                <div className='d-flex align-items-center gap-1 justify-content-start'>
                  <a href='https://apps.apple.com/in/app/united-for-her/id1501325167' target='_blank' rel='noreferrer' className=''>
                    <img src='/assets/img/appleStore.png' alt='' />
                  </a>
                  <a href='https://play.google.com/store/apps/details?id=com.UnitedFor.Her&hl=en_IN' target='_blank' rel='noreferrer' className=''>
                    <img src='/assets/img/playStore.png' alt='' className='h-100' />
                  </a>
                </div> */}
                <div className='d-none d-md-block mt-3'>
                  <h6 style={{ fontSize: "26px", color: "#4c4c4c" }} className='font-inter font-w600 py-1'>
                    Subscribe to our Newsletter
                  </h6>
                  <InputGroup className='mb-3 lh-1 rounded-5 d-flex flex-column gap-3'>
                    <Form.Control onChange={e => setEmail(e.target.value)} className="rounded-5" style={{ height: "50px", width: "100%" }} type="text" placeholder="Enter your Email..." />
                    <Button className='font-inter fs-6 text-white rounded-5 border-0' style={{ background: "#38AB45", height: "50px" }} onClick={onSubscribeClick}>Submit</Button>
                  </InputGroup>
                  {subscribeMessage && <p className='font-inter fs-5 font-w600 text-success lh-lg'>Thank You for Subscribing!</p>}
                </div>
                <div className='d-block d-md-none'>
                  <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-1'>Download the app</h6>
                  <div className='d-flex align-items-center gap-1 justify-content-start'>
                    <a href='https://apps.apple.com/in/app/united-for-her/id1501325167' target='_blank' rel='noreferrer' className='col-6'>
                      <img src='/assets/img/kareify/appStore.svg' alt='' className='col-10' />
                    </a>
                    <a href='https://play.google.com/store/apps/details?id=com.UnitedFor.Her&hl=en_IN' target='_blank' rel='noreferrer' className='col-6'>
                      <img src='/assets/img/kareify/playStore.svg' alt='' className='col-10' />
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className='col-6'>
                <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-1'>Follow us on</h6>
                <div className='d-flex gap-2'>
                  <a href='https://instagram.com/unitedwecare_global' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <img src='/assets/img/instagram.png' alt='' />
                  </a>
                  <a href='https://www.facebook.com/unitedwecareglobal' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <img src='/assets/img/facebook.png' alt='' />
                  </a>
                  <a href='https://twitter.com/unitedwecare_in' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <img src='/assets/img/twitter.png' alt='' />
                  </a>
                  <a href='https://youtube.com/@unitedwecare6397' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <img src='/assets/img/yt.png' alt='' />
                  </a>
                  <a href='https://in.linkedin.com/company/unitedwecare' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <img src='/assets/img/Social icon.png' alt='' />
                  </a>
                </div>
              </div> */}
            </Row>
          </Col>
          <Col className='my-2 my-md-0 px-md-5' md={6}>
            <Row>
              <div className='col-6 col-md-4 footer-Col-Container'>
                <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-2'>Company</h6>
                <a href='https://www.kareify.com/about-us/' className='text-decoration-none lh-lg' target='_blank' rel='noreferrer'>
                  <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >About Us</p>
                </a>
                <a href='https://www.kareify.com/careers/' className='text-decoration-none' target='_blank' rel='noreferrer'>
                  <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Careers</p>
                </a>
                <a href='https://www.kareify.com/contact/' className='text-decoration-none' target='_blank' rel='noreferrer'>
                  <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Contact Us</p>
                </a>
                <a href='https://www.kareify.com/faq' className='text-decoration-none' target='_blank' rel='noreferrer'>
                  <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >FAQ</p>
                </a>
                <Link to="https://www.kareify.com/terms-and-conditions/" target='_blank' className='text-decoration-none' href='#!'>
                  <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Terms & Conditions</p>
                </Link>
                <Link to="https://www.kareify.com/privacy-policy" target='_blank' className='text-decoration-none' href='#!'>
                  <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Privacy Policy</p>
                </Link>
                <Col className='d-block d-md-none my-3'>
                  <a href='https://us.kareify.com/register-as-professional' className='text-decoration-none' target='' rel='noreferrer'>
                    <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-2'>Get onboarded as expert</h6>
                  </a>
                </Col>
              </div>
              <div className='col-6 col-md-4 footer-Col-Container'>
                <Col className='d-none'>
                  
                  {/* <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 lh-lg mb-0'>Care</h6>
                  <a href='/' className='text-decoration-none' target='' rel='noreferrer'>
                    <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Professionals</p>
                  </a>
                  <a href='/course' className='text-decoration-none' target='' rel='noreferrer'>
                    <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Programs</p>
                  </a> */}
                </Col>
                <Col className=''>
                  <h6 style={{ fontSize: "16px" }} className='py-2'>Care</h6>
                  <a href='/' className='text-decoration-none' target='' rel='noreferrer'>
                    <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Professionals</p>
                  </a>
                  <a href='/course' className='text-decoration-none' target='' rel='noreferrer'>
                    <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Programs</p>
                  </a>
                  <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-2'>Resource Center</h6>
                  <a href='https://www.kareify.com/blog' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Blogs</p>
                  </a>
                  <a href='https://www.kareify.com/explore' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Wellness Library</p>
                  </a>
                </Col>
                <div className='col-md-4 d-block d-md-none'>
                  {/* <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-2'>Resource Center</h6>
                  <a href='https://www.unitedwecare.com/blog/' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Blogs</p>
                  </a>
                  <a href='https://www.unitedwecare.com/explore/' className='text-decoration-none' target='_blank' rel='noreferrer'>
                    <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Wellness Library</p>
                  </a> */}
                  <Col className='my-3 d-none d-md-block footer-Col-Container'>
                    <a href='https://us.kareify.com/register-as-professional' className='text-decoration-none' target='' rel='noreferrer'>
                      <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-2'>Get onboarded as expert</h6>
                    </a>
                  </Col>
                  <Col className='my-3 footer-Col-Container'>
                    <a href='/plans' className='text-decoration-none' target='' rel='noreferrer'>
                      <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-2'>Plans and Pricing</h6>
                    </a>
                  </Col>
                </div>
              </div>
              <div className='col-md-4 d-none d-md-block'>
                {/* <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-2'>Resource Center</h6>
                <a href='https://www.unitedwecare.com/blog/' className='text-decoration-none' target='_blank' rel='noreferrer'>
                  <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Blogs</p>
                </a>
                <a href='https://www.unitedwecare.com/explore/' className='text-decoration-none' target='_blank' rel='noreferrer'>
                  <p className='font-inter font-w500 lh-base' style={{ fontSize: "14px", color: "#454745" }} >Wellness Library</p>
                </a> */}
                <Col className='footer-Col-Container'>
                  <a href='https://us.kareify.com/register-as-professional' className='text-decoration-none' target='' rel='noreferrer'>
                    <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-2'>Get onboarded as expert</h6>
                  </a>
                </Col>
                <Col className='my-3 footer-Col-Container'>
                  <a href='/plans' className='text-decoration-none' target='' rel='noreferrer'>
                    <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-2'>Plans and Pricing</h6>
                  </a>
                </Col>
              </div>
            </Row>
          </Col>
          <Col className='my-2' md={3}>
            <div className='d-none d-md-block'>
              <h6 style={{ fontSize: "16px" }} className='font-inter font-w600 py-1'>Download the app</h6>
              <div className='d-flex align-items-center gap-1 justify-content-start'>
                <a href='https://apps.apple.com/in/app/united-for-her/id1501325167' target='_blank' rel='noreferrer' className='col-6'>
                  <img src='/assets/img/kareify/appStore.svg' alt='' className='col-10' />
                </a>
                <a href='https://play.google.com/store/apps/details?id=com.UnitedFor.Her&hl=en_IN' target='_blank' rel='noreferrer' className='col-6'>
                  <img src='/assets/img/kareify/playStore.svg' alt='' className='col-10' />
                </a>
              </div>
            </div>
            <div className='d-block d-md-none'>
              <h6 style={{ fontSize: "16px" }} className='font-inter fs-6 font-w600 py-1'>
                Subscribe to our Newsletter
              </h6>
              <InputGroup className='mb-3 lh-1 rounded-5 d-flex flex-column gap-3'>
                <Form.Control onChange={e => setEmail(e.target.value)} className="rounded-5" style={{ height: "50px", width: "100%" }} type="text" placeholder="Enter your Email..." />
                <Button className='font-inter fs-6 text-white rounded-5 border-0' style={{ background: "#38AB45", height: "50px" }} onClick={onSubscribeClick}>Submit</Button>
              </InputGroup>
              {subscribeMessage && <p className='font-inter fs-5 font-w600 text-success lh-lg'>Thank You for Subscribing!</p>}
            </div>
            <div className='d-flex align-items-center justify-content-center gap-3 my-5 d-md-none'>
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20" fill="none"><path d="M17.9548 0.25H21.2628L14.0358 8.51L22.5378 19.75H15.8798L10.6658 12.933L4.69984 19.75H1.38984L9.11984 10.915L0.964844 0.25H7.78984L12.5028 6.481L17.9528 0.25H17.9548ZM16.7938 17.77H18.6268L6.79484 2.126H4.82784L16.7938 17.77Z" fill="black"></path></svg>
              <InstagramIcon />
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><g clip-path="url(#clip0_4679_1904)"><path d="M23.2616 6.50206C23.1363 6.01744 22.8908 5.57558 22.5498 5.2207C22.2088 4.86583 21.7842 4.6104 21.3185 4.47997C20.0458 3.96829 7.71595 3.71761 4.13877 4.49428C3.67301 4.62476 3.24835 4.88028 2.90733 5.23525C2.56631 5.59023 2.32088 6.03221 2.19563 6.51694C1.62089 9.13999 1.57744 14.8108 2.20993 17.4928C2.33527 17.9774 2.58073 18.4192 2.92175 18.7741C3.26276 19.129 3.68737 19.3844 4.15307 19.5148C6.67371 20.1187 18.657 20.2034 21.3328 19.5148C21.7985 19.3844 22.2231 19.129 22.5641 18.7741C22.9051 18.4192 23.1506 17.9774 23.2759 17.4928C23.8886 14.6351 23.9321 9.3157 23.2616 6.50206Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M16.0331 11.9994L10.8096 9.00391V14.9949L16.0331 11.9994Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"></path></g><defs><clipPath id="clip0_4679_1904"><rect width="24" height="24" fill="white" transform="translate(0.75)"></rect></clipPath></defs></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none"><path d="M6.82143 9.72321V17.3125M15.9286 17.3125V13.5179C15.9286 12.7127 15.6087 11.9406 15.0394 11.3713C14.4701 10.802 13.698 10.4821 12.8929 10.4821C12.0877 10.4821 11.3156 10.802 10.7463 11.3713C10.177 11.9406 9.85714 12.7127 9.85714 13.5179V17.3125V9.72321M6.0625 7.44643H7.58036M2.26786 1.375H20.4821C20.8847 1.375 21.2708 1.53492 21.5554 1.81957C21.8401 2.10422 22 2.4903 22 2.89286V21.1071C22 21.5097 21.8401 21.8958 21.5554 22.1804C21.2708 22.4651 20.8847 22.625 20.4821 22.625H2.26786C1.8653 22.625 1.47922 22.4651 1.19457 22.1804C0.909917 21.8958 0.75 21.5097 0.75 21.1071V2.89286C0.75 2.4903 0.909917 2.10422 1.19457 1.81957C1.47922 1.53492 1.8653 1.375 2.26786 1.375Z" stroke="black" stroke-width="1.22968"></path></svg>
            </div>
            <h6 style={{ fontSize: "16px" }} className='font-inter fs-6 font-w600 py-1 text-center text-md-start mt-md-5'>
              Your Privacy is Our Priority
            </h6>
            <div className='d-flex gap-3 justify-content-md-start justify-content-center'>
              <img src='/assets/img/iso.webp.png' alt='' />
              <img src='/assets/img/hippa.webp.png' alt='' />
              {/* <img src='/assets/img/eu.webp.png' alt='' /> */}
            </div>
          </Col>
        </div>
      </footer>
      <div className='d-flex align-items-center justify-content-between' style={{ backgroundColor: '#FFFFFF' }}>
        <a className='text-decoration-none text-center text-md-start footer-rights-text font-w500' href='#!' style={{ width: '100%' }}>
          © 2023 Kareify. All Rights Reserved.
        </a>

        <div className='d-none align-items-center justify-content-center gap-3 my-3 d-md-flex'>
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20" fill="none"><path d="M17.9548 0.25H21.2628L14.0358 8.51L22.5378 19.75H15.8798L10.6658 12.933L4.69984 19.75H1.38984L9.11984 10.915L0.964844 0.25H7.78984L12.5028 6.481L17.9528 0.25H17.9548ZM16.7938 17.77H18.6268L6.79484 2.126H4.82784L16.7938 17.77Z" fill="black"></path></svg>
          <InstagramIcon />
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><g clip-path="url(#clip0_4679_1904)"><path d="M23.2616 6.50206C23.1363 6.01744 22.8908 5.57558 22.5498 5.2207C22.2088 4.86583 21.7842 4.6104 21.3185 4.47997C20.0458 3.96829 7.71595 3.71761 4.13877 4.49428C3.67301 4.62476 3.24835 4.88028 2.90733 5.23525C2.56631 5.59023 2.32088 6.03221 2.19563 6.51694C1.62089 9.13999 1.57744 14.8108 2.20993 17.4928C2.33527 17.9774 2.58073 18.4192 2.92175 18.7741C3.26276 19.129 3.68737 19.3844 4.15307 19.5148C6.67371 20.1187 18.657 20.2034 21.3328 19.5148C21.7985 19.3844 22.2231 19.129 22.5641 18.7741C22.9051 18.4192 23.1506 17.9774 23.2759 17.4928C23.8886 14.6351 23.9321 9.3157 23.2616 6.50206Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M16.0331 11.9994L10.8096 9.00391V14.9949L16.0331 11.9994Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"></path></g><defs><clipPath id="clip0_4679_1904"><rect width="24" height="24" fill="white" transform="translate(0.75)"></rect></clipPath></defs></svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none"><path d="M6.82143 9.72321V17.3125M15.9286 17.3125V13.5179C15.9286 12.7127 15.6087 11.9406 15.0394 11.3713C14.4701 10.802 13.698 10.4821 12.8929 10.4821C12.0877 10.4821 11.3156 10.802 10.7463 11.3713C10.177 11.9406 9.85714 12.7127 9.85714 13.5179V17.3125V9.72321M6.0625 7.44643H7.58036M2.26786 1.375H20.4821C20.8847 1.375 21.2708 1.53492 21.5554 1.81957C21.8401 2.10422 22 2.4903 22 2.89286V21.1071C22 21.5097 21.8401 21.8958 21.5554 22.1804C21.2708 22.4651 20.8847 22.625 20.4821 22.625H2.26786C1.8653 22.625 1.47922 22.4651 1.19457 22.1804C0.909917 21.8958 0.75 21.5097 0.75 21.1071V2.89286C0.75 2.4903 0.909917 2.10422 1.19457 1.81957C1.47922 1.53492 1.8653 1.375 2.26786 1.375Z" stroke="black" stroke-width="1.22968"></path></svg>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  )
}
// function footer_bak() {
//   return (
//     <div className='mt-5 pt-3 pt-md-5 ' style={{ background: "#F7F8FA" }}>
//       <footer className='container py-3'>
//         <div className='row justify-content-between'>
//           <Col md={6}>
//             <img src='/assets/img/united-logo.png' alt='' className='pb-4' />
//             <div className='d-flex align-items-center gap-5'>
//               <img src='/assets/img/apple-store.png' alt='' className='col-3' />
//               <img src='/assets/img/play-store.png' alt='' className='col-3' />
//             </div>
//           </Col>
//           <Col md={3}>
//             <h3 className='font-w600 fs-4 pb-4'>Content</h3>
//             <p>About Us</p>
//             <p>Get Professional help</p>
//             <p>Blog</p>
//             <p>Faq</p>
//             <p>Terms & Conditions</p>
//           </Col>
//           <Col md={3}>
//             <h3 className='font-w600 fs-4 pb-4'>Cooperation</h3>
//             <p>UWC in Media</p>
//             <p>Careers</p>
//             <p>Become a Counselor</p>
//             <p>Partnership</p>
//             <div className=''>
//  style={{fontSize:"16px"}}              <h6 className='font-w600 py-2'>Social media</h6>
//               <div className='d-flex gap-3'>
//                 <img src='/assets/img/instagram.png' alt='' />
//                 <img src='/assets/img/facebook.png' alt='' />
//                 <img src='/assets/img/twitter.png' alt='' />
//                 <img src='/assets/img/linkedin.png' alt='' />
//               </div>
//             </div>
//           </Col>
//         </div>
//       </footer>
//       <div className='text-center p-3 ' style={{ backgroundColor: '#142432' }}>
//         <div className='container row gap-5 justify-content-evenly'>
//           <a className='text-white text-decoration-none' href='#!'>
//             Terms & Conditions
//           </a>
//           <a className='text-white text-decoration-none' href='#!'>
//             Privacy Policy
//           </a>
//           <a className='text-white text-decoration-none' href='#!'>
//             Copyright © United We Care. 2023.  All Rights Reserved
//           </a>
//         </div>
//       </div>
//     </div>
//   )
// }
export default Footer
