import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../context';
import { useAuth } from '../../auth';
import { getCountryCode } from '../core/commonFun';

const ThreeImgBackground = ({ type }) => {

    const { planType } = useParams();
    const { currentUser } = useAuth();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    const navigate = useNavigate();
    const [isCountryIndia, setIsCountryIndia] = useState();

    var countryCode = getCountryCode();
    console.log("countryCode", countryCode)
    useEffect(() => {
        if (countryCode === "IN") {
            setIsCountryIndia(true)
        } else {
            setIsCountryIndia(false)
        }
    }, [])

    const onClickSubscribe = () => {
        if (!currentUser) {
            if (type === "Platinum") {
                sessionStorage.setItem("subscriptionID", 5);
            }
            else if (type === "Premium") {
                if (isCountryIndia) {
                    sessionStorage.setItem("subscriptionID", 7);
                } else {
                    sessionStorage.setItem("subscriptionID", 9);
                }
            }
            else if (type === "Super") {
                if (isCountryIndia) {
                    sessionStorage.setItem("subscriptionID", 6);
                } else {
                    sessionStorage.setItem("subscriptionID", 10);
                }
            }
            localStorage.setItem("retrunurl", `/subscription-order-summary`)
            setShowLloginPopup(true);
        }
        else {
            if (type === "Platinum") {
                sessionStorage.setItem("subscriptionID", 5);
                navigate(`/subscription-order-summary`)
            }
            else if (type === "Premium") {
                sessionStorage.setItem("subscriptionID", 7);
                navigate(`/subscription-order-summary`)
            }
            else if (type === "Super") {
                sessionStorage.setItem("subscriptionID", 6);
                navigate(`/subscription-order-summary`)
            }
        }
    }

    return (
        <div className='ThreeImg--Background'>
            <picture>
                <source media="(min-width:500px)" srcset="/assets/img/planDetails/bg3.png" />
                <img src="/assets/img/planDetails/bg3Mobile.png" loading='lazy' />
            </picture>
            <img src="/assets/img/planDetails/bg2.png" loading='lazy' />
            <img src="/assets/img/planDetails/bg1.png" loading='lazy' />


            <div className='Content--Area'>
                <h6 className='my-md-5'>Affordable Healing, Unmatched Support: Your Mental Wellness Journey Begins</h6>
                <button onClick={() => onClickSubscribe()} className='my-md-5'>Start your Journey</button>
            </div>
        </div>
    )
}

export default ThreeImgBackground