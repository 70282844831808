import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import Button from "@mui/material/Button";
import { useAuth } from "../auth";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Stella.css";
import MediaCardResponse from "../components/stella/MediaCardResponse";
import ContactCardResponse from "../components/stella/ContactCardResponse";
import StellaLogo from "../images/StellaLogo.svg";
import DelayedRender from "../components/DelayedRenderer";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { AppContext } from "../context";

import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";

import { callAwaitStart } from "./_request";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

//Icons
import HeadphonesIcon from "@mui/icons-material/Headphones";
import HeadsetOffIcon from "@mui/icons-material/HeadsetOff";

import { Helmet } from "react-helmet";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Stella() {
  const { currentUser, userType, logout } = useAuth();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [showInput, setShowInput] = useState(true);
  // const [showEmotions, setShowEmotions] = useState(false);
  // const [buttons, setButtons] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [allLanguages, setAllLanguages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState({
    name: "English",
    id: "1",
  });
  const [chatHistory, setChatHistory] = useState(["loading"]);
  const [renderAnimation, setRenderAnimation] = useState(false);
  const [shouldRenderButtons, setShouldRenderButtons] = useState(false);
  const [sessionID, setSessionID] = useState("");

  const [isNavigating, setIsNavigating] = useState(false);

  //New States for managing avatar
  const [showMic, setShowMic] = useState(true);
  const [showStopMic, setShowStopMic] = useState(false);
  const [showSend, setShowSend] = useState(true);

  const [isAvatarSelected, setIsAvatarSelected] = useState(false);
  const [introSectionClass, setIntroSectionClass] =
    useState("IntroSection py-4");
  const [chatBoxClass, setChatBoxClass] = useState("chat-box");
  const [stellaFaces, setStellaFaces] = useState([]);
  const [serverAvatar, setServerAvatar] = useState("");

  //Audio States
  const [audioRequestData, setAudioRequestData] = useState(null);
  const [isRecordingStopped, setIsRecordingStopped] = useState(false);

  const [currentAudioIndex, setCurrentAudioIndex] = useState(-1); // Index of the currently playing audio
  const [isAudioMode, setIsAudioMode] = useState(false);
  const [playedAudioIds, setPlayedAudioIds] = useState([]);
  const [isAllAudiosPlayed, setIsAllAudiosPlayed] = useState(true);

  //Stella Faces
  const [openAvatarDialog, setOpenAvatarDialog] = useState(false);

  const [queryString, setQueryString] = useState("");

  const recorderControls = useAudioRecorder();

  const languageAPIKey = process.env.REACT_APP_LANGUAGEAPIKEY;

  //const baseURL = "https://6f496bf3-217c-4c41-883b-536dea9a39e5.mock.pstmn.io/";
  //const userID = 7632;
  const baseURL = "https://odoo.unitedwecare.ca";
  const userID = currentUser?.UserId;

  const stellaAPIKey = process.env.REACT_APP_STELLAAPIKEY;

  const location = useLocation();
  const navigate = useNavigate();
  const prevLocation = useRef(null);

  const chatHistoryRef = useRef(null);

  const checkInputView = () => {
    if (
      chatHistory.length > 0 &&
      chatHistory[chatHistory.length - 1].data !== undefined &&
      chatHistory[chatHistory.length - 1].data !== null
    ) {
      chatHistory[chatHistory.length - 1].data.map((item) => {
        if (Object.keys(item).includes("buttons")) {
          setShowInput(false);
        } else {
          setShowInput(true);
        }
      });
    } else {
      setShowInput(true);
    }
  };

  const scrollChatToBottom = () => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  };

  const setLanguage = (language) => {
    localStorage.setItem("stellaLanguage", language);
  };

  ///Audio Autoplay Section Start
  //For Audio
  const audioRefs = useRef([]);
  const audioEndedHandlers = useRef([]);

  const getNextAudioMessageId = (index) => {
    for (let i = index; i < chatHistory.length; i++) {
      const audioMessage = chatHistory[i]?.data?.find(
        (message) => message.audio
      );
      if (audioMessage) {
        return audioMessage.audio;
      }
    }
    return null;
  };

  const handleAudioEnded = (index) => {
    const nextIndex = index + 1;

    // Check if the next audio message ID is in the list of played audio IDs
    if (nextIndex < audioRefs.current.length) {
      const nextAudioMessageId = getNextAudioMessageId(nextIndex);

      if (nextAudioMessageId && playedAudioIds.includes(nextAudioMessageId)) {
        // Pause the audio playback
        audioRefs.current[index].pause();
      } else {
        // Play the next audio message
        setCurrentAudioIndex(nextIndex);
        audioRefs.current[nextIndex].play();

        // Update the list of played audio IDs
        if (nextAudioMessageId) {
          setPlayedAudioIds((prevIds) => [...prevIds, nextAudioMessageId]);
        }
      }
    } else {
      // Reset currentAudioIndex when all new audios have been played
      setCurrentAudioIndex(-1);
      setIsAllAudiosPlayed(false);
    }
  };

  // useEffect(() => {
  //   audioRefs.current = Array.from(
  //     document.querySelectorAll(".server-response .audio-player")
  //   );

  //   if (audioRefs.current.length > 0 && isAudioMode && isAllAudiosPlayed) {
  //     if (currentAudioIndex === -1) {
  //       setCurrentAudioIndex(0);
  //       audioRefs.current[0].play();
  //       setPlayedAudioIds([chatHistory[0].data[0]?.audio]);
  //     }

  //     audioEndedHandlers.current = audioRefs.current.map((audio, index) => {
  //       const handler = () => handleAudioEnded(index);
  //       audio.addEventListener("ended", handler);
  //       return handler;
  //     });
  //   }

  //   return () => {
  //     audioEndedHandlers.current.forEach((handler, index) => {
  //       audioRefs.current[index].removeEventListener("ended", handler);
  //     });
  //   };
  // }, [chatHistory, currentAudioIndex, isAudioMode, isAllAudiosPlayed]);

  ///Audio Autoplay Section End

  //Initial check for stella face in session storage
  useEffect(() => {
    const face = sessionStorage.getItem("StellaFace");
    if (face !== undefined && face !== null && face !== "") {
      setServerAvatar(JSON.parse(face).link);
    }
  }, []);

  //Check if Stella Face ID is in URL
  useEffect(() => {
    let URLParts = window.location.href.split("?")[1];
    let URLStrip;

    if (URLParts !== undefined) {
      URLStrip = URLParts.split("&");

      URLStrip?.map((item) => {
        if (item.includes("face-id")) {
          let faceID = item.split("face-id=")[1];

          const selectedFace = stellaFaces?.filter((item) => item.id == faceID);

          if (selectedFace.length > 0) {
            setServerAvatar(selectedFace[0].link);
            sessionStorage.setItem(
              "StellaFace",
              JSON.stringify(selectedFace[0])
            );
            //console.log("selectedFace", selectedFace)
          }
        }
      });
    }
  }, [stellaFaces]);

  //Get session ID for stella chat
  useEffect(() => {
    let isMounted = true; // Flag to check if the component is still mounted

    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": stellaAPIKey,
    };

    const getSessionValue = async () => {
      try {
        setIsLoading(true);

        const response = await fetch(`${baseURL}/im_livechat/get_session`, {
          headers,
        });

        if (!isMounted) {
          // Component has been unmounted, no need to process the response
          return;
        }

        if (!response.ok) {
          // Handle error response
          throw new Error("Error fetching languages");
        }

        const jsonData = await response.json();

        const token = jsonData.data;
        const { sender_id } = jwt_decode(token);
        setSessionID(sender_id);
        sessionStorage.setItem("stella_s_id", sender_id);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getSessionValue();

    return () => {
      // Cleanup function to run when component is unmounted
      isMounted = false; // Set the flag to false when component is unmounted
    };
  }, []);

  //Initially body scroll Y
  useEffect(() => {
    document.body.style.overflowY = "scroll";
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShouldRenderButtons(true);
    }, 1000); // Delay of 1 second

    return () => clearTimeout(timeoutId);
  }, [renderAnimation]);

  //Initial Language set
  useEffect(() => {
    if (localStorage.getItem("stellaLanguage") == undefined) {
      setSelectedLanguage("English");
    } else setSelectedLanguage(localStorage.getItem("stellaLanguage"));
  }, []);

  //Check for input box to be shown or not
  useEffect(() => {
    checkInputView();
  }, [chatHistory]);

  //If user is not logged in
  useEffect(() => {
    if (userID == undefined) {
      //localStorage.setItem('retrunurl', `/book-an-appointment`);
      setShowLloginPopup(true);
      const searchParams = location.search;
      const searchString = searchParams.toString();
      const paramsArray = searchString.split("&");

      // Filter out the token parameter
      const filteredParamsArray = paramsArray.filter(
        (param) => !param.startsWith("token=")
      );

      // Reconstruct the query string
      const filteredQueryString = filteredParamsArray
        .join("&")
        .replace(/\+/g, "%20");
      setQueryString(filteredQueryString);
      if (searchParams == "") {
        sessionStorage.setItem("Login-Invoked", "/stella");
      } else {
        sessionStorage.setItem(
          "Login-Invoked",
          `/stella${filteredQueryString}`
        );
      }
    }
  }, [userID, sessionID, location, navigate]);

  // Scroll chat box to the bottom
  useEffect(() => {
    scrollChatToBottom();
  }, [chatHistory]);

  //Stella Faces
  useEffect(() => {
    let isMounted = true; // Flag to check if the component is still mounted

    const fetchStellaFaces = async () => {
      try {
        setIsLoading(true);

        const headers = {
          "Content-Type": "application/json",
          "X-API-Key": languageAPIKey,
        };

        const payload = {
          user_id: currentUser?.UserId,
        };

        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/web/stella/faces`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );

        if (!isMounted) {
          // Component has been unmounted, no need to process the response
          return;
        }

        if (!response.ok) {
          // Handle error response
          throw new Error("Error fetching languages");
        }

        const jsonData = await response.json();
        const { faces } = jsonData.data;
        setStellaFaces(faces);

        setServerAvatar(faces[0]?.link);
        sessionStorage.setItem("StellaFace", JSON.stringify(faces[0]));
        //console.log("faces", faces)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchStellaFaces();
  }, []);

  // Use useMemo to cache the stellaFaces data
  const memoizedStellaFaces = useMemo(() => stellaFaces, [stellaFaces]);

  //Languguage List useEffect
  useEffect(() => {
    let isMounted = true; // Flag to check if the component is still mounted

    const fetchLanguages = async () => {
      try {
        setIsLoading(true);

        const headers = {
          "Content-Type": "application/json",
          "X-API-Key": stellaAPIKey,
        };

        const response = await fetch(`${baseURL}/stella/language/list`, {
          headers,
        });

        if (!isMounted) {
          // Component has been unmounted, no need to process the response
          return;
        }

        if (!response.ok) {
          // Handle error response
          throw new Error("Error fetching languages");
        }

        const jsonData = await response.json();

        const token = jsonData.data;
        const { languages } = jwt_decode(token);

        //console.log("LANGUAGES", decodedToken)
        setAllLanguages(languages);
        setCurrentLanguage(allLanguages[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchLanguages();
  }, []);

  //Initial chat API useEffect
  useEffect(() => {
    let payload;
    let key = "";
    let value = "";

    if (window.location.href.includes("stella?")) {
      let locationFragment = window.location.href.split("?")[1];

      //console.log(first);
      if (locationFragment !== undefined) {
        let URLStrip = locationFragment.split("&");
        URLStrip.map((item) => {
          if (!item.includes("face-id")) {
            key = item.split("=")[0];
            value = item.split("=")[1];
          }
        });
      }
    }

    if (
      key == "" ||
      value == "" ||
      (key !== "message" && key !== "button_id" && key !== "notification_id")
    ) {
      payload = {
        jsonrpc: "2.0",
        params: {
          sender_id: sessionID,
          //button_id: 1909,
          message: "Hi!",
        },
      };
    } else {
      payload = {
        jsonrpc: "2.0",
        params: {
          sender_id: sessionID,
          [key]: value,
        },
      };
    }

    const fetchConversation = async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
          "X-API-Key": stellaAPIKey,
        };

        const response = await fetch(
          `${baseURL}/stella/conversation/${userID}`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );

        const postResponse = await response.json();
        let data = jwt_decode(postResponse?.data).responses;
        //console.log("postResponse", postResponse);

        setChatHistory([
          { response: "server", data: data, avatar: serverAvatar },
        ]);
      } catch (error) {
        console.log(error);
      }
    };

    if (sessionID !== "") {
      setTimeout(async () => {
        fetchConversation();
      }, 1500);
    }
  }, [sessionID]);

  //Stella Await Start
  useEffect(() => {
    const payload = {
      jsonrpc: "2.0",
      params: {
        sender_id: sessionID,
        message: "Hi!",
      },
    };

    //console.log("sessionID", sessionID);

    const fetchAwaitStart = async () => {
      try {
        const data = await callAwaitStart(payload, currentUser?.UserId);

        if (data.ResponseCode == 200 || data.ResponseCode == 201) {
          // setChatHistory((prevChatHistory) => [...prevChatHistory, "loading"]);

          // setTimeout(() => {
          //   setChatHistory((prevChatHistory) => [
          //     ...prevChatHistory.slice(0, prevChatHistory.length - 1),
          //     { response: "server", data: data.data, avatar: serverAvatar },
          //   ]);
          // }, 2000);

          const decodedMessage = jwt_decode(data.data).responses;

          const tempDecoded = [
            {
              text: "That's great! It's important to feel happy and enjoy life. What activities make you feel happy?",
              audio: null,
            },
          ];

          if (decodedMessage.length > 0) {
            decodedMessage?.map((item) => {
              setChatHistory((prevChatHistory) => [
                ...prevChatHistory,
                "loading",
              ]);

              setTimeout(() => {
                setChatHistory((prevChatHistory) => [
                  ...prevChatHistory.slice(0, prevChatHistory.length - 1),
                  { response: "server", data: [item], avatar: serverAvatar },
                ]);
              }, 1500);
            });
          }
        }
      } catch (error) {
        console.error("Error getting await start:", error);
      }
    };

    const intervalId = setInterval(() => {
      if (sessionID !== null) fetchAwaitStart();
    }, 5000);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [sessionID]);

  const sendMessageToApi = async (key, value) => {
    setChatHistory((prevChatHistory) => [...prevChatHistory, "loading"]);

    const payload = {
      jsonrpc: "2.0",
      params: {
        sender_id: sessionID,
        [key]: value,
      },
    };

    //console.log("payload", payload)

    setTimeout(async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
          "X-API-Key": stellaAPIKey,
        };

        const response = await fetch(
          `${baseURL}/stella/conversation/${userID}`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );

        const postResponse = await response.json();

        let data = jwt_decode(postResponse?.data).responses;
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory.slice(0, prevChatHistory.length - 1),
          { response: "server", data: data, avatar: serverAvatar },
        ]);
      } catch (error) {
        // Handle any errors
        console.error("Error sending message:", error);
      }
    }, 2000);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const postLanguage = (id) => {
    const payload = {
      jsonrpc: "2.0",
      params: {
        lang_id: id,
      },
    };

    const postLanguageWithID = async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
          "X-API-Key": stellaAPIKey,
        };

        const response = await fetch(
          `${baseURL}/stella/user/language/${userID}`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );

        const postResponse = await response.json();
        const token = postResponse.data;
        const { languages } = jwt_decode(token);

        //console.log("POST LANGUAGES RESPONSE", languages)
        setCurrentLanguage(languages);
        if (response.ok) {
          console.log("Data successfully posted");
          // Process the response if needed
        } else {
          console.error("Error:", response.status);
        }
      } catch (error) {
        console.error("Error posting data:", error);
      }
    };

    postLanguageWithID();
  };

  const handleLanguageChange = (language) => {
    //POST Data to API for language change
    postLanguage(language.id);
    //Setting language for UI
    setSelectedLanguage(language.name);
    setLanguage(language.name);
    // Close the dropdown after selecting a language
    setShowDropdown(false);
  };

  const onEnterClicked = () => {
    // Perform your desired actions here
    const newMessage = {
      response: "self",
      type: "text",
      message: inputValue,
      avatar: currentUser?.ProfileImage,
    };
    setChatHistory([...chatHistory, newMessage]);
    sendMessageToApi("message", inputValue);
    //setIsAllAudiosPlayed(true)
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputValue !== "") {
      // Call a function when Enter key is pressed
      onEnterClicked();
      setInputValue("");
    }
  };

  const handleSendButton = () => {
    if (currentUser == undefined) {
      setShowLloginPopup(true);
      if (sessionStorage.getItem("Login-Invoked") == undefined) {
        const searchParams = location.search;
        if (searchParams == "") {
          sessionStorage.setItem("Login-Invoked", "/stella");
        } else {
          sessionStorage.setItem("Login-Invoked", `/stella${queryString}`);
        }
      }
    } else {
      if (inputValue !== "") {
        onEnterClicked();
        setInputValue("");

        /*setShowMic(true);
        setShowSend(false);*/
      }
    }
  };
  const handleEmotion = (emotion) => {
    const newMessage = {
      response: "self",
      type: "text",
      message: emotion.button_text,
      avatar: currentUser?.ProfileImage,
    };
    setChatHistory([...chatHistory, newMessage]);
    sendMessageToApi("button_id", emotion.button_id);
    //sendMessageToApi("button_id", 4060);
  };

  const navigateToURL = (url) => {
    window.location.href = url;
  };

  const handleBackButton = () => {
    window.history.back();
  };

  const handleRenderComplete = (isCompleted) => {
    if (isCompleted) {
      // Perform actions or handle completion status
      scrollChatToBottom();
      setRenderAnimation(false);
    } else {
      scrollChatToBottom();
      setRenderAnimation(true);
    }
  };

  const handleViewChatBox = () => {
    setShowInput(!showInput);
  };

  const handleTextChange = (e) => {
    setInputValue(e.target.value);

    /*if (e.target.value !== "") {
      setShowMic(false);
      setShowSend(true);
    } else if (e.target.value == "") {
      setShowMic(true);
      setShowSend(false);
    }*/
  };

  ///Audio Message Region Start
  //Use Effect for handling Audio

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  useEffect(() => {
    //console.log("CALLED", audioRequestData)
    const sendAudioData = async () => {
      const url = URL.createObjectURL(audioRequestData);
      const newMessage = {
        response: "self",
        type: "audio",
        url,
        avatar: currentUser?.ProfileImage,
      };
      setChatHistory([...chatHistory, newMessage]);

      const base64String = await convertBlobToBase64(audioRequestData);

      sendMessageToApi("audio", base64String);
    };

    if (isRecordingStopped && audioRequestData) {
      sendAudioData();
      setAudioRequestData(null);
      setIsRecordingStopped(false); // Reset the flag
    }

    return () => {
      setAudioRequestData(null);
      // Perform any cleanup tasks here if needed
      // This function will be executed when the component is unmounted
    };
  }, [audioRequestData, isRecordingStopped]);

  const handleRecordingStart = () => {
    if (currentUser == undefined) {
      setShowLloginPopup(true);
      if (sessionStorage.getItem("Login-Invoked") == undefined) {
        if (queryString == "") {
          sessionStorage.setItem("Login-Invoked", "/stella");
        } else {
          sessionStorage.setItem("Login-Invoked", `/stella${queryString}`);
        }
      }
    } else {
      setShowMic(false);
      setShowStopMic(true);
      //handleUserInteraction();
      //setIsRecording(true);
      //SpeechRecognition.startListening();
      recorderControls.startRecording();
    }
  };

  const handleRecordingStop = async () => {
    //console.log("BLOB", blob);
    setShowMic(true);
    setShowStopMic(false);
    //setShowSend(true);
    recorderControls.stopRecording();
    setIsRecordingStopped(true);
  };

  ///Audio Message Region End

  const myAvatar = currentUser?.ProfileImage;

  const handleAvatarSelect = (face) => {
    sessionStorage.setItem("StellaFace", JSON.stringify(face));
    setServerAvatar(face.link);
    setOpenAvatarDialog(false);
  };

  const handleClickOpenDialog = () => {
    setOpenAvatarDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenAvatarDialog(false);
  };

  // console.log(chatHistory)

  return (
    <>
      <Helmet>
        <title>Talk to Stella | United We Care</title>
      </Helmet>
      {window.scrollTo(0, 0)}
      <div className="min-h-screen stella-screen mb-5 pb-5">
        <div className={chatBoxClass}>
          <div className="chat-box--header">
            <Button
              className="StellaAvatar--Selector"
              sx={{ minWidth: "max" }}
              onClick={handleClickOpenDialog}
            >
              <img
                src={serverAvatar}
                width={60}
                height={60}
                style={{
                  borderRadius: "50%",
                  objectFit: "cover",
                  objectPosition: "center top",
                }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M11.9984 22.0914C17.3004 22.0914 21.5984 17.7933 21.5984 12.4914C21.5984 7.18942 17.3004 2.89136 11.9984 2.89136C6.6965 2.89136 2.39844 7.18942 2.39844 12.4914C2.39844 17.7933 6.6965 22.0914 11.9984 22.0914Z"
                  fill="#541CF5"
                  stroke="white"
                  strokeWidth="1.90119"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 8.49146V16.4915"
                  stroke="white"
                  strokeWidth="1.02981"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 12.4917H16"
                  stroke="white"
                  strokeWidth="1.02981"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>

            <Dialog
              className="FaceSelector--Dialog"
              open={openAvatarDialog}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleCloseDialog}
            >
              <div className="Dialog--Top"></div>
              <DialogTitle sx={{ textAlign: "center" }}>
                Choose My Avatar
              </DialogTitle>
              <DialogContent>
                <div className="Avatar--Container d-flex align-items-center gap-4 justify-content-center mt-4">
                  {memoizedStellaFaces?.map((item) => (
                    <Button
                      sx={{ padding: "0", borderRadius: "50%" }}
                      className={`${
                        serverAvatar == item?.link ? "SelectedFace" : ""
                      }`}
                      variant="text"
                      onClick={() => handleAvatarSelect(item)}
                    >
                      <img
                        src={item?.link}
                        width={90}
                        height={90}
                        style={{
                          borderRadius: "50%",
                          objectFit: "cover",
                          objectPosition: "center top",
                        }}
                      />
                    </Button>
                  ))}
                </div>
              </DialogContent>
            </Dialog>

            <div className="chat-box--logo">
              <p>Hi I am Stella</p>
            </div>

            <div className="d-flex align-items-center LanguageSelector">
              <Button onClick={toggleDropdown} className="LanguageDropdown">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                >
                  <path
                    d="M10.0828 1.30444H4.94935C2.29728 1.30444 0.984375 2.61735 0.984375 5.26942V10.416C0.984375 13.1206 2.29728 14.4335 4.94935 14.3679H10.0959C12.8005 14.4335 14.1134 13.1206 14.0478 10.4029V5.26942C14.1134 2.61735 12.8005 1.30444 10.0828 1.30444ZM10.1879 11.4926C9.29507 11.4926 8.42856 11.1512 7.70646 10.5473C6.89245 11.1381 5.90777 11.4926 4.84432 11.4926C4.30603 11.4926 3.85964 11.0462 3.85964 10.5079C3.85964 9.96963 4.30603 9.52324 4.84432 9.52324C6.18349 9.52324 7.29946 8.6042 7.70646 7.34381H4.84432C4.30603 7.34381 3.85964 6.89743 3.85964 6.35913C3.85964 5.82084 4.30603 5.37445 4.84432 5.37445H6.53797C6.59049 4.87555 6.99749 4.48168 7.50952 4.48168C8.02155 4.48168 8.42856 4.87555 8.48107 5.37445H8.82243H8.84869H8.87494H10.1747C10.713 5.37445 11.1594 5.82084 11.1594 6.35913C11.1594 6.89743 10.7261 7.34381 10.1747 7.34381H9.74146C9.6233 7.97401 9.37385 8.56482 9.04562 9.10311C9.40011 9.36569 9.78085 9.52324 10.1879 9.52324C10.7261 9.52324 11.1725 9.96963 11.1725 10.5079C11.1725 11.0462 10.7261 11.4926 10.1879 11.4926Z"
                    fill="white"
                  />
                  <path
                    d="M10.175 28.5472C4.56891 28.5472 0 23.9782 0 18.3721C0 17.8338 0.446388 17.3875 0.98468 17.3875C1.52297 17.3875 1.96936 17.8338 1.96936 18.3721C1.96936 22.2583 4.67395 25.5143 8.3107 26.3677L7.95621 25.7769C7.6805 25.3043 7.82492 24.7003 8.29757 24.4246C8.75709 24.1489 9.37415 24.2933 9.64986 24.766L11.0284 27.0636C11.2122 27.3655 11.2122 27.7463 11.0415 28.0483C10.8577 28.3502 10.5295 28.5472 10.175 28.5472Z"
                    fill="white"
                  />
                  <path
                    d="M27.2399 11.4795C26.7016 11.4795 26.2552 11.0331 26.2552 10.4948C26.2552 6.60865 23.5507 3.35264 19.9139 2.49925L20.2684 3.09006C20.5441 3.5627 20.3997 4.16664 19.927 4.44235C19.4675 4.71806 18.8504 4.57364 18.5747 4.101L17.1962 1.80341C17.0124 1.50144 17.0124 1.1207 17.1831 0.818729C17.3669 0.51676 17.6951 0.319824 18.0496 0.319824C23.6557 0.319824 28.2246 4.88874 28.2246 10.4948C28.2246 11.0331 27.7782 11.4795 27.2399 11.4795Z"
                    fill="white"
                  />
                  <path
                    d="M20.5739 14.2388C16.8977 14.2388 13.9043 17.2191 13.9043 20.9083C13.9043 24.5845 16.8846 27.5779 20.5739 27.5779C24.25 27.5779 27.2434 24.5976 27.2434 20.9083C27.2434 17.2191 24.2631 14.2388 20.5739 14.2388ZM23.8299 24.0724C23.3441 24.3088 22.7533 24.125 22.5038 23.6261L22.2806 23.1797H18.8802L18.657 23.6261C18.4863 23.9674 18.1319 24.1643 17.7774 24.1643C17.633 24.1643 17.4754 24.125 17.3441 24.0593C16.8583 23.8099 16.6614 23.2191 16.8977 22.7333L19.7073 17.1272C19.878 16.7989 20.2194 16.5889 20.587 16.5889C20.9546 16.5889 21.296 16.7989 21.4666 17.1403L24.2763 22.7464C24.5126 23.2322 24.3156 23.823 23.8299 24.0724Z"
                    fill="white"
                  />
                  <path
                    d="M19.8633 21.2087H21.2943L20.5722 19.7776L19.8633 21.2087Z"
                    fill="white"
                  />
                </svg>

                <p>Choose Language</p>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                >
                  <g clipPath="url(#clip0_683_16548)">
                    <path
                      d="M7.51562 10.7366L12.724 15.9449L17.9323 10.7366H7.51562Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_683_16548">
                      <rect
                        width="25"
                        height="25"
                        fill="white"
                        transform="translate(0.224609 0.319824)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </Button>

              <Dropdown show={showDropdown} align="end" drop="down">
                <Dropdown.Toggle
                  as={Button}
                  variant="secondary"
                ></Dropdown.Toggle>
                <Dropdown.Menu>
                  {/* <p>Change Language</p>

                <Dropdown.Divider /> */}
                  {/* Available Languages */}

                  {allLanguages?.map((item) => (
                    <Dropdown.Item
                      className={
                        selectedLanguage == item.name ? "selected-language" : ""
                      }
                      key={item.id}
                      onClick={() => handleLanguageChange(item)}
                    >
                      {item.name}
                    </Dropdown.Item>
                  ))}

                  {/* Add more languages as needed */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div
            className="chat-box--content"
            // style={{
            //   background: `url('/assets/img/stellaBG.jpg'), lightgray 0px -98.965px / 100% 190.666% no-repeat`,
            // }}
          >
            <div className="chat-content--buttonArea px-3 d-flex align-items-center gap-2 bg-white">
              <div className="chat-box--logo  d-flex align-items-center gap-3">
                <span className="pingContainer">
                  <span className="animate-ping"></span>
                  <span className="pingDot"></span>
                </span>
                {/* <img src={StellaLogo} alt="..." width={32} /> */}
                <p>LIVE Chat</p>
              </div>

              <div className="d-flex align-items-center gap-1">
                <Button
                  sx={{ minWidth: "max-content" }}
                  onClick={() => setIsAudioMode(!isAudioMode)}
                >
                  {isAudioMode ? <HeadphonesIcon /> : <HeadsetOffIcon />}
                </Button>

                <Button
                  variant="outline-secondary type-btn"
                  onClick={handleViewChatBox}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    />
                  </svg>
                </Button>
              </div>
            </div>

            <div ref={chatHistoryRef} className="chat-content--chats">
              {/* Binding Conversation API */}
              {currentUser == undefined ? (
                <div className="response-area">
                  <div className="avatar">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Fstella%2Ffaces%2F1.png?alt=media"
                      alt="My Avatar"
                    />
                  </div>

                  <div className="space-y-5">
                    <div className={`response server-response`}>
                      Hi, nice to meet you. Please login so I can get to know
                      you better.
                    </div>

                    <div className="response-buttons">
                      <div
                        className="button--item"
                        onClick={() => {
                          setShowLloginPopup(true);
                          if (
                            sessionStorage.getItem("Login-Invoked") == undefined
                          ) {
                            if (queryString == "") {
                              sessionStorage.setItem(
                                "Login-Invoked",
                                "/stella"
                              );
                            } else {
                              sessionStorage.setItem(
                                "Login-Invoked",
                                `/stella${queryString}`
                              );
                            }
                          }
                        }}
                      >
                        Login
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                chatHistory?.map((chat) => {
                  // console.log("CHAT HERE: ", chat?.data)
                  if (chat?.response === "server") {
                    return (
                      <div key={chat.id} className="response-area">
                        {chat?.data !== undefined && (
                          <div className="avatar">
                            <img src={chat?.avatar} alt="My Avatar" />
                            <span></span>
                          </div>
                        )}

                        <div className="space-y-5">
                          {chat?.data?.map((item, i) => (
                            <>
                              <DelayedRender
                                key={i}
                                delay={i * 1500}
                                onComplete={handleRenderComplete}
                              >
                                <div className={`response server-response`}>
                                  {item.text && item.text}

                                  {item.audio && isAudioMode && (
                                    <audio
                                      className="audio-player"
                                      src={item.audio}
                                      controls={true}
                                      controlsList="nodownload"
                                    />
                                  )}

                                  {item.buttons && (
                                    <div className="response-buttons">
                                      {item.buttons.map((btn) => (
                                        <div
                                          key={btn.button_id}
                                          className="button--item"
                                          onClick={() => handleEmotion(btn)}
                                        >
                                          {btn.button_text}
                                        </div>
                                      ))}
                                    </div>
                                  )}

                                  {item?.html && (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item.html,
                                      }}
                                    ></div>
                                  )}

                                  {item?.content && (
                                    <MediaCardResponse
                                      categoryID={item.content.category_id}
                                      id={item.content.content_id}
                                      title={item.content.title}
                                      type={item.content.type}
                                      description={item.content.description}
                                      thumbnail={item.content.thumb_image}
                                      largeImage={item.content.large_image}
                                      videoURL={item.content.video_url}
                                      audioURL={item.content.audio_url}
                                      video={item.content.video}
                                    />
                                  )}

                                  {item?.user && (
                                    <ContactCardResponse
                                      key={item.user.id}
                                      id={item.user.id}
                                      fee={item.user.fee}
                                      description={item.user.description}
                                      name={`${item.user.name}`}
                                      image={`${item.user.image}`}
                                      profession={`${item.user.category}`}
                                      expInYears={`${item.user.experience}`}
                                      languages={[
                                        "English",
                                        "Hindi",
                                        "Bengali",
                                      ]}
                                      url={item.user.expert_detail_page}
                                    />
                                  )}
                                </div>
                              </DelayedRender>
                              {item.web_url && navigateToURL(item.web_url)}
                            </>
                          ))}
                        </div>
                      </div>
                    );
                  } else if (chat === "loading") {
                    return (
                      <div id="wave-animation">
                        <div id="loading-bubble" className="grey">
                          <div className="spinner">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        {chat?.type == "text" && (
                          <div className="response-area">
                            <div
                              className={`response ${
                                chat.response === "server"
                                  ? "server-response"
                                  : "my-response"
                              }`}
                            >
                              {chat.message}
                            </div>

                            {/* <div className="avatar ml-auto">
                          <img src={myAvatar} alt="My Avatar" />
                          <span></span>
                        </div> */}
                          </div>
                        )}

                        {chat.type === "audio" && (
                          <div className="response-area">
                            <div
                              className={`response ${
                                chat?.response === "server"
                                  ? "server-response"
                                  : "my-response"
                              }`}
                            >
                              <audio
                                className="audio-player"
                                src={chat.url}
                                controls={true}
                                controlsList="nodownload"
                              />
                            </div>

                            <div className="avatar ml-auto">
                              <img src={myAvatar} alt="My Avatar" />
                              <span></span>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  }
                })
              )}

              {renderAnimation && (
                <div id="wave-animation">
                  <div id="loading-bubble" className="grey">
                    <div className="spinner">
                      <div className="bounce1"></div>
                      <div className="bounce2"></div>
                      <div className="bounce3"></div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {showInput && (
              <div className="chat-content--input">
                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    value={inputValue}
                    placeholder="Start Typing..."
                    onChange={(e) => handleTextChange(e)}
                    onKeyDown={handleKeyPress}
                  />

                  {/* Send Text */}
                  {showSend && (
                    <button
                      className="send-input--button"
                      onClick={handleSendButton}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-6 h-6"
                      >
                        <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                      </svg>
                    </button>
                  )}
                </div>

                {/* Microphone */}
                {showMic && (
                  <button
                    className="send-input--mic"
                    onClick={handleRecordingStart}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z"
                      />
                    </svg>
                  </button>
                )}

                <div style={{ visibility: "hidden", position: "absolute" }}>
                  <AudioRecorder
                    onRecordingComplete={(blob) => setAudioRequestData(blob)}
                    recorderControls={recorderControls}
                  />
                </div>

                {/* Stop recording */}
                {showStopMic && (
                  <button
                    className="send-input--stop"
                    onClick={handleRecordingStop}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.5 7.5a3 3 0 013-3h9a3 3 0 013 3v9a3 3 0 01-3 3h-9a3 3 0 01-3-3v-9z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Stella;
