import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import image1 from "../../../dashboard/images/abuse.png";
import image2 from "../../../dashboard/images/chronic c.png";
import image3 from "../../../dashboard/images/chronic syn.png";
import image4 from "../../../dashboard/images/dite n.png";
import image5 from "../../../dashboard/images/emotional w.png";
import image6 from "../../../dashboard/images/fitness.png";
import image7 from "../../../dashboard/images/focus.png";
import image8 from "../../../dashboard/images/lgbt.png";
import image9 from "../../../dashboard/images/meditation.png";
import image10 from "../../../dashboard/images/mindfuln.png";
import image11 from "../../../dashboard/images/move.png";
import image12 from "../../../dashboard/images/parenting.png";
import image13 from "../../../dashboard/images/phobia.png";
import image14 from "../../../dashboard/images/psychol d.png";
import image15 from "../../../dashboard/images/self help.png";
import image16 from "../../../dashboard/images/sexual well.png";
import image17 from "../../../dashboard/images/sleep.png";
import image18 from "../../../dashboard/images/stress.png";
import image19 from "../../../dashboard/images/wellness.png";
import image20 from "../../../dashboard/images/work related.png";
import image21 from "../../../dashboard/images/yoga medita.jpg";

import { BsArrowRightCircle } from "react-icons/bs";
import { BsArrowLeftCircle } from "react-icons/bs";
import "../../Styles/style.css";
function Explore_according() {
    const navigate = useNavigate();

    const [card, setCard] = useState([
      { image: image1, link: 'https://www.unitedwecare.com/category/abuse/' },
      { image: image2,  link: 'https://www.unitedwecare.com/category/chronic-condition/' },
      { image: image3,  link: 'https://www.unitedwecare.com/category/chronic-syndrome/' },
      { image: image4, link: 'https://www.unitedwecare.com/category/diet-nutrition/' },
  
      { image: image5,  link: 'https://www.unitedwecare.com/category/emotional-wellness/' },
      { image: image6,   link: 'https://www.unitedwecare.com/category/fitness/' },
      { image: image7,  link: 'https://www.unitedwecare.com/category/focus/' },
      { image: image8, link:  'https://www.unitedwecare.com/category/lgbtqia/' },
      { image: image9,  link:'https://www.unitedwecare.com/category/meditation/' },
      { image: image10,   link:  'https://www.unitedwecare.com/category/mindfulness/'},
      { image: image11,   link:  'https://www.unitedwecare.com/category/move/'},
      { image: image12,   link:  'https://www.unitedwecare.com/category/parenting/'},
      { image: image13,   link: 'https://www.unitedwecare.com/category/phobia/' },
      { image: image14,   link:  'https://www.unitedwecare.com/category/psychological-disorder/'},
      { image: image15,  link:  'https://www.unitedwecare.com/category/self-help/'},
      { image: image16,  link:  'https://www.unitedwecare.com/category/sexual-wellness/' },
      { image: image17,  link:   'https://www.unitedwecare.com/category/sleep/' },
      { image: image18,   link: 'https://www.unitedwecare.com/category/stress/ '},
      { image: image19,  link:   'https://www.unitedwecare.com/category/wellness/'},
      { image: image20, link:  'https://www.unitedwecare.com/category/work-related/'},
      { image: image21, link:  'https://www.unitedwecare.com/category/yoga-meditation/'},
     
    ]);
  
    const handleNavigation = (link) => {
      window.location.href = link;
    };

    const clicked=(a)=>{
        alert(a,"clicked...")
    }
  
  
    return (
      <div className="mt-2">
        <div
          className=""
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            boxShadow: " 2px 2px 9px 14px rgba(0,0,0,0.06);",
          }}
        >
          <div className="d-flex justify-content-between pe-2">
            <div className='mt-2'>
              <h5 className="text-start px-3 ms-3 pt-3">Explore according to your goal</h5>
              <p className="m-0 p-0 text-start px-3 ms-3 ">Start with programs as per your goals</p>
            </div>
            <div>
              {/*<h6 className="text-muted m-0 p-0 me-2 d-block d-sm-none">View All</h6>*/}

              <div className="d-none d-md-block pt-3">
  
  <div className="px-3 d-flex align-items-center pt-2 " style={{ cursor:'pointer'}}  onClick={()=>clicked("data")} >
  <h6 className="text-muted m-0 p-0 me-2 d-block d-sm-none">View All</h6>



            <div className='d-flex'>
            <BsArrowLeftCircle size={25} className="me-1"/>
             <BsArrowRightCircle size={25} className="ms-1" />
            </div>

   </div>
   
  </div>
            </div>
          </div>
  
          <div className="row main-card p-md-4">
            <div className="col-md-12">
              <div className="">
                <div className="scroll-container d-flex">
                  {card.map((a) => (
                    <div  className="">
                      <div className="">
                        {/*click able */}
                        <div
                          onClick={() => handleNavigation(a.link)}
                          className="card rounded-4 mx-2"
                          style={{ width: "170px", height: "150px", cursor: 'pointer' }}
                        >
                          <img src={a.image} alt="card-img" className="" style={{ width: "170px", height: "150px" }} />
                          <div className="card-img-overlay ">
                            <div>
             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default Explore_according