import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import "../styles/DailyPractice.css";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import {
  section_bg_img,
  bookmark,
  practice_plus,
  practice_tick1,
  green_tick,
  arrow,
  left_arrow,
  plus_btn_icon,
  info_icon,
  edit_dialog,
  delete__icon,
} from "../assets";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popup from "./Modal";

import {
  editPointToCheckSheet,
  deletePointToCheckSheet,
  addPointToCheckSheet,
  courseInternalContentView,
  addBookmarkEntry,
} from "../_request";
import { useNavigate, useParams } from "react-router-dom";
import SidebarMobile from "./SidebarMobile";
import { useAuth } from "../../../auth";

import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { changeDailyPracticeItemStatus } from "../_request";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Helmet } from "react-helmet";

const modal_data = [
  {
    title: "Daily Practice",
    desc: "Your hunt for motivation to start healthy practices ends here. Simply click from the list and add to your daily routine. We will help you be on track.",
    // subHead: "There are two different ways in which you can use your journal:",
    // step1:
    //   "1. Standard journal entries, Simply type in whatever you like as free text below.",
    // step2:
    //   "2. Journal entries linked to the program, You might like to use your journal to reflect on things you notice and learn as you go through the program content, using the 'Comment in your journal' button which sits below each block of information.",
  },
];

const DailyPractice = () => {
  const [checkSheetData, setCheckSheetData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState(null);
  const [clickedItemId, setClickedItemId] = useState(null);
  const [tryList, setTryList] = useState([]);
  const [myList, setMyList] = useState([]);
  const [completedList, setCompletedList] = useState([]);
  const [dailyPracticeList, setDailyPracticeList] = useState([]);
  const [doneList, setDoneList] = useState([]);
  const [show, setShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [deleteDailyp_id, setDeleteDailyP_id] = useState(null);
  const [isBookmarked, setIsBookmarked] = useState(false);

  const [isWhyNotTryClicked, setIsWhyNotTryClicked] = useState(false)
  const [isPracticeListItemClicked, setPracticeListItemClicked] = useState(false)


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickOption = (event,daily_p_id) => {
    setDeleteDailyP_id(daily_p_id);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseOption = () => {
    setAnchorEl(null);
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const [subLevelId, setSubLevelId] = useState(id);

  useEffect(() => {
    if (clickedItemId !== null) {
      setShowEdit(clickedItemId);
    }
  }, [clickedItemId]);

  useEffect(() => {
    fetchChecksheetData();
    setSubLevelId(id);
  }, [id]);

  const fetchChecksheetData = () => {
    const body = {
      userId: currentUser?.UserId,
      subLevelId: id,
    };
    courseInternalContentView(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setCheckSheetData(resp?.data);

        const tryListData = resp?.data?.content?.check_points.filter(
          (item) => item.status == "draft"
        );

        const myListData = resp?.data?.content?.check_points.filter(
          (item) => item.status == "ongoing"
        );

        const myCompletedListData = resp?.data?.content?.check_points.filter(
          (item) => item.status == "completed"
        );

        setMyList(myListData);
        setTryList(tryListData);
        setCompletedList(myCompletedListData);
        //console.log("tryListData", tryListData);
        setIsBookmarked(resp?.data?.bookmarked);

        setIsLoading(false);
      } else {
        console.error(resp?.ResponseMessage);
      }
    });
  };

  const handleEditClick = (item) => {
    setShowEdit(item?.id);
    setShow(true);
    const body = {
      checksheet_data_id: checkSheetData?.content?.checksheet_data_id,
      checksheet_point_id: item?.id,
      point_name: item?.name,
    };
    editPointToCheckSheet(body).then((resp) => {});
  };

  const handleDeleteClick = (item) => {
    const body = {
      checksheet_data_id: checkSheetData?.content?.checksheet_data_id,
      checksheet_point_id: item
    };
    // console.log("deletePointToCheckSheet");
    deletePointToCheckSheet(body).then((res) => {
      //console.log("RES", res);
      if (res.ResponseCode == 200) {
        handleCloseOption();
        fetchChecksheetData();
      }
    });
  };

  const handleClose = () => {
    setShowEdit(null);
    setShow(false);
  };

  //console.log("checkSheetData", checkSheetData);
  const changeStatus = (checksheetID, checksheetPointID) => {
    const body = {
      checksheet_data_id: checksheetID,
      checksheet_point_id: checksheetPointID,
    };
    changeDailyPracticeItemStatus(body).then((res) => {
      //console.log("RES", res);
      if (res?.ResponseCode == 200) {
        fetchChecksheetData();
        setIsWhyNotTryClicked(false);
        setPracticeListItemClicked(false)
      }
    });
  };

  const HandleAddtoMydailyList = (item) => {
    changeStatus(checkSheetData?.content?.checksheet_data_id, item?.id);
    setIsWhyNotTryClicked(true)
  };

  const HandleAddtoCompleteList = (item) => {
    changeStatus(checkSheetData?.content?.checksheet_data_id, item?.id);
    setPracticeListItemClicked(true)
  };

  const handleAddStep = () => {
    addPointToCheckSheet().then((resp) => {
      if (resp.ResponseCode === 200) {
        //console.log(resp.ResponseMessage);
      }
    });
  };

  const handleAddStepClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    const body = {
      checksheet_data_id: checkSheetData?.content?.checksheet_data_id,
      point_name: textValue.trim(),
    };
    addPointToCheckSheet(body).then((resp) => {
      if (resp.ResponseCode === 200) {
        console.log("My-Response123:---", resp);
        // changeStatus(checkSheetData?.content?.checksheet_data_id, item?.id);
        fetchChecksheetData();
      }
    });
    setIsEditing(false);
    setTextValue("");
  };

  const handlePrevButton = () => {
    if (checkSheetData?.previous_id?.type === "video") {
      navigate(`/course-internal/module/${checkSheetData?.previous_id?.id}`);
    } else if (checkSheetData?.previous_id?.type === "audio") {
      navigate(`/course-internal/module/${checkSheetData?.previous_id?.id}`);
    } else if (checkSheetData?.previous_id?.type === "checksheet") {
      navigate(
        `/course-internal/module/daily-practice/${checkSheetData?.previous_id?.id}`
      );
      setSubLevelId(checkSheetData?.previous_id?.id);
    } else if (checkSheetData?.previous_id?.type === "journal") {
      navigate(
        `/course-internal/module/my-journal/${checkSheetData?.previous_id?.id}`
      );
    } else if (checkSheetData?.previous_id?.type === "quiz") {
      navigate(
        `/course-internal/module/quiz/${checkSheetData?.previous_id?.id}`
      );
    } else if (checkSheetData?.previous_id?.type === "html") {
      navigate(
        `/course-internal/module/article/${checkSheetData?.previous_id?.id}`
      );
    } else if (checkSheetData?.previous_id?.type === "stella_assessment") {
      navigate(
        `/course-internal/talk-to-stella?assessmentID=${checkSheetData?.previous_id?.id}`
      );
    } else if (checkSheetData?.previous_id?.type === "mood_monitor") {
      navigate(
        `/course-internal/module/mood-monitor?id=${checkSheetData?.previous_id?.id}`
      );
    }
  };

  const handleNextButton = () => {
    if (checkSheetData?.next_id?.type === "video") {
      navigate(`/course-internal/module/${checkSheetData?.next_id?.id}`);
    } else if (checkSheetData?.next_id?.type === "audio") {
      navigate(`/course-internal/module/${checkSheetData?.next_id?.id}`);
    } else if (checkSheetData?.next_id?.type === "checksheet") {
      navigate(
        `/course-internal/module/daily-practice/${checkSheetData?.next_id?.id}`
      );
      setSubLevelId(checkSheetData?.next_id?.id);
    } else if (checkSheetData?.next_id?.type === "journal") {
      navigate(
        `/course-internal/module/my-journal/${checkSheetData?.next_id?.id}`
      );
    } else if (checkSheetData?.next_id?.type === "quiz") {
      navigate(`/course-internal/module/quiz/${checkSheetData?.next_id?.id}`);
    } else if (checkSheetData?.next_id?.type === "html") {
      navigate(
        `/course-internal/module/article/${checkSheetData?.next_id?.id}`
      );
    } else if (checkSheetData?.next_id?.type === "stella_assessment") {
      navigate(
        `/course-internal/talk-to-stella?assessmentID=${checkSheetData?.next_id?.id}`
      );
    } else if (checkSheetData?.next_id?.type === "mood_monitor") {
      navigate(
        `/course-internal/module/mood-monitor?id=${checkSheetData?.next_id?.id}`
      );
    }
  };

  const handlePrevModuleButton = () => {
    navigate(
      `/course-internal/course-view/${checkSheetData?.previous_module_id?.id}`
    );
  };

  const handleNextModuleButton = () => {
    navigate(
      `/course-internal/course-view/${checkSheetData?.next_module_id?.id}`
    );
  };

  const handleBookmarkClick = () => {
    let body = { sub_level_id: subLevelId };
    addBookmarkEntry(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        // setBookmarkDataStatus(resp?.data);
        //console.log(resp?.data?.bookmarked)
        setIsBookmarked(resp?.data?.bookmarked);
        // console.log("resp?.dataresp?.data", resp?.data);
      } else {
        //console.log(resp?.ResponseMessage);
      }
    });
  };

  return (
    <section className="practice">
      <Helmet>
        <title>Program | Daily Practice</title>
      </Helmet>
      {isLoading ? (
        <div
          className="d-flex align-items-center justify-content-center mx-auto"
          style={{ minHeight: "100vh" }}
        >
          <img alt="" src="/assets/img/kareify/loader.gif" />
        </div>
      ) : (
        <>
          <section className="daily-practice-sidebar">
            <Sidebar />
          </section>
          <SidebarMobile />
          <section className="practice-section">
            <div className="daily-practice-bg-img">
              <img src={section_bg_img} alt="" loading="lazy" />
            </div>

            <div className="practice-container">
              <div className="sections-heading">
                {checkSheetData?.course_name}
                <span>
                  {" >"} {checkSheetData?.content?.name}
                </span>
              </div>
              <div className="sections-progress">
                Section {checkSheetData?.current_level_index + 1}/
                {checkSheetData?.level_count}
              </div>
              <div className="quiz-sections-name">
                <div style={{ display: "flex", gap: "10px" }}>
                  Daily Practice
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <img
                    src={info_icon}
                    alt=""
                    loading="lazy"
                    className="quiz-info-icon"
                    onClick={() => setShowModal(true)}
                  />
                  <Popup
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    data={modal_data[0]}
                  />
                  <div
                    className="quiz-save"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleBookmarkClick()}
                  >
                    {isBookmarked ? (
                      <BookmarkIcon color="#541cf5" />
                    ) : (
                      <BookmarkBorderOutlinedIcon />
                    )}
                  </div>
                </div>
              </div>
              <hr style={{ color: "#E0E0E0" }} />
              <div className="practice-section-desc">
                {checkSheetData?.content?.description}
              </div>

              <div className="lists">
                <div className="practice-try-list">
                  <div className="list-title try-list-title">Why not Try?</div>
                  <div className="list-items">
                    {tryList?.map((item, i) => (
                      <button className="try-list-item" key={i} disabled={isWhyNotTryClicked}>
                        <img
                          src={practice_plus}
                          alt=""
                          loading="lazy"
                          className="plus-icon"
                          onClick={() => HandleAddtoMydailyList(item)}
                        />
                        <div className="try-item-desc">{item.name}</div>
                      </button>
                    ))}
                  </div>
                </div>

                <div className="practice-list">
                  <div className="list-title practice-list-title">
                    My Daily Practice List
                  </div>
                  <div className="list-items">
                    {myList?.map((item, i) => (
                      <div
                        key={item.id}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className="practice-list-item">
                          <button
                            onClick={() => HandleAddtoCompleteList(item)}
                            disabled={isPracticeListItemClicked}
                            className="tick-icon"
                          />
                          <div className="practice-item-desc">{item.name}</div>
                          {/* {isEditing && editingItemId === item?.id ? (
                        <div className="add-step-textarea">
                          <textarea
                            className="add-step-text"
                            value={textValue}
                            onChange={(e) => setTextValue(e.target.value)}
                            placeholder={item.name}
                            rows="1"
                            cols="30"
                          />
                          <div
                            className="add-step-save-text"
                            onClick={handleSaveClick}
                          >
                            Save
                          </div>
                        </div>
                      ) : (
                        <div className="practice-item-desc">{item.name}</div>
                      )} */}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            gap: "1rem",
                          }}
                        >
                          <Button
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={(e) => handleClickOption(e, item.id)}
                            sx={{ minWidth: "max-content" }}
                          >
                            {/* <img
                          src={edit_dialog}
                          alt=""
                          loading="lazy"
                          className="edit-dialog-icon"
                          // onClick={() => handleEditClick(item)}
                        /> */}
                            <MoreVertIcon />
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleCloseOption}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            {/* <MenuItem onClick={() => handleEditClick(item)}>
                          Edit
                        </MenuItem> */}
                            <MenuItem onClick={() => handleDeleteClick(deleteDailyp_id)}>

                              Delete
                            </MenuItem>
                          </Menu>
                          {/* <Dropdown className="d-flex flex-row-reverse gap-1">
                        <Dropdown.Toggle
                          variant="info"
                          id="dropdown-basic"
                          className="edit-popup"
                        >
                        
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <div
                              className="edit"
                              onClick={() => handleEditClick(item)}
                            >
                              <ModeEditOutlineOutlinedIcon className="edit-icon" />
                              <p>Edit</p>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div
                              className="delete"
                              onClick={() => handleDeleteClick(item)}
                            >
                              <img
                                loading="lazy"
                                src={delete__icon}
                                alt=""
                                className="delete__icon"
                              />
                              <p>Delete</p>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mt-3">
                    {isEditing ? (
                      <div className="add-step-textarea">
                        <input
                          className="add-step-text"
                          value={textValue}
                          onChange={(e) => setTextValue(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && textValue !== "") {
                              handleSaveClick();
                              setTextValue("");
                            }
                          }}
                          rows="1" // Set the number of rows as needed
                          cols="30" // Set the number of columns as needed
                        />
                        <div
                          className="add-step-save-text"
                          onClick={handleSaveClick}
                          style={

                            textValue.trim() === ""
                              ? { color: "#73589b91" }
                              : { color: "#73589b" }
                          }
                        >
                          Save
                        </div>
                      </div>
                    ) : (
                      <div
                        className="practice-btn"
                        onClick={handleAddStepClick}
                      >
                        <img
                          alt=""
                          src={plus_btn_icon}
                          className="plus-icon-btn"
                        />
                        <div className="btn-text" onClick={handleAddStep}>
                          Add Step
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="done-list">
                  <div className="list-title practice-list-title">Done</div>
                  <div className="list-items">
                    {completedList?.map((item, i) => (
                      <div className="done-list-item">
                        <img
                          src={green_tick}
                          alt=""
                          loading="lazy"
                          className="tick-icon"
                        />
                        <div className="practice-item-desc">{item.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="daily-practice-btn d-flex align-items-center justify-content-between">
                <div>
                  {checkSheetData?.previous_id?.type && (
                    <div className="prev-btn" onClick={handlePrevButton}>
                      <div className="modules-prev-text">Previous</div>
                      <div className="prev-arrow">
                        <img
                          src={left_arrow}
                          alt=""
                          loading="lazy"
                          className="arrow-img"
                        />
                      </div>
                    </div>
                  )}
                  {checkSheetData?.previous_module_id?.id &&
                    !checkSheetData?.previous_id?.type && (
                      <div
                        className="prev-btn"
                        onClick={handlePrevModuleButton}
                      >
                        <div className="modules-text">Previous Module</div>
                        <div className="prev-arrow">
                          <img
                            src={left_arrow}
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                          />
                        </div>
                      </div>
                    )}
                </div>
                <div>
                  {checkSheetData?.next_id?.type && (
                    <div
                      className="next-btn"
                      style={{ marginLeft: "auto" }}
                      onClick={handleNextButton}
                    >
                      <div className="modules-text">Next</div>
                      <div className="next-arrow">
                        <img
                          src={arrow}
                          alt=""
                          loading="lazy"
                          className="arrow-img"
                        />
                      </div>
                    </div>
                  )}
                  {checkSheetData?.next_module_id?.id &&
                    !checkSheetData?.next_id?.type && (
                      <div
                        className="next-btn"
                        onClick={handleNextModuleButton}
                      >
                        <div className="modules-text">Next Module</div>
                        <div className="next-arrow">
                          <img
                            src={arrow}
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </section>
  );
};

export default DailyPractice;
