
import jwt_decode from "jwt-decode";


const headers = {
  "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
  "Content-Type": "application/json",
};

export const getSessionID = async () => {
    try {
       
        const response = await fetch(`https://odoo.unitedwecare.ca/im_livechat/get_session`, {
          headers,
        });

       

        if (!response.ok) {
          // Handle error response
          throw new Error("Error fetching languages");
        }

        const jsonData = await response.json();

        const token = jsonData.data;
        const { sender_id } = jwt_decode(token);
       
        sessionStorage.setItem("stella_s_id", sender_id);

        return sender_id;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
}

export const callAwaitStart = async (body, ID) => {
    
  try {
    const response = await fetch(
      `https://awtkafka.uwc.world/kafka-message/${ID}`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      }
    );

    const postResponse = await response.json();
    //console.log("Await start", postResponse);

    return postResponse;
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
};
