import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button } from 'react-bootstrap'
import DisclaimerModal from './DisclaimerModal';
import OTPModal from './OTPModal';

const currencies = [
    {
        value: 'USD',
        label: '$',
    },
    {
        value: 'EUR',
        label: '€',
    },
    {
        value: 'BTC',
        label: '฿',
    },
    {
        value: 'JPY',
        label: '¥',
    },
];

const SignUp2 = () => {

    const [modalShow, setModalShow] = useState(false);
    const [otpModalShow, setOtpModalShow] = useState(false)

    return (
        <div className='kareify--SignIn d-flex flex-column align-items-center align-items-md-end justify-content-md-end justify-content-center'>
            <div className='SignIn--box col-12 col-md-4 col-xxl-3 d-flex flex-column align-items-center gap-3'>
                <h3>
                    Create Account
                </h3>

                <div className='d-flex align-items-center justify-content-around w-75'>
                    <img src='/assets/img/kareify/Google.png' onClick={() => setOtpModalShow(true)} alt='' />
                    <img src='/assets/img/kareify/Apple.png' alt='' />
                    <img src='/assets/img/kareify/Twitter.png' alt='' />
                    <img src='/assets/img/kareify/linkedin.png' alt='' />
                </div>

                <OTPModal
                    show={otpModalShow}
                    onHide={() => setOtpModalShow(false)}
                />

                <div className='d-flex align-items-center w-100 gap-2 px-5 horizontal--line'>
                    <hr />
                    <p>Or</p>
                    <hr />
                </div>

                <p>
                    Sign Up with your email address
                </p>

                <TextField id="outlined-basic" label="Email Address *" variant="outlined" className='w-75' />
                <TextField id="outlined-basic" label="Full Name *" variant="outlined" className='w-75' />
                <TextField id="outlined-select-currency" select label="Country *" defaultValue="EUR" className='w-75'>
                    {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField id="outlined-select-currency" select label="Time Zone *" defaultValue="EUR" className='w-75'>
                    {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField id="outlined-basic" label="Referral code (Optional)" variant="outlined" className='w-75' />

                <Button className='border-0' onClick={() => setModalShow(true)}>
                    Continue
                </Button>

                <DisclaimerModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />

                <p>
                    Already have an account? <span className='diff-clr'>Login</span>
                </p>
            </div>
            {/* <h2 className='col-12 col-md-4 col-xxl-3'>
                “You are worthy of happiness and peace of mind.”
            </h2> */}
        </div>
    )
}

export default SignUp2