import axios from "axios";
import { Config } from "../../core/_request";
import jwtDecode from "jwt-decode";

export const getCourseDetailsV2 = async (body) => {
  const resp = await axios.post(
    `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/v2/community/course/detail`,
    body,
    Config()
  );
  const resp_1 = resp.data;
  if (resp_1.data) resp_1.data = resp_1.data;
  return resp_1;
};
