import React from "react";
import ".././Plans.css";

const PersonalDataArea = () => {
  return (
    <div>
      {/* <!------ personal-data-area (desktop-display) ------> */}
      <div className="personal-data-area mobile-display-none desktop-display">
        <div className="container">
          <img
            src="assets/img/personalData_1.png"
            alt=""
            className="personal-data-inner"
          />
          {/* <div className="personal-data-inner">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="personal-data-text">
                                    <h3>Your personal data is <span className="diff-clr"> secure</span> with us!</h3>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="personal-data-img">
                                    <ul>
                                        <li><img alt='' src="assets/img/iso.webp.png" /></li>
                                        <li><img alt='' src="assets/img/hippa.webp.png" /></li>
                                        <li><img alt='' src="assets/img/eu.webp.png" /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>

      {/* <!------ personal-data-area (mobile-display) ------> */}
      <div className="personal-data-area mobile-display desktop-display-none">
        <div className="container">
          <div className="personal-data-inner">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="personal-data-text">
                  <h3>
                    Your personal data is{" "}
                    <span className="diff-clr"> secure</span>
                    <br /> with us!
                  </h3>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="d-flex align-items-center gap-3 justify-content-md-start justify-content-center">
                  <img
                    src="/assets/img/iso.webp.png"
                    alt=""
                    className=" d-block mx-auto"
                  />
                   <img
                    src="/assets/img/hippa.webp.png"
                    alt=""
                    className=" d-block mx-auto"
                  />
                   <img
                    src="/assets/img/image 17763.png"
                    alt=""
                    className=" d-block mx-auto"
                  />
                   <img
                    src="/assets/img/image 17762.png"
                    alt=""
                    className=" d-block mx-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDataArea;
