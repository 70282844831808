import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Card, Modal, Tab, Tabs, ToggleButton } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
// import { getCourseDetail_WebService, getCourseList_WebService } from '../core/_request';
// import { courseData } from './core/models'
import { useAuth } from '../../auth'
import { getCourseDetail_WebService, getDashboardCourseList_WebService, course_clubs, course_web_lists, course_time_filter, course_price_filter } from '../core/_request';
import { getCountryCode, getCurrencyCode } from '../core/commonFun';
import { useContext } from 'react';
import { AppContext } from '../../context';
import LoadingSpinner from '../LoadingSpinner';

const CourseListing = () => {
    const navigate = useNavigate();
    // const [descriptionText, setDescriptionText] = useState();
    const { currentUser } = useAuth();
    const [time_Filter_Data, setTime_Filter_Data] = useState([]);
    const [price_Filter_Data, setPrice_Filter_Data] = useState([]);
    const [time_Filter_Data_selected, setTime_Filter_Data_selected] = useState({});
    const [final_selected, setFinal_selected] = useState();
    const [price_Filter_Data_selected, setPrice_Filter_Data_selected] = useState({});
    const [club_Filter_Data_selected, setClub_Filter_Data_selected] = useState([]);
    const [myCourseData, setMyCourseData] = useState([]);
    const [headerClubsData, setHeaderClubsData] = useState([]);
    const [culb_current_id, setCulb_current_id] = useState([]);
    const [currentlySelected, setCurrentlySelected] = useState(false)
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    const [filtershow, setFiltershow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isclubremove, setIsclubremove] = useState(false);
    const [istimeremove, setIstimeremove] = useState(false);
    const [ispriceremove, setIspriceremove] = useState(false);
    const [isdataAvailable, setIsdataAvailable] = useState(false);
    const [istabfilter, setIstabfilter] = useState(false); 
    const countryCode = getCountryCode();
    const CurrencyCode = getCurrencyCode();

    useEffect(() => {
        // const body = { "userId": currentUser?.UserId || "" }
        // getDashboardCourseList_WebService(body).then(resp => {
        //     console.log("resp Data", resp.data)
        //     setMyCourseData(resp.data);
        // });

        course_time_filter().then(resp => {
            setTime_Filter_Data(resp?.data?.time);
        });

        const price_body = { "country": countryCode == "IN" ? "India" : "US" }
        course_price_filter(price_body).then(resp => {
            setPrice_Filter_Data(resp?.data?.price);
        });

        course_clubs().then(resp => {
            setHeaderClubsData(resp?.data?.clubs);
            console.log("setCourse_details", resp?.data?.clubs)
        });

        bindCourseData();

    }, []);

    const handleChange_club_Filter = event => { 
        const { checked, value } = event.currentTarget; 
        var Json_val = JSON.parse(value)
        setClub_Filter_Data_selected(
            prev => checked
                ? [...prev, Json_val]
                : prev.filter(val => val?.id !== Json_val?.id)
        ); 
        setCurrentlySelected(true);
    };

    const handleChange_time_Filter = event => {
        const { checked, value } = event.currentTarget;
        var Json_val = JSON.parse(value)
        setTime_Filter_Data_selected(Json_val);
        setCurrentlySelected(true);
    };

    const handleChange_price_Filter = event => {
        const { checked, value } = event.currentTarget;
        var Json_val = JSON.parse(value)
        setPrice_Filter_Data_selected(Json_val);
        setCurrentlySelected(true);
    };

    const handel_Apply_Filter = () => { 
        debugger
        var club_Filter = club_Filter_Data_selected?.map((item)=> {return item.id});
        // setFinal_selected({ "club": club_Filter_Data_selected, "time": time_Filter_Data_selected, "price": price_Filter_Data_selected });
        setFinal_selected({ "club": club_Filter, "time": time_Filter_Data_selected, "price": price_Filter_Data_selected });
        setFiltershow(false);
        setMyCourseData([]);
        bindCourseData();
    }

    const handel_Clear_Filter = () => { 
        setMyCourseData([]);
        setFinal_selected([]);
        setTime_Filter_Data_selected({});
        setPrice_Filter_Data_selected({});
        setClub_Filter_Data_selected([]);
        setCulb_current_id(0);
        setIsclubremove(true);
        bindCourseData();
        setCurrentlySelected(false);
    }

    const handel_remove_club = (item) => {
        setMyCourseData([]);
        setClub_Filter_Data_selected([]);
        setFinal_selected((prevState) => { return { ...prevState, "club": null } });
        //bindCourseData();
        setCulb_current_id(0);
        setIsclubremove(true);
    }

    const handel_remove_time = (item) => {
        setMyCourseData([]);
        setTime_Filter_Data_selected({});
        setFinal_selected((prevState) => { return { ...prevState, "time": null } });
        //bindCourseData();
        setIstimeremove(true);
    }

    const handel_remove_price = (item) => {
        setMyCourseData([]);
        setPrice_Filter_Data_selected({});
        setFinal_selected((prevState) => { return { ...prevState, "price": null } });
        //bindCourseData();
        setIspriceremove(true);
    }

    useEffect(() => {
        bindCourseData();
        setIsclubremove(false);
        setIstimeremove(false);
        setIspriceremove(false);
    }, [isclubremove, istimeremove, ispriceremove])

    const onClick = (id) => {
        navigate(`/course/details/${id}`);
    }

    // const onClick = (item) => {
    //     // console.log("items", item) 
    //     if (!currentUser) {
    //         localStorage.setItem('retrunurl', "/course");
    //         setShowLloginPopup(true)
    //     }
    //     else {
    //         const body = { "courseId": item?.id, "userId": currentUser?.UserId }
    //         // console.log("req", body)

    //         // var tcourseData = courseData.find(o => o.id == item?.id);
    //         var tcourseData = myCourseData.find(o => o.id == item?.id);

    //         getCourseDetail_WebService(body).then(resp => {
    //             // console.log("resp09", resp)
    //             if (resp?.Data && resp?.Data && resp?.Data?.type == "detail" && tcourseData) {
    //                 navigate(`/dashboard/course/details/${tcourseData?.urlTitle}`);
    //             }
    //             else if (resp?.Data && resp?.Data && resp?.Data?.type == "options") {
    //                 navigate(`/dashboard/course/course-questions/${tcourseData?.id}`);
    //             }
    //             else if (resp?.Data && resp?.Data && resp?.Data?.type == "consultation" && tcourseData) {
    //                 navigate(`/book-an-appointment/${tcourseData?.urlTitle}`);
    //             }
    //             else if (resp?.Data && resp?.Data && resp?.Data?.type == "payment" && tcourseData) {
    //                 navigate(`/course/details/${tcourseData?.urlTitle}`);
    //             }
    //             else if (resp?.Data && resp?.Data && resp?.Data?.type == "dashboard") {
    //                 navigate("/dashboard/course");
    //             }
    //             else if (resp?.Data && resp?.Data && resp?.Data?.type == "course" && tcourseData) {
    //                 // console.log("resp?.Data?.data?.type", resp?.Data?.data?.type)
    //                 navigate(`/dashboard/course/course-preview/${tcourseData?.urlTitle}`);
    //             }
    //             //setCourseDetailData(resp.Data);
    //             // console.log("respData", resp.Data)
    //         });
    //     }
    // }


    const onClickEnroll = (item) => {
        const body = { "userId": currentUser?.UserId, "courseId": item.id }
        console.log("req", body);
        navigate(`/course/details/${item?.urlTitle}`);
    }

    const onClubClick = (item) => {   
        var IsExists = club_Filter_Data_selected && club_Filter_Data_selected?.some((o)=> o?.id === item?.id); 
        if(IsExists)
        { 
            setClub_Filter_Data_selected((prevVals) => [...prevVals.filter((o) => o?.id != item?.id)]); 
        }
        else{
            setClub_Filter_Data_selected((prevVals) => [...prevVals, item]);
        }
        setIstabfilter(true); 
    }

    useEffect(() => {
        bindCourseData();
        setIstabfilter(false);
    }, [istabfilter])

    const bindCourseData = () => { 
        setIsLoading(true); 
        const body = { 
            "clubs": (club_Filter_Data_selected?.map((item)=> {return item.id}) ?? []),
            "days": time_Filter_Data_selected?.id ?? "",
            "price": countryCode == "IN" ? (price_Filter_Data_selected?.id ?? "") : "",
            "usd_price": countryCode != "IN" ? (price_Filter_Data_selected?.id ?? "") : ""
        }
        course_web_lists(body).then(resp => {
            if (resp?.data?.courses) {
                setIsdataAvailable(true);
            }
            else {
                setIsdataAvailable(false);
            }
            setMyCourseData(resp?.data?.courses);
            setIsLoading(false);
        });
    }

    return ( 
        <div className='pt-1' style={{ backgroundImage: `url("/assets/img/bg-3.png")`, backgroundRepeat: "no-repeat", backgroundSize: "100% 176px" }}>
            <div className='container'>
                <h3 className='font-inter text-center font-w600 mt-4 mb-2 fs-4'>
                    Our Wellness Programs 
                </h3>
                {/* <p className='font-inter text-center py-2 fs-6 col-md-3 mx-auto' style={{ color: "#494747" }}>
                    Our online courses are scientifically proven to help with the most common mental health problems.
                </p> */}
                <ButtonGroup className='d-flex flex-row align-items-center justify-content-start gap-3 hideScrollbar' style={{ overflowX: "scroll" }}>
                    <ToggleButton
                        onClick={(e) => setClub_Filter_Data_selected([]) || setIstabfilter(true)}
                        type="radio"
                        name="radio"
                        className="rounded-2 px-4 px-xl-2 px py-1 ms-2 border-0"
                        // defaultChecked
                        style={{
                            background: (culb_current_id === 0 ? "#1B8BCF" : "#F3F3F3"),
                            // background: "#1B8BCF",
                            color: (culb_current_id === 0 ? "#FFFFFF" : "#21231E"),
                            border: "1px solid #50B3EF",
                            minWidth: "100px !important",
                            height: "40px",
                        }}>
                        <p className='font-inter fs-6 text-center lh-lg'>All</p>
                    </ToggleButton>
                    {
                        headerClubsData?.map((item) => (
                            <div>
                                <ToggleButton
                                    onClick={(e) => onClubClick(item)}
                                    type="checkbox" 
                                    className="rounded-2 px-2 py-1 ms-1 border-0" 
                                    // defaultChecked
                                    style={{
                                        background: (club_Filter_Data_selected && club_Filter_Data_selected?.some((o)=> o?.id === item?.id) ? "#1B8BCF" : "#F3F3F3"),
                                        color: (club_Filter_Data_selected && club_Filter_Data_selected?.some((o)=> o?.id === item?.id) ? "#FFFFFF" : "#21231E"),
                                        border: "1px solid #50B3EF",
                                        maxWidth: "auto",
                                        height: "40px",
                                    }}>
                                    <p className='font-inter fs-6 text-center lh-lg' style={{ whiteSpace: 'nowrap' }}>{item?.name}</p>
                                </ToggleButton>
                            </div>
                        ))}
                </ButtonGroup>
                <div className='d-flex align-items-center justify-content gap-3 my-3'>
                    <span style={{ cursor: 'pointer' }} className='d-flex align-items-center gap-2 text-decoration-none' onClick={() => setFiltershow(true)}>
                        <img src='/assets/img/Mask group(8).png' alt='' />
                        <p className='font-inter  text-black'>Refine your search</p>
                    </span>
                    {/* <div className='vr'></div> */}
                    {/* {
                        final_selected && final_selected?.club && final_selected?.club?.name &&
                        <span onClick={(e) => { handel_remove_club(final_selected?.club) }} className=' d-flex text-decoration-none rounded-5 px-3 align-items-center fs-6' style={{ background: "#333131", color: "#fff", border: "1px solid #333131", cursor: "pointer", whiteSpace: "nowrap", marginRight: 3, display: '-ms-inline-flexbox', padding: 2, lineHeight: 2 }}> <img src='/assets/img/closeIcon.png' alt='Filter' style={{ height: 10, width: 10 }} /> &nbsp; {final_selected?.club?.name}</span>
                    } */}
                    {
                        final_selected && final_selected?.time && final_selected?.time?.name &&
                        <span onClick={(e) => { handel_remove_time(final_selected?.time) }} className=' d-flex text-decoration-none rounded-5 px-3 align-items-center fs-6' style={{ background: "#333131", color: "#fff", border: "1px solid #333131", cursor: "pointer", whiteSpace: "nowrap", marginRight: 3, display: '-ms-inline-flexbox', padding: 2, lineHeight: 2 }}> <img src='/assets/img/closeIcon.png' alt='Filter' style={{ height: 10, width: 10 }} /> &nbsp; {final_selected?.time?.name}</span>
                    }
                    {
                        final_selected && final_selected?.price && final_selected?.price?.name &&
                        <span onClick={(e) => { handel_remove_price(final_selected?.price) }} className=' d-flex text-decoration-none rounded-5 px-3 align-items-center fs-6' style={{ background: "#333131", color: "#fff", border: "1px solid #333131", cursor: "pointer", whiteSpace: "nowrap", marginRight: 3, display: '-ms-inline-flexbox', padding: 2, lineHeight: 2 }}> <img src='/assets/img/closeIcon.png' alt='Filter' style={{ height: 10, width: 10 }} /> &nbsp; {final_selected?.price?.name}</span>
                    }
                </div>
                <Modal
                    show={filtershow}
                    onHide={() => setFiltershow(false)}
                    dialogClassName="left-modal"
                    aria-labelledby="example-custom-modal-styling-title"
                    className='m-0 col-8'
                // style={{ height: "100vh" }}
                >
                    <div>
                        <div className='m-0 ' style={{ height: "99vh" }}>
                            <Modal.Header className='justify-content-end mt-4 ' closeButton>
                                <Modal.Title className='font-inter fs-5 text-end ms-auto text-black' id="example-custom-modal-styling-title">
                                    Filter search results
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Tabs
                                    defaultActiveKey="Club-Filter"
                                    id="justify-tab-example"
                                    className="mb-3 d-flex flex-row flex-nowrap"
                                    justify
                                >
                                    <Tab eventKey="Club-Filter" title="Category" className='filterTab'>
                                        <div style={{ scrollBehavior: 'smooth', overflowY: 'scroll', height: 480 }}>
                                            <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                                <input
                                                    id={`Club_Filter0`}
                                                    name='Time'
                                                    value={JSON.stringify({ id: 0, name: 'All' })}
                                                    type="checkbox"
                                                    checked={club_Filter_Data_selected?.id === 0}
                                                    onChange={handleChange_club_Filter}
                                                    style={{ zoom: 1.3 }}
                                                />&nbsp;&nbsp;
                                                <label for={`Club_Filter0`} className='fs-6'>All</label>
                                            </div>

                                            {headerClubsData && headerClubsData.map((item, index) =>
                                                <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                                    <input
                                                        id={`Club_Filter${item.id}`}
                                                        name='Time'
                                                        value={JSON.stringify(item)}
                                                        type="checkbox"
                                                        checked={club_Filter_Data_selected?.some((o)=> item?.id === o?.id)} 
                                                        onChange={handleChange_club_Filter}
                                                        style={{ zoom: 1.3 }}
                                                    />&nbsp;&nbsp;
                                                    <label for={`Club_Filter${item.id}`} className='fs-6'>{item.name}</label>
                                                </div>
                                            )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Time-Filter" title="Duration" className='filterTab'>
                                        <div style={{ scrollBehavior: 'smooth', overflowY: 'scroll', height: 480 }}>
                                            {time_Filter_Data && time_Filter_Data.map((item, index) =>
                                                <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                                    <input
                                                        id={`Time_Filter${item.id}`}
                                                        name='Time'
                                                        value={JSON.stringify(item)}
                                                        type="checkbox"
                                                        checked={time_Filter_Data_selected?.id === item?.id}
                                                        onChange={handleChange_time_Filter}
                                                        style={{ zoom: 1.3 }}
                                                    />&nbsp;&nbsp;
                                                    <label for={`Time_Filter${item.id}`} className='fs-6'>{item.name}</label>
                                                </div>
                                            )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="price" title="Price" className='font-inter fs-6 font-w400'>
                                        <div style={{ scrollBehavior: 'smooth', overflowY: 'scroll', height: 480 }}>
                                            {price_Filter_Data && price_Filter_Data.map((item, index) =>
                                                <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                                    <input
                                                        id={`price_Filter${item.id}`}
                                                        name='Price'
                                                        value={JSON.stringify(item)}
                                                        type="checkbox"
                                                        checked={price_Filter_Data_selected?.id === item?.id}
                                                        onChange={handleChange_price_Filter}
                                                        style={{ zoom: 1.3 }}
                                                    />&nbsp;&nbsp;
                                                    <label for={`price_Filter${item.id}`} className='fs-6'>{item.name}</label>
                                                </div>
                                            )}
                                        </div>
                                    </Tab>
                                </Tabs>
                                <Modal.Footer className='d-flex justify-content-around' style={{ position: "fixed", bottom: "0", width: "85%", backgroundColor: '#fff', paddingBottom: 50 }}>
 
                                    {club_Filter_Data_selected?.length === 0 && time_Filter_Data_selected === 0 && price_Filter_Data_selected === 0 &&
                                        <Button disabled style={{ cursor: 'pointer !important', background: "none" }} className='border-0 text-black font-inter fs-6'>
                                            Clear All
                                        </Button> ||
                                        <Button onClick={handel_Clear_Filter} style={{ cursor: 'pointer !important', background: "none" }} className='border-0 text-black font-inter fs-6'>
                                            Clear All
                                        </Button>
                                    }
                                    {
                                        <Button disabled={!currentlySelected} onClick={handel_Apply_Filter} className='rounded-5 border-0 text-black font-inter fs-6 px-4' style={{ background: '#C4E5F9', cursor: 'pointer !important' }}>
                                            Apply
                                        </Button>

                                    }
                                </Modal.Footer>
                            </Modal.Body>
                        </div>
                    </div>
                </Modal>
                <div className='row g-3'>
                    {isLoading && <LoadingSpinner />}
                    {!isdataAvailable &&
                        <p className='font-inter fs-4 my-3'>
                            No Result Found!
                        </p>
                    }
                    {myCourseData?.map((item) => (
                        <div className='col-lg-4 col-md-6 col-sm-6' >
                            <a onClick={() => onClick(item?.id)}>
                                <div className='d-flex flex-column justify-content-between bg-white px-0 pt-0 pb-3 gap-2' style={{ cursor: "pointer" }}>
                                    <img variant="top" src={item?.website_thumbnail_url} alt='' className='rounded-3' />
                                    <div className='d-flex flex-column gap-3'>
                                        <p className='font-inter fs-5 font-w600 text-black'>
                                            {item?.name}
                                        </p>
                                    </div>
                                    <div className='d-flex gap-3'>
                                        <div>
                                            <p className='font-inter font-w500' style={{ fontSize: 14, color: "#454745", whiteSpace: "nowrap" }}>
                                                {item?.duration}
                                            </p>
                                        </div>
                                        <div className='' style={{ color: "#E2E2E1" }}>•</div>
                                        <div className='d-flex align-items-center gap-2'>
                                            <img src='/assets/img/image 14.png' alt='Star' />
                                            <p className='font-inter font-w500' style={{ fontSize: 14, color: "#454745", whiteSpace: "nowrap" }}>{item?.rating}</p>
                                        </div>
                                        <div className='' style={{ color: "#E2E2E1" }}>•</div>
                                        <div className='d-flex align-items-center gap-2'>
                                            <img src='/assets/img/icons8-translation-15.png' alt='Language' />
                                            <p className='font-inter font-w500' style={{ fontSize: 14, color: "#454745", whiteSpace: "nowrap" }}>{item?.languages}</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))
                    }
                </div>
            </div>
        </div >
    )
}
export default CourseListing