import React from "react";
import "../styles/Sidebar.css";
import {
  sidebar_home,
  sidebar_mood_monitor,
  sidebar_journal,
  sidebar_stella,
  UWC_logo,
  UWC_QR,
  my_library,
} from "../assets";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";

const Sidebar = () => {
  const { id, urlTitle } = useParams();
  let homeLink = "";
  let linksData = JSON.parse(sessionStorage.getItem("courseInternalLinks"));
  if (linksData !== undefined && linksData !== null) {
    homeLink = linksData.filter((item) => item.name == "Home");
  } else {
    if (
      id !== null &&
      id !== undefined &&
      id !== "" &&
      urlTitle !== null &&
      urlTitle !== undefined &&
      urlTitle !== ""
    ) {
      let linksData = [
        { name: "Home", uri: `/course-internal/${id}/${urlTitle}` },
        { name: "My Journal", uri: `/course-internal/module/my-journal/:id` },
      ];
      sessionStorage.setItem("courseInternalLinks", JSON.stringify(linksData));
      homeLink = [{ name: "Home", uri: `/course-internal/${id}/${urlTitle}` }]
    }
  }

  //console.log("homeLink", homeLink[0].uri)
  const sidebar_data = [
    {
      img: sidebar_home,
      text: "Home",
      link: homeLink[0].uri,
    },
    // {
    //   img: sidebar_stella,
    //   text: "Talk to Stella",
    //   link: "/stella",
    // },
    {
      img: sidebar_mood_monitor,
      text: "Mood Monitor",
      link: "/course-internal/module/mood-monitor",
    },
    // {
    //   img: sidebar_journal,
    //   text: "My Journal",
    //   link: "/course-internal/module/my-journal/:id",
    // },

    {
      img: my_library,
      text: "My Library",
      link: "/course-internal/library",
    },
  ];
  return (
    <>
      <section className="sidebar-section">
        <div className="sidebar-list">
          {sidebar_data.map((item, i) => (
            <div
              className="sidebar-li"
              onClick={() => {
                window.location.pathname = item.link;
              }}
            >
              <img
                src={item.img}
                className="sidebar-img"
                alt=""
                loading="lazy"
              />
              <p>{item.text}</p>
            </div>
          ))}
        </div>

        <div className="sidebar-app">
          <div className="sidebar-app-heading">
            <img src={UWC_logo} />
            <p>Use App</p>
          </div>
          <div className="sidebar-app-qr">
            <img src={UWC_QR} />
            <p className="sidebar-qr-text">
              Scan this QR code to use our mobile app
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sidebar;
