import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import "./SignUp.css"

const DisclaimerModal = (props) => {
    return (
        <div className='disclaimer--containers'>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='disclaimer--popup'
            >
                <Modal.Body>
                    <h4>Disclaimer</h4>
                    <div className='disclaimer-points'>
                        <p>
                            1: If you are thinking about suicide or if you are considering harming yourself or others or if you feel that any other person may be in any danger or if you have any medical emergency, you must immediately call the emergency service number (112 in India) and notify the relevant authorities, and seek immediate in person assistance.
                            <br />
                            2: You understand and acknowledge that We and the Service Providers have a duty to promptly report any suspicions that a child is or may be in need of protection to a children’s aid society including suspicion of physical, sexual and emotional abuse, neglect, and risk of harm.
                            <br />
                            3: You understand and acknowledge that Service Providers are permitted to disclose personal health information about an individual if they have reasonable grounds to believe disclosure is necessary to eliminate or reduce significant risk of serious bodily harm to a person or group of persons. Further, the use of information provided on the Platform and availing of Services on the Platform is solely at your own risk. United We Care is not and will not in any manner be involved in the practice of medicine or the provision of medical care.
                            <br />
                            4: The opinion, statement, response, assistance, guidance provided by Service Providers via the Platform are solely individual and independent opinion of the Service Providers and in no way reflect the opinion of United We Care, its affiliates or any other organizations or institutions of United We Care to which Service Provider is affiliated and/or provides services.
                            <br />
                            5: United We Care does not provide medical advice. All information including text, videos, graphics or written articles are for informational purposes only. No material on our website or App substitutes for professional advice, diagnosis or treatment. We highly recommend that you should consult a qualified professional before making any decisions regarding your health. We also urge our website/App users not to delay or disregard any medical advice from a professional on the basis of anything that you read or viewed on United We Care website or App.
                        </p>
                    </div>
                    <FormControlLabel control={<Checkbox />} label={<span>
                        I accept United We Care's{' '}
                        <a href="">Terms & Conditions</a> and{' '}
                        <a href="">Privacy Policy</a>
                    </span>} />
                    <Button>
                        Continue
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default DisclaimerModal