import { Routes, Route, BrowserRouter } from "react-router-dom";
import Layout from "../components/Layout";
import Home from "../pages/Home";
import LandingPage from "../pages/LandingPage";
import ExpertDetails from "../pages/ExpertDetails";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import BookAnAppointment from "../pages/BookAnAppointment";
import Dashboard from "../pages/dashboard";
import ExpertDashboard from "../pages/expertDashboard";
import About from "../pages/About";
import PaymentStatus from "../pages/PaymentStatus";
import TermAndCondition from "../components/TermAndCondition";
import PrivacyPolicy from "../components/PrivacyPolicy";
import Disclaimer from "../components/Disclaimer";
import CourseDetail from "../components/course/CourseDetail";
import CourseDetailID1 from "../components/course/courseDetailsComponents/CourseDetailID1";
import CourseListing from "../components/course/CourseListing";
// import CourseListing  from '../components/course/CourseListing'
import CoursePaymentStatus from "../pages/CoursePaymentStatus";
import ExpertBookingPreview from "../pages/ExpertBookingPreview";
import CourseOrderPreviewPage from "../pages/CourseOrderPreviewPage";
import ScrollToTop from "../ScrollToTop";
import RegisterAsProfessional from "../components/professional/RegisterAsProfessional";
import Stella from "../pages/Stella";
import SubscriptionUpgrade from "../components/subscriptions/SubscriptionUpgrade";
import SubscriptionPlans from "../components/subscriptions/SubscriptionPlans";
import CancelSubscriptions from "../components/subscriptions/CancelSubscriptions";
import CancelSubscriptionNote from "../components/subscriptions/CancelSubscriptionNote";
import SubscriptionOrderSummary from "../components/subscriptions/SubscriptionOrderSummary";
import MySubscription from "../components/subscriptions/MySubscription";
import OnboardingStella from "../pages/OnboardingStella";
import OverlayStella from "../components/OverlayStella";
import Plans from "../components/subscriptions/Plans";
import Listing from "../components/course/Listing";
import ThankYou from "../components/subscriptions/ThankYou";
import TransactionFailed from "../components/subscriptions/TransactionFailed";
import Callback from "../components/Callback";
import NewPlansPage1 from "../components/subscriptions/NewPlansPage-1";
import PlanDetailsPage from "../components/subscriptions/PlanDetailsPage";
import Course from "../components/courseNew/Course";
import CourseInternal from "../components/courseInternalJourney/CourseInternal";
import Modules from "../components/courseInternalJourney/components/Modules";
import Section from "../components/courseInternalJourney/components/Section";
import MoodMonitor from "../components/courseInternalJourney/components/MoodMonitor";
import Quiz from "../components/courseInternalJourney/components/Quiz";
import CompleteQuiz from "../components/courseInternalJourney/components/CompleteQuiz";
import Library from "../components/courseInternalJourney/components/Library";
import DailyPractice from "../components/courseInternalJourney/components/DailyPractice";
//import { Navbar } from 'react-bootstrap';
import Main from "../components/dashboard/Components/Dashboard/Main";
import My_programs from "../components/dashboard/Components/My programs/My_programs";
import SignIn2 from "../components/SignIn2";
import SignUp2 from "../components/SignUp2";
import CourseInternalRouting from "./CourseInternalRouting";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      {/* <OverlayStella /> */}
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/landing-page" element={<LandingPage />} />
          <Route
            path="/profession/:profession/:expertName/:id"
            element={<ExpertDetails />}
          />
          <Route
            path="/book-an-appointment/:id"
            element={<BookAnAppointment />}
          />
          <Route
            path="/payment-order-preview/:id"
            element={<ExpertBookingPreview />}
          />

          <Route path="/payment-status/:id" element={<PaymentStatus />} />
          <Route path="/terms-and-conditions" element={<TermAndCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          {/* <Route path='/course/details/:id' element={<CourseDetail />} /> */}
          {/* <Route path="/course/:name/:id" element={<CourseDetailID1 />} /> */}

          <Route
            path="/course/payment-status/:id"
            element={<CoursePaymentStatus />}
          />

          <Route
            path="/course/course-order-preview/:id"
            element={<CourseOrderPreviewPage />}
          />
          <Route path="/program" element={<Listing />} />
          {/* <Route path="/course-new" element={<Course />} />  */}
          <Route path="/program/:name/:id" element={<Course />} />
          <Route path="/course-new/:name/:id" element={<Course />} />
          <Route
            path="/course-internal/*"
            element={<CourseInternalRouting />}
          />
          {/* <Route path="/course-internal" element={<CourseInternal />} />
          <Route path="/course-internal/module" element={<Modules />} />
          <Route path="/course-internal/module/section" element={<Section />} />
          <Route
            path="/course-internal/module/mood-monitor"
            element={<MoodMonitor />}
          />
          <Route path="/course-internal/module/quiz" element={<Quiz />} />
          <Route
            path="/course-internal/module/quiz-completed"
            element={<CompleteQuiz />}
          />
          <Route path="/course-internal/library" element={<Library />} />
          <Route
            path="/course-internal/module/daily-practice"
            element={<DailyPractice />}
          /> */}

          {/* <Route path='/register-as-professional' element={<RegisterAsProfessional />} /> */}

          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/expertdashboard/*" element={<ExpertDashboard />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/login1" element={<SignIn2 />} />
          <Route path="/create-account" element={<SignUp />} />
          <Route path="/create-account2" element={<SignUp2 />} />
          <Route path="/subscription-plans" element={<SubscriptionPlans />} />
          <Route
            path="/subscription-upgrade"
            element={<SubscriptionUpgrade />}
          />
          <Route
            path="/cancel-subscription"
            element={<CancelSubscriptions />}
          />
          <Route
            path="/cancel-subscription-note"
            element={<CancelSubscriptionNote />}
          />
          <Route
            path="/subscription-order-summary/"
            element={<SubscriptionOrderSummary />}
          />
          <Route path="/my-subscription" element={<MySubscription />} />
          <Route path="/onboarding-stella" element={<OnboardingStella />} />
          <Route path="/stella" element={<Stella />} />
          <Route path="/plans" element={<NewPlansPage1 />} />
          <Route path="/listing" element={<Listing />} />
          <Route path="/payment-success" element={<ThankYou />} />
          <Route path="/payment-failed" element={<TransactionFailed />} />
          <Route
            path="/platinum-care-plan"
            element={<PlanDetailsPage type="Platinum" />}
          />
          <Route
            path="/premium-care-plan"
            element={<PlanDetailsPage type="Premium" />}
          />
          <Route
            path="/super-care-plan"
            element={<PlanDetailsPage type="Super" />}
          />
          <Route path="/callback" element={<Callback />} />

          <Route path="/user-dashboard" element={<Main />} />

          <Route path="/My-programs" element={<My_programs />} />
        </Route>

      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
