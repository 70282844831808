import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { SendRequestOTPService, LoginByOTPService } from "./core/_request";
import { useAuth } from "../auth";
import SignupComponent from "./SignupComponent";
const SignInComponent = () => {
  const { login, setCurrentUser, userType } = useAuth();
  const [step, setStep] = useState(1);
  const [validationMessage, setValidationMessage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMailRegistered, setIsMailRegistered] = useState(false);
  const [data, setData] = useState();
  const navigate = useNavigate();
  //const{retrunurl}=useParams();
  const retrunurl = null;
  const params = new URLSearchParams(window ? window.location.search : {});
  // useEffect(()=>{
  //console.log("params", params.get("retrunurl"))
  // },[]);

  const onLogin = () => {
    setValidationMessage([]);
    setLoading(true);
    switch (step) {
      case 1: {
        var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        if (!pattern.test(data.Email)) {
          let newValidationMessage = Object.assign(
            [],
            [
              ...validationMessage.filter((o) => o.key != "Email"),
              { key: "Email", message: "not a valid e-mail address" },
            ]
          );
          setValidationMessage(newValidationMessage);
          setLoading(false);
          break;
        }

        var req = { Email: data.Email };
        SendRequestOTPService(req).then((resp) => {
          setLoading(false);
          //console.log("SendRequestOTPService resp", resp);
          if (resp != null && resp.ResponseCode == 200) {
            setIsMailRegistered(resp.data.IsMailRegistered);
            setStep(2);
          } else if (resp != null && resp.ResponseCode == 401) {
            //console.log("ResponseCode 401");
          }
        });

        break;
      }
      case 2: {
        var req = { Email: data.Email, OTP: data?.OTP };
        LoginByOTPService(req).then((resp) => {
          //console.log("LoginByOTPService resp", resp);
          if (resp != null && resp.ResponseCode == 200) {
            if (resp.data.IsLogin) {
              let UserType = resp.data.UserType;
              let profile = resp.data.userdetails;
              profile.UserType = UserType;
              login(resp.data);
              setCurrentUser(profile);
              //localStorage.setItem("Authorization", resp.data.token_type + " " + resp.data.access_token);
              //localStorage.setItem("profile", JSON.stringify(profile));
              const retrunurl = params.get("retrunurl");
              console.log(sessionStorage.getItem("Login-Invoked"));
              if (retrunurl) {
                //window.location.href=retrunurl;
                navigate(retrunurl);
              } else {
                if (profile.UserType == userType.ENDUSER) {
                  navigate("/onboarding-stella");
                } else if (profile.UserType == userType.EXPERT) {
                  navigate("/expert/dashboard");
                } else {
                  navigate("/");
                }
              }
            } else {
              setLoading(false);
              setStep(3);
            }
          } else {
            let newValidationMessage = Object.assign(
              [],
              [
                ...validationMessage.filter((o) => o.key != "OTP"),
                { key: "OTP", message: resp.ResponseMessage },
              ]
            );
            setValidationMessage(newValidationMessage);
            setLoading(false);
          }
        });

        break;
      }
      default:
        console.log("Something Went Wrong!");
    }
  };

  return (
    (step == 3 && <SignupComponent step={step} data={data} />) || (
      <div className="d-flex align-items-center gap-4 py-5 py-md-0">
        <div
          className="d-none d-md-block"
          style={{
            backgroundImage: `url("/assets/img/signin/bannerwithbgLogo.png")`,
            backgroundSize: "100% 100%",
          }}
        >
          <img src="/assets/img/bannerWithBgLogo.png" alt="" />
          {/* <img src='/assets/img/logo.png' alt=''/> */}
        </div>
        <div className="px-5 col-md-4 col-12">
          <h6 className="fs-3 py-2">Sign in</h6>
          <div className="py-3">
            <p className="fs-6 py-1">Don't have an account?</p>
            <p className="fs-6 py-1">
              <Link
                to={`/create-account${
                  params.get("retrunurl")
                    ? `?retrunurl=${params.get("retrunurl")}`
                    : ""
                }`}
                style={{
                  textDecoration: "none",
                  color: "#0d6efd",
                  backgroundClip: "text",
                }}
              >
                Register here!
              </Link>
            </p>
          </div>

          <div className="py-3">
            <div className="mb-3 py-1">
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <input
                disabled={step == 2}
                placeholder="Enter your email address"
                type="email"
                id="email"
                className="form-control"
                name="email"
                onChange={(e) => setData({ ...data, Email: e.target.value })}
              />
              <span className="error-message" style={{ color: "#dc3545" }}>
                {validationMessage.filter((o) => o.key == "Email")[0]?.message}
              </span>
            </div>

            {step == 2 && (
              <div className="mb-3">
                <label className="form-label" htmlFor="OTP">
                  OTP
                </label>
                <input
                  disabled={loading}
                  placeholder="Enter Enter OTP"
                  type="text"
                  id="OTP"
                  className="form-control"
                  name="otp"
                  onChange={(e) => {
                    setData({ ...data, OTP: e.target.value });
                  }}
                  onKeyDown={(event) => {
                    if (event.key != "Backspace" && data?.OTP?.length == 4)
                      event.preventDefault();
                    if (event.key != "Backspace" && !/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <span className="error-message" style={{ color: "#dc3545" }}>
                  {validationMessage.filter((o) => o.key == "OTP")[0]?.message}
                </span>
              </div>
            )}
          </div>
          {/* <div className='d-flex justify-content-between py-3'>
                    <Form.Check
                        type='checkbox'
                        id='remeberMe'
                        label='Remember Me'
                    />
                    <p><a href='#!'>Forgot Password</a></p>
                </div> */}
          <Button
            disabled={
              loading ||
              (step == 1 && !data?.Email) ||
              (step == 2 && !data?.OTP)
            }
            className="w-100 my-3 py-2"
            style={{
              background: "linear-gradient(90deg, #FF9C00 0%, #FFD255 100%)",
              borderRadius: "40px",
              border: "none",
            }}
            onClick={onLogin}
          >
            {loading
              ? "Loading..."
              : step == 1
              ? "Continue Login"
              : "Sign - in"}
          </Button>
          {/* <p className='fs-6 text-center'>or continue with</p>
                <div className='d-flex justify-content-center gap-3 py-3'>
                    <img src='/assets/img/signin/facebook.png' alt='' />
                    <img src='/assets/img/signin/apple.png' alt='' />
                    <img src='/assets/img/signin/google.png' alt='' />
                </div> */}
        </div>
      </div>
    )
  );
};

export default SignInComponent;
