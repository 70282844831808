import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useLocation  } from "react-router-dom";

const MobileHeaderBackOnly = () => {
  const navigate = useNavigate();
  const location = useLocation();


  //const HISTORYSTATE = window.history.state;

  return (
    <div
      className="MobileNav--Back d-flex align-items-center gap-3 px-2 py-3"
      style={{
        background: "#73589b",
        color: "#FFF",
        position: "sticky",
        top: 0,
        zIndex: "1000",
      }}
    >
      {location.key !== "default" ? (
        <>
          <button
            onClick={() => {
              navigate(-1);
            }}
            style={{
              border: "none",
              background: "transparent",
              color: "#FFF",
            }}
          >
            <KeyboardBackspaceIcon />
          </button>
          <p style={{ fontWeight: "500" }}>Back</p>
        </>
      ) : (
        <p style={{ fontWeight: "500", marginLeft: "20px" }}>Program</p>
      )}
    </div>
  );
};

export default MobileHeaderBackOnly;
