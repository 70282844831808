import React, { useState } from "react";
import "../../styles/courses/Faq.css";
import { cross, plus } from "./assets";

const data = [
  {
    question: "How can United We Care help improve my mental wellness?",
    answer:
      "At United We Care, we strive to make the world a happier place by providing life-changing support and interventions in the mental health care realm. Through our AI-driven, deep-tech platform, we offer safe, secure and inclusive professional guidance right from the comfort of your home. Our array of mainstream and alternative therapies is designed to bring positive change to thousands of lives globally, empowering you to overcome emotional challenges and enhance your mental wellness.",
  },
  {
    question: "Are your services provided by licensed professionals?",
    answer:
      "Yes, all our services are provided by licensed professionals proficient in diverse domains. Our team of qualified mental health professionals ensure that you receive the highest standard of care and support in your mental health journey.",
  },
  {
    question: "What types of mental health conditions do you address?",
    answer:
      "At United We Care, we address a wide range of mental health conditions, including but not limited to anxiety, depression, stress, relationship issues and trauma. We look at mental healthcare from the lens of holistic wellness therefore we also provide services like Yoga, Meditation, Mindfulness and alternative therapies which can be highly effective in addressing behavioural or stress-related conditions. Our team of licensed professionals is equipped to provide personalized support tailored to your specific needs. We also offer a range of self-help resources and tools, ranging from informative articles and guided exercises to self-assessment quizzes.",
  },
  {
    question:
      "How can I know if my mental health condition is improving after enrolling? Would there be someone to track my progress?",
    answer:
      "We are committed to helping you in your healing journey and tracking progress becomes extremely important for both you as well as us in this journey. While you can track the change in your emotions through our daily mood tracker we also provide before and after assessments for mental health conditions which would help you understand how you feel and also if there has been an improvement in your mental health state during the course of the program. Through feedback, both during and after the program, we understand if we are on the right track as well and accordingly, update our content to serve you better. While these programs are self-paced and aimed at kickstarting your healing journey, the additional support of a therapist is strongly recommended as it can aide you even further in speeding up your recovery.",
  },
  {
    question: "Is my personal information kept confidential and secure?",
    answer:
      "Absolutely! Your privacy and security are of utmost importance to us. We adhere to strict confidentiality protocols, and your personal information is kept safe and secure in accordance with our privacy policy.",
  },
  {
    question:
      "Is there a mobile app available to access your services on the go??",
    answer:
      "Yes. The United We Care app is open to all and is available as 'United We Care' on both Google Playstore and Apple Store. For a seamless, more personalized experience we highly recommend using our App.",
  },
  {
    question: "What types of assessments can I expect from your programs?",
    answer:
      "Our mental wellness programs employ a mix of scientifically validated self-assessment tools and quizzes. These are seamlessly integrated into the modules and delve into various aspects such as emotional well-being, coping mechanisms and self-awareness. You can conveniently complete the assessments and quizzes at your own pace, contributing to a personalized and effective wellness journey.",
  },
];

const Faq = () => {
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <section className="faq-section">
      <div className="faq-container">
        <div className="faq-heading">FAQs</div>
        {data.map((item, i) => (
          <div className="item">
            <div className="faq-title" onClick={() => toggle(i)}>
              <h2 className="faq-question">{item.question}</h2>
              <span className="faq-btn">
                {selected === i ? (
                  <img src={cross} className="faq-icon" />
                ) : (
                  <img src={plus} className="faq-icon" />
                )}
              </span>
            </div>
            <div className={selected === i ? "faq-answer show" : "faq-answer"}>
              {item.answer}
            </div>
            <div className="item-border"></div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Faq;
