import React, { useContext } from "react";

import {
  badge,
  star,
  wp_btn,
  btn,
} from "./assets";
import "../../styles/courses/Hero.css";
import { useState } from "react";
import Iframe from "react-iframe";
import { useAuth } from "../../auth";
import { AppContext } from "../../context";
import { OODOCoursePaymentService } from "../core/_request";
import { Link, useNavigate, useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import SubscriptionAlertDialougBox from "./SubscriptionAlertDialougBox";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Hero = (props) => {
  const { courseData, isPaid } = props;
  const [readmore, setReadmore] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openPaymetAlert, setOpenPaymetAlert] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  function readmoreHandler() {
    setReadmore(!readmore);
  }
  const { name, id } = useParams();
  const info = courseData?.course_description;

  const description = readmore ? info : `${info?.substring(0, 200)} `;
  //console.log("name, id ", name, id);
  const onCLickCopy = async () => {
    try {
      await navigator?.clipboard?.writeText(props?.courseData?.course_url);
      // setResponseMessage(true);
      //alert("Link Copied!");
      setOpen(true);
      // setShow(true);
    } catch (err) {
      console.log(err);
    }
  };

  const onClickWhatsApp = () => {
    // window.open(encodeURI(`whatsapp://send?text=${props?.courseData?.course_url}`));
    window.open(
      encodeURI(
        `whatsapp://send?text=${props?.courseData?.course_url.replace(
          "us.unitedwecare.com",
          "my.unitedwecare.com"
        )}`
      )
    );

    // window.open(encodeURI(`whatsapp://send?text=${window.location.origin}/program/${props?.courseData?.course_title}/${props?.courseData?.course_id}`))
  };

  // Function to call when attempting to open WhatsApp
  const sendMessageToReactNative = (message) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(message);
    }
  };

  const onClickWhatsAppOnWeb = () => {
    const whatsappURL = encodeURI(
      `https://wa.me?text=${props?.courseData?.course_url}`
    );

    // const a = document.createElement("a");
    // a.href = whatsappURL;
    // a.target = "_blank";
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);

    sendMessageToReactNative(
      JSON.stringify({ action: "openWhatsApp", url: whatsappURL })
    );
  };

  const onClickWhatsAppOnMobileWeb = () => {
    const whatsappURL = encodeURI(
      `whatsapp://send?text=${props?.courseData?.course_url}`
    );
    // const a = document.createElement("a");
    // a.href = whatsappURL;
    // a.target = "_blank";
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);

    sendMessageToReactNative(
      JSON.stringify({ action: "openWhatsApp", url: whatsappURL })
    );
  };

  const handleStartSubscription = () => {
    setIsButtonClicked(true);
    if (!currentUser) {
      //window.location.href=`/login?retrunurl=/detail/${id}`;
      // navigate(`/login?retrunurl=/course/course-order-preview/${id}`);
      localStorage.setItem(
        "retrunurl",
        `/course-new/${props?.courseData?.course_title}/${props?.courseId}`
      );
      alert("Something went Wrong! Please try to close and re-open app.");
      setIsButtonClicked(false);
    } else {
      if (courseData?.enrolled) {
        navigate(
          `/course-internal/${courseData?.course_data_id}/${courseData?.course_title}`
        );
        let linksData = [
          {
            name: "Home",
            uri: `/course-internal/${courseData?.course_data_id}/${courseData?.course_title}`,
          },
          { name: "My Journal", uri: `/course-internal/module/my-journal/:id` },
        ];
        sessionStorage.setItem(
          "courseInternalLinks",
          JSON.stringify(linksData)
        );
        setIsButtonClicked(false);
      } else if (
        courseData?.inr_price === 0.0 ||
        courseData?.course_type === "free"
      ) {
        let request = {
          userId: currentUser?.UserId,
          courseId: props?.courseId,
        };
        OODOCoursePaymentService(request).then((res) => {
          console.log("RES", res);
          navigate(
            `/course-internal/${res?.data?.data?.id}/${courseData?.course_title}`
          );
          let linksData = [
            {
              name: "Home",
              uri: `/course-internal/${res?.data?.data?.id}/${courseData?.course_title}`,
            },
            {
              name: "My Journal",
              uri: `/course-internal/module/my-journal/:id`,
            },
          ];
          sessionStorage.setItem(
            "courseInternalLinks",
            JSON.stringify(linksData)
          );
        });
        setIsButtonClicked(false);
      } else if (courseData?.course_type === "premium") {
        // navigate("/plans");
        setOpenPaymetAlert(true);
      }
    }
  };

  return (
    <section className="course--hero">
      {courseData?.features && (
        <div className="hero-whiteLayout">
          {courseData?.features?.map((item) => (
            <div className="hero-layout-items">
              <img src={item?.icon} alt="" loading="lazy" />
              <span className="hero-layout-text anim1">{item?.name}</span>
            </div>
          ))}
        </div>
      )}

      <div className="course--hero__left">
        <div className="hero-side-buttons">
          <div className="hero-side-buttons__container">
            <div
              className="hero-wp-btn d-none d-md-block"
              onClick={onClickWhatsAppOnWeb}
            >
              <img src={wp_btn} alt="" loading="lazy" />
            </div>
            <div
              className="hero-wp-btn d-block d-md-none"
              onClick={onClickWhatsAppOnMobileWeb}
            >
              <img src={wp_btn} alt="" loading="lazy" />
            </div>
            <div className="hero-wp-btn" onClick={onCLickCopy}>
              <img src={btn} alt="" loading="lazy" />
            </div>
          </div>
        </div>
        <div className="course--hero__content">
          <div className="hero-blueHeading">
            Program {">"} {courseData?.club}
          </div>
          <h1 className="hero-heading">{courseData?.course_title}</h1>
          <div className="d-flex align-items-center gap-2">
            <div className="hero-selfPaced">{courseData?.course_duration}</div>
            <div
              className="d-flex d-md-none align-items-center justify-content-center"
              style={{
                border: "1px solid #D0D5DD",
                height: "35px",
                width: "35px",
                cursor: "pointer",
                background: "#FFF",
                borderRadius: "8px",
              }}
            >
              <button
                className=""
                style={{
                  cursor: "pointer",
                  border: "none",
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px",
                  background: "#FFF",
                }}
                onClick={onClickWhatsAppOnMobileWeb}
              >
                <img
                  src="/assets/img/whatsapp.png"
                  alt=""
                  style={{ height: "25px", width: "25px" }}
                />
              </button>
            </div>

            <button
              onClick={onCLickCopy}
              className="d-flex d-md-none  align-items-center gap-1 justify-content-center px-2 py-1 text-decoration-none"
              style={{
                border: "1px solid #D0D5DD",
                background: "#FFF",
                borderRadius: "8px",
                height: "35px",
                width: "35px",
              }}
            >
              <img src="/assets/img/copy.png" alt="" />
              <p
                className="font-inter font-w600 fs-6"
                style={{ color: "#344054" }}
              ></p>
            </button>
          </div>

          <div className="hero-info">
            <div className="hero-enrollments">
              <img src={badge} width={31} height={31} alt="" loading="lazy" />
              <span className="hero-infoText">
                {courseData?.people_enrolled} enrollments
              </span>
            </div>
            <div className="hero-rating">
              <img src={star} alt="" loading="lazy" />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "8px",
                }}
              >
                <span className="hero-infoText">
                  {courseData?.course_rating}
                </span>{" "}
                <p className="hero-infoText">Ratings</p>
              </div>
            </div>
          </div>

          <div className="hero-desc">
            {description}
            <span className="hero-read-more" onClick={readmoreHandler}>
              {readmore ? `Show Less` : `Read More`}
            </span>
          </div>

          <button
            className="hero-btn"
            style={{ cursor: "pointer", justifyContent: "center" }}
            onClick={handleStartSubscription}
            disabled={isButtonClicked}
          >
            {isButtonClicked && <CircularProgress color="inherit" />}
            {props.isSubscribed ? "Continue" : isPaid ? "Subscribe" : "Start"}
          </button>
        </div>
      </div>

      <div className="course--hero__video">
        <div className="hero-blueHeading--mobile">
          Program {">"} {courseData?.club}
        </div>
        <Iframe
          url={courseData?.video?.video?.player_embed_url}
          controls={true}
          //light={courseData?.video?.video?.small_thumbnail_url}
          className="video"
          //height={1200}
          //width={900}
          // url='https://embed-ssl.wistia.com/deliveries/67691ab6f4d0a9da7c09dd950ae2961f940c5a85.webp?image_crop_resized=1920x1080'
        ></Iframe>
      </div>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        action={action}
      >
        <Alert severity="success">Link copied successfully!</Alert>
      </Snackbar>

      <SubscriptionAlertDialougBox
        description="Thank you for showing interest in our program! It is currently available exclusively for our Enterprise users. If your employer is already partnered with us, you may have access through your company's plan—please check with your HR department. We'll notify you as soon as it becomes available for individual users like you."
        courseData={courseData}
        userId={currentUser?.UserId}
        isOpen={openPaymetAlert}
        setOpenPaymetAlert={(item) => setOpenPaymetAlert(item)}
        setIsButtonClicked={(item) => setIsButtonClicked(item)}
      />
    </section>
  );
};

export default Hero;
