import React, { useState } from "react";
import { useAuth } from "../../auth";
import { useNavigate } from "react-router";
import { getCurrencyCode } from "../core/commonFun";
import { useContext } from "react";
import { AppContext } from "../../context";

const DashboardDetailCard = (props) => {
  // console.log("props", props)
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { data } = props;
  const [sdata, setSdata] = useState();
  const CurrencyCode = getCurrencyCode();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  // useState(()=>{
  //     OODOGetUserProfileV4Service(currentUser.UserId, data.ExpertId).then(resp=>{
  //         setSdata(resp.data.user);
  //     })
  // },[])

  const onBookNow = (data) => {
    currentUser
      ? navigate(`/book-an-appointment/${data?.Id}`)
      : // navigate(`/login?retrunurl=/book-an-appointment/${data?.Id}`)
        setShowLloginPopup(true);
  };

  return (
    (data && (
      <div
        className="bg-white d-flex flex-column align-items-center justify-content-around px-2 py-3"
        style={{
          borderRadius: "12px",
          boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)",
        }}
      >
        <div className="row my-3 align-items-center justify-content-around">
          <div className="my-3 d-flex align-items-center justify-content-around col-xs-6 col-md-12">
            <img
              src={data?.ProfileImage}
              style={{ height: 200, width: 200, borderRadius: "50%" }}
              alt=""
            />
          </div>
          <div className="d-flex flex-column my-1 col-xs-6 col-md-12">
            <h6 className="text-center">{data?.Name}</h6>
            <p className="text-center fs-6">{data?.RegisterYourselfAs}</p>
            <p className="text-center fs-6">
              Experience : {data?.Experience} years
            </p>
            {/* {
                        (data?.RegisterYourselfAs === "Psychiatrist")
                        &&
                        <> <span className='text-center fs-6'>Starts @ <span className="font-w500 text-black">{CurrencyCode} {data?.countryAmount}</span></span></>
                        ||
                        <span className='text-center fs-6'>Starts @ <span className="font-w500 text-black">{CurrencyCode} {Math.round(data?.countryAmount / 2)}</span></span>
                    } */}
            {/* <span className='text-center fs-6'>Starts @ <span className="font-w500 text-black">{CurrencyCode} {data?.countryAmount}</span></span> */}
          </div>
          {/* {data?.Rating>0 && <div className='d-flex flex-row align-items-center justify-content-center gap-2'>
                    <p>{data?.Rating} </p>
                    <img src={`/assets/united_img/star-${data?.Rating>=1?"full":"half"}.png`} alt='' />
                    <img src={`/assets/united_img/star-${data?.Rating>=2?"full":"half"}.png`} alt='' />
                    <img src={`/assets/united_img/star-${data?.Rating>=3?"full":"half"}.png`} alt='' />
                    <img src={`/assets/united_img/star-${data?.Rating>=4?"full":"half"}.png`} alt='' />
                    <img src={`/assets/united_img/star-${data?.Rating>=5?"full":"half"}.png`} alt='' />
                </div>} */}
        </div>
        <hr className="w-100" />
        {sdata && (
          <div className="d-flex flex-row justify-content-between w-100">
            <p className="fs-6">Followers {sdata?.followersCount}</p>
            <p className="fs-6">Following {sdata?.followingCount}</p>
          </div>
        )}
        {/* <hr className="w-100" />
            <div className='d-flex flex-row justify-content-evenly w-100 my-4'>
                <button type="button" className="btn btn-outline-secondary col-5">Follow</button>
                <button type="button" className="btn btn-outline-secondary col-5">Chat</button>
            </div> */}

        <button
          onClick={() => onBookNow(data)}
          className="px-5 border-0"
          style={{
            background: "linear-gradient(90deg, #FF9C00 0%, #FFD255 100%)",
            borderRadius: "40px",
            height: "48px",
            color: "#fff",
          }}
        >
          Book Now
          {/* @ ₹ {data.countryAmount} */}
        </button>
      </div>
    )) || <div>Loading...</div>
  );
};

export default DashboardDetailCard;
