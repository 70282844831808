import React from 'react'
import "./SignIn.css"
import TextField from '@mui/material/TextField';
import { Button } from 'react-bootstrap'

const SignIn2 = () => {
    return (
        <div className='kareify--SignIn d-flex flex-column align-items-center align-items-md-end justify-content-md-end justify-content-center'>
            <div className='SignIn--box col-12 col-md-4 col-xxl-3 d-flex flex-column align-items-center gap-4'>
                <h6>
                    Welcome to <span>United We Care</span>
                </h6>
                <h3>
                    Log In
                </h3>

                <div className='d-flex align-items-center justify-content-around w-100'>
                    <img src='/assets/img/kareify/Google.png' alt='' />
                    <img src='/assets/img/kareify/Apple.png' alt='' />
                    <img src='/assets/img/kareify/Twitter.png' alt='' />
                    <img src='/assets/img/kareify/linkedin.png' alt='' />
                </div>

                <div className='d-flex align-items-center w-100 gap-2 px-5 horizontal--line'>
                    <hr />
                    <p>Or</p>
                    <hr />
                </div>

                <p>
                    Log in with your Email Address
                </p>

                <TextField id="outlined-basic" label="Email Address *" variant="outlined" className='w-75' />

                <Button className='border-0'>
                    Continue
                </Button>

                <p>
                    Don’t have an account? <span className='diff-clr'>Create Account</span>
                </p>
            </div>
            <h2 className='col-12 col-md-4 col-xxl-3'>
                “You are worthy of happiness and peace of mind.”
            </h2>
        </div>
    )
}

export default SignIn2