import React, { useEffect, useRef, useState } from "react";
import CourseCard from "./CourseCard";
import { arrow_left, arrow_right, course_card } from "./assets";
import { useNavigate } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { OODOCoursePaymentService } from "../core/_request";
import { useAuth } from "../../auth";
import Rating from "@mui/material/Rating";
import { results_star } from "./assets";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";

// Swiper.use([Pagination, Navigation]);

const RelatedCourses = (props) => {
  const { courseData } = props;
  const len = courseData?.related_courses?.length;
  const navigate = useNavigate();
  const { currentUser } = useAuth();

 // console.log("courseData?.related_courses", courseData?.related_courses);
  return (
    <section className="related-course-section">
      <div className="related-course-container">
        <div className="related-course-heading">Related Program</div>
        <div className="related-course-desc">
          Connect with our community and stay inspired on your mental wellness
          journey.
        </div>

        {/* <div className="related-course-cards">
          <Swiper
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              1024: {
                slidesPerView: len < 3 ? len : 3,
              },
            }}
            spaceBetween={30}
            navigation={len < 3 ? false : true}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
          >
            <div className="related-course-card">
              {courseData?.related_courses?.map((item, i) => (
                <SwiperSlide key={i}>
                  <CourseCard
                    item={item}
                    courseDataURL={courseData?.course_url}
                  />
                </SwiperSlide>
              ))}
            </div>
            
          </Swiper>
        </div> */}
        <div className="swiper--container">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={0}
            navigation={len < 2 ? false : true}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper relatedCourse--Swiper"
          >
            {courseData?.related_courses?.map((item, i) => (
              <SwiperSlide className="relatedCourse--SwiperItem">
                <div
                  className="SwiperItem--Card"
                  onClick={() => {
                    if (item.enrolled) {
                      const request = {
                        userId: currentUser?.UserId,
                        courseId: item?.id,
                      };
                      OODOCoursePaymentService(request).then((res) => {
                        // console.log("res", res?.data?.data?.id);
                        navigate(
                          `/course-internal/${res?.data?.data?.id}/${item?.title}`
                        );
                      });
                    } else {
                      navigate(`/course-new/${item?.title}/${item?.id}`);
                    }
                  }}
                >
                  <div className="relative SwiperItem--Card__Image">
                    <img src={item?.image} alt="" />
                    {item?.enrolled && (
                      <button className="d-flex align-items-center gap-1 mx-auto justify-content-center">
                        <span>Continue</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_6820_21780)">
                            <path
                              d="M16.51 11H5.5C4.95 11 4.5 11.45 4.5 12C4.5 12.55 4.95 13 5.5 13H16.51V14.79C16.51 15.24 17.05 15.46 17.36 15.14L20.14 12.35C20.33 12.15 20.33 11.84 20.14 11.64L17.36 8.85002C17.05 8.53002 16.51 8.76002 16.51 9.20002V11Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6820_21780">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="translate(0.5)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    )}
                  </div>

                  <div className="course-card-title">
                    <span>{item?.club}</span>
                    <p>{item?.title}</p>
                    <div className="d-flex align-items-center gap-1">
                      <p>{item?.rating}</p>
                      <Rating name="read-only" value={item?.rating} readOnly />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default RelatedCourses;
