import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import "../styles/CompleteQuiz.css";
import {
  module_bg_img,
  quiz_complete,
  bookmark,
  left_arrow,
  arrow,
  green_tick,
  info_icon,
  home_wp,
  home_copy,
} from "../assets";
import { courseInternalContentView } from "../_request";
import Popup from "./Modal";
import SidebarMobile from "./SidebarMobile";
import { useAuth } from "../../../auth";
import { useNavigate, useParams } from "react-router-dom";
import { addBookmarkEntry } from "../_request";

import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Helmet } from "react-helmet";

const modal_data = [
  {
    title: "Quiz Time",
    desc: "Answer these questions to understand how much of the information disseminated within the program stuck with you.",
  },
];

const CompleteQuiz = () => {
  const [quizData, setQuizData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { currentUser } = useAuth();
  const { id } = useParams();
  const [subLevelId, setSubLevelId] = useState(id);
  const navigate = useNavigate();

  useEffect(() => {
    setSubLevelId(id);
  }, [id]);

  useEffect(() => {
    let body = {
      userId: currentUser?.UserId,
      subLevelId: id,
    };
    courseInternalContentView(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setQuizData(resp?.data);
        setIsBookmarked(resp?.data?.bookmarked);
        setIsLoading(false);
      } else {
        console.log(resp?.ResponseMessage);
      }
    });
  }, [subLevelId, id]);

  const handlePrevButton = () => {
    if (quizData?.previous_id?.type === "video") {
      navigate(`/course-internal/module/${quizData?.previous_id?.id}`);
    } else if (quizData?.previous_id?.type === "audio") {
      navigate(`/course-internal/module/${quizData?.previous_id?.id}`);
    } else if (quizData?.previous_id?.type === "checksheet") {
      navigate(
        `/course-internal/module/daily-practice/${quizData?.previous_id?.id}`
      );
    } else if (quizData?.previous_id?.type === "journal") {
      navigate(
        `/course-internal/module/my-journal/${quizData?.previous_id?.id}`
      );
    } else if (quizData?.previous_id?.type === "quiz") {
      navigate(`/course-internal/module/quiz/${quizData?.previous_id?.id}`);
      setSubLevelId(quizData?.previous_id?.id);
    } else if (quizData?.previous_id?.type === "html") {
      navigate(`/course-internal/module/article/${quizData?.previous_id?.id}`);
    } else if (quizData?.previous_id?.type === "stella_assessment") {
      navigate(
        `/course-internal/talk-to-stella?assessmentID=${quizData?.previous_id?.id}`
      );
    } else if (quizData?.previous_id?.type === "mood_monitor") {
      navigate(
        `/course-internal/module/mood-monitor?id=${quizData?.previous_id?.id}`
      );
    }
  };

  const handleNextButton = () => {
    if (quizData?.next_id?.type === "video") {
      navigate(`/course-internal/module/${quizData?.next_id?.id}`);
    } else if (quizData?.next_id?.type === "audio") {
      navigate(`/course-internal/module/${quizData?.next_id?.id}`);
    } else if (quizData?.next_id?.type === "checksheet") {
      navigate(
        `/course-internal/module/daily-practice/${quizData?.next_id?.id}`
      );
    } else if (quizData?.next_id?.type === "journal") {
      navigate(`/course-internal/module/my-journal/${quizData?.next_id?.id}`);
    } else if (quizData?.next_id?.type === "quiz") {
      navigate(`/course-internal/module/quiz/${quizData?.next_id?.id}`);
      setSubLevelId(quizData?.next_id?.id);
    } else if (quizData?.next_id?.type === "html") {
      navigate(`/course-internal/module/article/${quizData?.next_id?.id}`);
    } else if (quizData?.next_id?.type === "stella_assessment") {
      navigate(
        `/course-internal/talk-to-stella?assessmentID=${quizData?.next_id?.id}`
      );
    } else if (quizData?.next_id?.type === "mood_monitor") {
      navigate(
        `/course-internal/module/mood-monitor?id=${quizData?.next_id?.id}`
      );
    }
  };

  const handlePrevModuleButton = () => {
    navigate(
      `/course-internal/course-view/${quizData?.previous_module_id?.id}`
    );
  };

  const handleNextModuleButton = () => {
    navigate(`/course-internal/course-view/${quizData?.next_module_id?.id}`);
  };

  const handleBookmarkClick = () => {
    var bBody = { sub_level_id: subLevelId };
    addBookmarkEntry(bBody).then((resp) => {
      if (resp?.ResponseCode === 200) {
        // setBookmarkDataStatus(resp?.data);
        //console.log(resp?.data?.bookmarked)
        setIsBookmarked(resp?.data?.bookmarked);
        // console.log("resp?.dataresp?.data", resp?.data);
      } else {
        //console.log(resp?.ResponseMessage);
      }
    });
  };
  return (
    <section className="complete-quiz">
      <Helmet>
        <title>Program | Quiz</title>
      </Helmet>
      {isLoading ? (
        <div
          className="d-flex align-items-center justify-content-center mx-auto"
          style={{ minHeight: "100vh" }}
        >
          <img alt="" src="/assets/img/kareify/loader.gif" />
        </div>
      ) : (
        <>
          <section className="complete-quiz-sidebar">
            <Sidebar />
          </section>
          <SidebarMobile />
          <section className="complete-quiz-section">
            <div className="quiz-bg-img">
              <img src={module_bg_img} alt="" loading="lazy" />
            </div>
            <div className="complete-quiz-container">
              {/* <div className="home-upper-section">
            <div className="home-heading">
              <p className="home-heading1">
                COURSE • SELF CARE • {quizData?.preview_video?.duration} WEEKS
              </p>
            </div>
            <div className="home-sub-heading">
              <p className="home-heading2">{quizData?.title}</p>
              <div className="home-btns">
                <img src={home_wp} alt="" loading="lazy" />
                <img src={home_copy} alt="" loading="lazy" />
              </div>
            </div>
          </div> */}
              <div className="sections-heading">
                {quizData?.course_name}
                <span>
                  {" "}
                  {" >"} {quizData?.content?.name}{" "}
                </span>
              </div>
              <div className="sections-progress">
                Section {quizData?.current_level_index + 1}/
                {quizData?.level_count}
              </div>
              <div className="quiz-sections-name">
                <div
                  style={{ display: "flex", gap: "1rem", lineHeight: "150%" }}
                >
                  {quizData?.content?.name}
                  <img
                    src={info_icon}
                    alt=""
                    loading="lazy"
                    className="quiz-info-icon"
                    onClick={() => setShowModal(true)}
                  />
                  <Popup
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    data={modal_data[0]}
                  />
                </div>

                <div
                  className="quiz-save"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleBookmarkClick()}
                >
                  {isBookmarked ? (
                    <BookmarkIcon color="#541cf5" />
                  ) : (
                    <BookmarkBorderOutlinedIcon />
                  )}
                </div>
              </div>
              <hr style={{ color: "#E0E0E0" }} />

              <div>
                <img
                  src={quiz_complete}
                  alt=""
                  loading="lazy"
                  className="complete-quiz-img"
                />
              </div>
              <div className="complete-quiz-section-desc">
                You have completed this quiz! Here’s a summary of what we
                covered...
              </div>
              <div className="complete-quiz-question-container">
                {quizData?.content?.quiz_summary?.map((item) => (
                  <div className="complete-items">
                    <img
                      src={green_tick}
                      alt=""
                      loading="lazy"
                      className="green-tick"
                    />
                    <div className="complete-items-text">{item}</div>
                  </div>
                ))}
                {/* <div className="complete-items">
              <img
                src={green_tick}
                alt=""
                loading="lazy"
                className="green-tick"
              />
              <div className="complete-items-text">
                Activation comes before motivation!
              </div>
            </div>
            <div className="complete-items">
              <img
                src={green_tick}
                alt=""
                loading="lazy"
                className="green-tick"
              />
              <div className="complete-items-text">
                Rewarding, rather than punishing, yourself is more helpful in
                overcoming depression.
              </div>
            </div>
            <div className="complete-items">
              <img
                src={green_tick}
                alt=""
                loading="lazy"
                className="green-tick"
              />
              <div className="complete-items-text">
                Talking is important as is making changes in your daily life as
                well.
              </div>
            </div>
            <div className="complete-items">
              <img
                src={green_tick}
                alt=""
                loading="lazy"
                className="green-tick"
              />
              <div className="complete-items-text">
                Achieving even tiny goals will boost your sense of achievement
                and your mood.
              </div>
            </div> */}
              </div>

              <div className="complete-quiz-btn justify-content-between">
                <div>
                  {quizData?.previous_id.type && (
                    <div className="prev-btn" onClick={handlePrevButton}>
                      <div className="modules-prev-text">Previous</div>
                      <div className="prev-arrow">
                        <img
                          src={left_arrow}
                          alt=""
                          loading="lazy"
                          className="arrow-img"
                        />
                      </div>
                    </div>
                  )}
                  {quizData?.previous_module_id?.id &&
                    !quizData?.previous_id?.type && (
                      <div
                        className="prev-btn"
                        onClick={handlePrevModuleButton}
                      >
                        <div className="modules-text">Previous Module</div>
                        <div className="prev-arrow">
                          <img
                            src={left_arrow}
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                          />
                        </div>
                      </div>
                    )}
                </div>

                <div>
                  {quizData?.next_id.type && (
                    <div className="next-btn" onClick={handleNextButton}>
                      <div className="modules-text">Next</div>
                      <div className="next-arrow">
                        <img
                          src={arrow}
                          alt=""
                          loading="lazy"
                          className="arrow-img"
                        />
                      </div>
                    </div>
                  )}
                  {quizData?.next_module_id?.id && !quizData?.next_id?.type && (
                    <div className="next-btn" onClick={handleNextModuleButton}>
                      <div className="modules-text">Next Module</div>
                      <div className="next-arrow">
                        <img
                          src={arrow}
                          alt=""
                          loading="lazy"
                          className="arrow-img"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </section>
  );
};

export default CompleteQuiz;
