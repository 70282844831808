import { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper';

//Icons
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

//Card
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useAuth } from '../../auth';
import { useContext } from 'react';
import { AppContext } from '../../context';
import { useNavigate } from 'react-router-dom';
import { getCountryCode } from '../core/commonFun';

const courses = [
    { name: "Abuse", color: '#C11574', background: '#FFEAF8' },
    { name: "Diet and Nutrition", color: '#FD6F1F', background: '#FFEFE5' },
    { name: "Emotional Wellness", color: '#254336', background: '#D6FCEB' },
    { name: "Fitness", color: '#3538CD', background: '#EEF4FF' },
    { name: "Focus", color: '#6941C6', background: '#F0E6FE' },
    { name: "LGBTQIA+", color: '#C11574', background: '#FFEAF8' },
    { name: "Meditation", color: '#C11574', background: '#FFEAF8' },
    { name: "Addiction", color: '#027A48', background: '#ECFDF3' },
    { name: "Parenting", color: '#D15B17', background: '#FFE8DB' },
    { name: "Phobia", color: '#7636D1', background: '#F3ECFD' },
    { name: "Relationship", color: '#8C6800', background: '#FFF5D7' },
    { name: "Self Help", color: '#C31D28', background: '#FFDFE1' },
    { name: "Stress", color: '#A2A81F', background: '#FEFFE4' },
    { name: "Yoga & Meditation", color: '#FF4343', background: '#FDECEC' },
    { name: "Work Related", color: '#DE22E2', background: '#FEDBFF' },
]

const psycologistRange = [
    { name: "Psychologists", color: '#C11574', background: '#FFEAF8' },
    { name: "Psychiatrists", color: '#541CF5', background: '#F1ECFF' },
    { name: "Coaches", color: '#156EC1', background: '#D9EDFF' },
    { name: "Wellness Experts", color: '#0BA91A', background: '#EFFFF1' },
]

const WhyPlan = ({ type }) => {
    const [pageValue, setPageValue] = useState({})
    const { currentUser } = useAuth();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    const navigate = useNavigate();
    const [isCountryIndia, setIsCountryIndia] = useState();

    var countryCode = getCountryCode();
    console.log("countryCode", countryCode)
    useEffect(() => {
        if (countryCode === "IN") {
            setIsCountryIndia(true)
        } else {
            setIsCountryIndia(false)
        }
    }, [])

    useEffect(() => {
        if (type == "Platinum") {
            setPageValue({
                type,

            })
        } else {
            setPageValue({
                type,

            })
        }
    }, [])

    const onClickSubscribe = (item) => {
        sessionStorage.setItem("subscriptionID", item);
        if (!currentUser) {
            //window.location.href=`/login?retrunurl=/detail/${id}`;
            // navigate(`/login?retrunurl=/course/course-order-preview/${id}`);
            localStorage.setItem("retrunurl", `/subscription-order-summary`)
            setShowLloginPopup(true);
        }
        else {
            navigate(`/subscription-order-summary`)
        }
    }

    const onClickExploreCourses = () => {
        navigate('/course')
    }

    const onTalkToStella = () => {
        navigate('/stella')
    }

    const onClickCopyURL = () => {
        navigator?.clipboard?.writeText("https://my.unitedwecare.com/course/details/3");
        alert("Link Copied!");
    }

    const onClickWhatsAppOnWeb = () => {
        window.location.href = `https://web.whatsapp.com/send?text=https://my.unitedwecare.com/course/details/3`
    }

    const onClickWhatsAppOnMobileWeb = () => {
        window.location.href = `whatsapp://send?text=https://my.unitedwecare.com/course/details/3`
    }

    return (
        <div className='Carousel--Area'>
            <div className='Carousel--Top'>
                <div>
                    <h4>Why?</h4>
                    <h6>{type} Care</h6>
                </div>
            </div>

            <div className='Swiper--Area'>
                <img src='/assets/img/planDetails/WhyCarouselBG.png' />
                <Swiper
                    slidesPerView={1}
                    centeredSlides={true}
                    spaceBetween={20}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    effect="coverflow"
                    className="mySwiper"
                >
                    {
                        type == "Premium" && (
                            <>
                                <SwiperSlide>
                                    {/* <img src='/assets/img/planDetails/WhyCarouselBG.png' /> */}
                                    <div className='swiper--content'>
                                        <div className='Img--Container'>
                                            <img src='/assets/img/planDetails/StellaFace2.png' />
                                        </div>

                                        <div className='Desc--Area Platinum'>
                                            <h6>Chat with Stella-anytime, anywhere</h6>
                                            <ul>
                                                <li>
                                                    Imagine having someone available to cater to your mental health and wellness at your fingertips.
                                                </li>

                                                <li>
                                                    Stella is CBT Trained by over <span>80+ clinicians.</span>
                                                </li>

                                                <li>
                                                    Real-time interactions.
                                                </li>

                                                <li>
                                                    Voice-Text & Text-Voice Features.
                                                </li>

                                                <li>
                                                    Understand Face and Lip Movements.
                                                </li>
                                            </ul>


                                            <Button onClick={() => onTalkToStella()}>Talk to Stella</Button>
                                        </div>

                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    {/* <img src='/assets/img/planDetails/WhyCarouselBG.png' /> */}
                                    <div className='swiper--content'>
                                        <div className='Card--Container'>
                                            <Card className='card' sx={{ maxWidth: "100%", padding: '15px' }}>
                                                <CardMedia
                                                    component="img"
                                                    alt="green iguana"
                                                    height="140"
                                                    image="/assets/img/planDetails/card-preview-1.png"
                                                    sx={{ borderRadius: '7px' }}
                                                />
                                                <CardContent sx={{ textAlign: 'left' }}>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        Get Started with Mindfulness
                                                    </Typography>
                                                    <div className='d-flex align-items-center  gap-2 card--options'>
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            <AccessTimeFilledIcon />
                                                            Self- Paced
                                                        </span>

                                                        <Button onClick={onClickCopyURL}>
                                                            <ContentCopyOutlinedIcon />
                                                            Copy link
                                                        </Button>

                                                        <Button className='whatsapp--btn d-none d-md-block' onClick={onClickWhatsAppOnWeb}>
                                                            <img src="/assets/img/planDetails/WP.png" />
                                                        </Button>

                                                        <Button className='whatsapp--btn d-block d-md-none' onClick={onClickWhatsAppOnMobileWeb}>
                                                            <img src="/assets/img/planDetails/WP.png" />
                                                        </Button>
                                                    </div>

                                                </CardContent>
                                                <CardActions>
                                                    <Button size="small" onClick={() => {isCountryIndia ? onClickSubscribe(7) : onClickSubscribe(9)}}>
                                                        <LockOutlinedIcon />
                                                        Subscribe to Enroll
                                                    </Button>
                                                </CardActions>
                                            </Card>

                                        </div>

                                        <div className='Desc--Area'>
                                            <h6>Premium Courses</h6>
                                            <ul>
                                                <li>In a world where clinically proven mental health programs are a rarity, our expert-designed courses are akin to therapeutic solutions for your well-being.</li>

                                                <li>
                                                    Catering to every possible concern and issues one might face, we have an array of programs:
                                                </li>
                                            </ul>
                                            <div className='Courses--List'>
                                                {
                                                    courses?.map((item, i) => {
                                                        return (
                                                            <span key={i} style={{ color: item.color, background: item.background }}>{item.name}</span>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <Button onClick={() => onClickExploreCourses()}>Explore Courses</Button>
                                        </div>

                                    </div>
                                </SwiperSlide>

                            </>
                        ) || type == "Platinum" && (
                            <>
                                <SwiperSlide>
                                    {/* <img src='/assets/img/planDetails/WhyCarouselBG.png' /> */}
                                    <div className='swiper--content'>
                                        <div className='Img--Container'>
                                            <img src='/assets/img/planDetails/KirtiBajpai.png' />
                                        </div>

                                        <div className='Desc--Area Platinum'>
                                            <h6>Personalised Sessions <br /><span>UNLIMITED</span></h6>

                                            <ul>
                                                <li>
                                                    Imagine having unlimited monthly, one-on-one sessions with top, highly qualified professionals.
                                                </li>

                                                <li>
                                                    Our professionals go through a rigorous screening process. We assure the best care for you.
                                                </li>

                                                <li>
                                                    Every expert you will find here will have a minimum of 3 years of professional experience in their respective fields.
                                                </li>

                                                <li>
                                                    These experts range from
                                                </li>
                                            </ul>
                                            <div className='Psycologist--List'>
                                                {
                                                    psycologistRange?.map((item, i) => {
                                                        return (
                                                            <span key={i} style={{ color: item.color, background: item.background }}>{item.name}</span>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <Button onClick={() => navigate('/?Profession=Psychologist', { replace: true })}>Talk to Professional</Button>
                                        </div>

                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    {/* <img src='/assets/img/planDetails/WhyCarouselBG.png' /> */}
                                    <div className='swiper--content'>
                                        <div className='Img--Container'>
                                            <img src='/assets/img/planDetails/StellaFace2.png' />
                                        </div>

                                        <div className='Desc--Area Platinum'>
                                            <h6>Chat with Stella-anytime, anywhere</h6>
                                            <ul>
                                                <li>
                                                    Imagine having someone available to cater to your mental health and wellness at your fingertips.
                                                </li>

                                                <li>
                                                    Stella is CBT Trained by over <span>80+ clinicians.</span>
                                                </li>

                                                <li>
                                                    Real-time interactions.
                                                </li>

                                                <li>
                                                    Voice-Text & Text-Voice Features.
                                                </li>

                                                <li>
                                                    Understand Face and Lip Movements.
                                                </li>


                                            </ul>


                                            <Button onClick={() => onTalkToStella()}>Talk to Stella</Button>
                                        </div>

                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    {/* <img src='/assets/img/planDetails/WhyCarouselBG.png' /> */}
                                    <div className='swiper--content'>
                                        <div className='Card--Container'>
                                            <Card className='card' sx={{ maxWidth: "100%", padding: '15px' }}>
                                                <CardMedia
                                                    component="img"
                                                    alt="green iguana"
                                                    height="140"
                                                    image="/assets/img/planDetails/card-preview-1.png"
                                                    sx={{ borderRadius: '7px' }}
                                                />
                                                <CardContent sx={{ textAlign: 'left' }}>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        Get Started with Mindfulness
                                                    </Typography>
                                                    <div className='d-flex align-items-center  gap-2 card--options'>
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            <AccessTimeFilledIcon />
                                                            Self- Paced
                                                        </span>

                                                        <Button onClick={onClickCopyURL}>
                                                            <ContentCopyOutlinedIcon />
                                                            Copy link
                                                        </Button>

                                                        <Button className='whatsapp--btn d-none d-md-block' onClick={onClickWhatsAppOnWeb}>
                                                            <img src="/assets/img/planDetails/WP.png" />
                                                        </Button>

                                                        <Button className='whatsapp--btn d-block d-md-none' onClick={onClickWhatsAppOnMobileWeb}>
                                                            <img src="/assets/img/planDetails/WP.png" />
                                                        </Button>
                                                    </div>

                                                </CardContent>
                                                <CardActions>
                                                    <Button size="small" onClick={() => onClickSubscribe(5)}>
                                                        <LockOutlinedIcon />
                                                        Subscribe to Enroll
                                                    </Button>
                                                </CardActions>
                                            </Card>

                                        </div>

                                        <div className='Desc--Area'>
                                            <h6>Premium Courses</h6>
                                            <ul>
                                                <li>In today's world, where data-driven, clinically proven mental health programs available at your fingertips are a rarity, we offer you courses—designed by our experts—that are like therapies in themselves.</li>

                                                <li>
                                                    Catering to every possible concern and issues one might face, we have an array of programs:
                                                </li>
                                            </ul>
                                            <div className='Courses--List'>
                                                {
                                                    courses?.map((item, i) => {
                                                        return (
                                                            <span key={i} style={{ color: item.color, background: item.background }}>{item.name}</span>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <Button onClick={() => onClickExploreCourses()}>Explore Courses</Button>
                                        </div>

                                    </div>
                                </SwiperSlide>

                            </>
                        )
                        || type == "Super" && (
                            <>
                                <SwiperSlide>
                                    {/* <img src='/assets/img/planDetails/WhyCarouselBG.png' /> */}
                                    <div className='swiper--content'>
                                        <div className='Img--Container'>
                                            <img src='/assets/img/planDetails/KirtiBajpai.png' />
                                        </div>

                                        <div className='Desc--Area Platinum'>
                                            <h6>Personalised Sessions</h6>

                                            <ul>
                                                <li>
                                                    Imagine having 2 sessions monthly, one-on-one sessions with top, highly qualified professionals.
                                                </li>

                                                <li>
                                                    Our professionals go through a rigorous screening process. We assure the best care for you.
                                                </li>

                                                <li>
                                                    Every expert you will find here will have a minimum of 3 years of professional experience in their respective fields.
                                                </li>

                                                <li>
                                                    These experts range from
                                                </li>
                                            </ul>
                                            <div className='Psycologist--List'>
                                                {
                                                    psycologistRange?.map((item, i) => {
                                                        return (
                                                            <span key={i} style={{ color: item.color, background: item.background }}>{item.name}</span>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <Button onClick={() => navigate('/?Profession=Psychologist', { replace: true })}>Talk to Professional</Button>
                                        </div>

                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    {/* <img src='/assets/img/planDetails/WhyCarouselBG.png' /> */}
                                    <div className='swiper--content'>
                                        <div className='Img--Container'>
                                            <img src='/assets/img/planDetails/StellaFace2.png' />
                                        </div>

                                        <div className='Desc--Area Platinum'>
                                            <h6>Chat with Stella-anytime, anywhere</h6>
                                            <ul>
                                                <li>
                                                    Imagine having someone available to cater to your mental health and wellness at your fingertips.
                                                </li>

                                                <li>
                                                    Stella is CBT Trained by over <span>80+ clinicians.</span>
                                                </li>

                                                <li>
                                                    Real-time interactions.
                                                </li>

                                                <li>
                                                    Voice-Text & Text-Voice Features.
                                                </li>

                                                <li>
                                                    Understand Face and Lip Movements.
                                                </li>
                                            </ul>


                                            <Button onClick={() => onTalkToStella()}>Talk to Stella</Button>
                                        </div>

                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    {/* <img src='/assets/img/planDetails/WhyCarouselBG.png' /> */}
                                    <div className='swiper--content'>
                                        <div className='Card--Container'>
                                            <Card className='card' sx={{ maxWidth: "100%", padding: '15px' }}>
                                                <CardMedia
                                                    component="img"
                                                    alt="green iguana"
                                                    height="140"
                                                    image="/assets/img/planDetails/card-preview-1.png"
                                                    sx={{ borderRadius: '7px' }}
                                                />
                                                <CardContent sx={{ textAlign: 'left' }}>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        Get Started with Mindfulness
                                                    </Typography>
                                                    <div className='d-flex align-items-center  gap-2 card--options'>
                                                        <span style={{ whiteSpace: "nowrap" }}>
                                                            <AccessTimeFilledIcon />
                                                            Self- Paced
                                                        </span>

                                                        <Button onClick={onClickCopyURL}>
                                                            <ContentCopyOutlinedIcon />
                                                            Copy link
                                                        </Button>

                                                        <Button className='whatsapp--btn d-none d-md-block' onClick={onClickWhatsAppOnWeb}>
                                                            <img src="/assets/img/planDetails/WP.png" />
                                                        </Button>

                                                        <Button className='whatsapp--btn d-block d-md-none' onClick={onClickWhatsAppOnMobileWeb}>
                                                            <img src="/assets/img/planDetails/WP.png" />
                                                        </Button>
                                                    </div>

                                                </CardContent>
                                                <CardActions>
                                                    <Button size="small" onClick={() => { isCountryIndia ? onClickSubscribe(6) : onClickSubscribe(10)}}>
                                                        <LockOutlinedIcon />
                                                        Subscribe to Enroll
                                                    </Button>
                                                </CardActions>
                                            </Card>

                                        </div>

                                        <div className='Desc--Area'>
                                            <h6>Premium Courses</h6>
                                            <ul>
                                                <li>In today's world, where data-driven, clinically proven mental health programs available at your fingertips are a rarity, we offer you courses—designed by our experts—that are like therapies in themselves.</li>

                                                <li>
                                                    Catering to every possible concern and issues one might face, we have an array of programs:
                                                </li>
                                            </ul>
                                            <div className='Courses--List'>
                                                {
                                                    courses?.map((item, i) => {
                                                        return (
                                                            <span key={i} style={{ color: item.color, background: item.background }}>{item.name}</span>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <Button onClick={() => onClickExploreCourses()}>Explore Courses</Button>
                                        </div>

                                    </div>
                                </SwiperSlide>
                            </>
                        )
                    }




                </Swiper>


            </div>

        </div>
    )
}

export default WhyPlan