import React, { useState } from "react";
import {
  expectSection,
  course_working1,
  course_working2,
  course_working3,
  course_working4,
  results_star,
  google_rating,
  module_1,
  course_tick_purple,
  course_tick_white,
  white_check_mark,
} from "./assets";
import "../../styles/courses/WhatToExpect.css";
import ModuleCard from "./ModuleCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Rating from "react-rating";
// import required modules
import { Pagination, Navigation } from "swiper";
import { AppContext } from "../../context";
import { useAuth } from "../../auth";
import { useNavigate } from "react-router-dom";
import { OODOCoursePaymentService } from "../core/_request";

import CircularProgress from "@mui/material/CircularProgress";
import SubscriptionAlertDialougBox from "./SubscriptionAlertDialougBox";

const cards = [
  {
    image: module_1,
    title: "Developing Your Unique Style and Personality as a Storyteller",
    desc: "Understanding the current situtation of working women with family duties and the impact on their physical and emotional well-being",
  },
  {
    image: module_1,
    title: "Developing Your Unique Style and Personality as a Storyteller",
    desc: "Understanding the current situtation of working women with family duties and the impact on their physical and emotional well-being",
  },
  {
    image: module_1,
    title: "Developing Your Unique Style and Personality as a Storyteller",
    desc: "Understanding the current situtation of working women with family duties and the impact on their physical and emotional well-being",
  },
  {
    image: module_1,
    title: "Developing Your Unique Style and Personality as a Storyteller",
    desc: "Understanding the current situtation of working women with family duties and the impact on their physical and emotional well-being",
  },
];

const course_data = [
  {
    heading: "Dashboard",
    desc: "Track your progress with a personalized dashboard that helps you trace your success.",
    image: course_working1,
  },
  {
    heading: "Structure Learning Path",
    desc: "Choose your own pace to learn with structured learning created by the best of experts.",
    image: course_working2,
  },
  {
    heading: "Track your progress",
    desc: "Keep an eye on your journey with regular tracking within the course.",
    image: course_working3,
  },
  {
    heading: "Community Support",
    desc: "Find a community of people to help you find a healing path even after course completion.",
    image: course_working4,
  },
];

const course_images = [
  course_working1,
  course_working2,
  course_working3,
  course_working4,
];

const WhatToExpect = (props) => {
  const { courseData, isPaid } = props;
  const [selected, setSelected] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [openPaymetAlert, setOpenPaymetAlert] = useState(false);

  const handleCardClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  //console.log("props", props)

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
    // setSelectedImage(course_images[i]);
  };

  const handleStartSubscription = () => {
    setIsButtonClicked(true);
    if (!currentUser) {
      localStorage.setItem(
        "retrunurl",
        `/course-new/${props?.courseData?.course_title}/${props?.courseId}`
      );
      alert("Something went Wrong! Please try to close and re-open app.");
      setIsButtonClicked(false);
    } else {
      if (courseData?.enrolled) {
        navigate(
          `/course-internal/${courseData?.course_data_id}/${courseData?.course_title}`
        );
        let linksData = [
          {
            name: "Home",
            uri: `/course-internal/${courseData?.course_data_id}/${courseData?.course_title}`,
          },
          { name: "My Journal", uri: `/course-internal/module/my-journal/:id` },
        ];
        sessionStorage.setItem(
          "courseInternalLinks",
          JSON.stringify(linksData)
        );
        setIsButtonClicked(false);
      } else if (
        courseData?.inr_price === 0.0 ||
        courseData?.course_type === "free"
      ) {
        let request = {
          userId: currentUser?.UserId,
          courseId: props?.courseId,
        };
        OODOCoursePaymentService(request).then((res) => {
          // console.log("RES", res);
          setIsButtonClicked(false);
          navigate(
            `/course-internal/${res?.data.data.id}/${courseData?.course_title}`
          );
          let linksData = [
            {
              name: "Home",
              uri: `/course-internal/${res?.data?.data?.id}/${courseData?.course_title}`,
            },
            {
              name: "My Journal",
              uri: `/course-internal/module/my-journal/:id`,
            },
          ];
          sessionStorage.setItem(
            "courseInternalLinks",
            JSON.stringify(linksData)
          );
        });
      } else if (courseData?.course_type === "premium") {
        localStorage.setItem(
          "retrunurl",
          `/course-new/${props?.courseData?.course_title}/${props?.courseId}`
        );
        // navigate("/plans");
        setOpenPaymetAlert(true);
      }
    }
  };

  return (
    <section>
      {/* expect section */}
      <div className="expect-header">
        <a href="#discover">Discover</a> <div className="line"></div>
        <a href="#modules">Modules</a> <div className="line"></div>
        <a href="#course">How Program Works</a> <div className="line"></div>
        <a href="#results">Proven Results</a> <div className="line"></div>
      </div>

      {/* discover section */}
      <section id="discover" className="expect-section">
        <div className="expect-main">
          <div className="expect-left-heading">What to Expect</div>
          <div className="expect-left">
            <div className="expect-left-text">
              <p className="expect-left-para">
                {courseData?.course_expectation_description}
              </p>
            </div>
            <div className="expect-left-blueSection d-xl-block d-none">
              <h4 className="expect-blue-heading">
                This course is suitable for you if
              </h4>
              <ul className="expect-blue-list">
                {courseData?.course_expectations &&
                  courseData?.course_expectations?.map((item) => (
                    <li className="expect-blue-list-items">
                      <img
                        src={white_check_mark}
                        alt=""
                        loading="lazy"
                        className="check-list"
                      />
                      {item?.name}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="expect-right">
          <img
            src={expectSection}
            className="expect-right-img"
            alt=""
            loading="lazy"
          />
        </div>

        <div className="expect-left-blueSection d-block d-xl-none">
          <h4 className="expect-blue-heading">
            This course is suitable for you if
          </h4>
          <ul className="expect-blue-list">
            {courseData?.course_expectations &&
              courseData?.course_expectations?.map((item) => (
                <li className="expect-blue-list-items">
                  <img
                    src={white_check_mark}
                    alt=""
                    loading="lazy"
                    className="check-list"
                  />
                  {item?.name}
                </li>
              ))}
          </ul>
        </div>
      </section>

      {/* module section */}
      <section id="modules" className="module-section">
        <div className="module-container">
          <h1 className="module-heading">
            <span className="text-highlight">Modules</span> we will cover in
            this course
          </h1>

          <div className="module-cards">
            <div className="card-slider">
              <Swiper
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  1024: {
                    slidesPerView: 1,
                  },
                }}
                spaceBetween={30}
                loop={true}
                navigation={false}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination, Navigation]}
                className="mySwiper"
              >
                <div>
                  {courseData?.modules?.map((item, i) => (
                    <SwiperSlide key={i}>
                      <ModuleCard {...item} />
                    </SwiperSlide>
                  ))}
                </div>
                {/* <div className="swiper-button-next custom-arrow"></div>
                <div className="swiper-button-prev custom-arrow"></div> */}
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      {/* course working */}
      <section id="course">
        <div className="course-section">
          <div className="course-container">
            <h1 className="course-heading">
              How our <span style={{ color: "#73589B" }}>program</span> works
            </h1>
            <div className="course-list">
              {course_data.map((item, i) => (
                <div className="course-list-items" onClick={() => toggle(i)}>
                  <button
                    className="course-list-check"
                    onClick={() => toggle(i)}
                  >
                    <img
                      src={
                        selected === i ? course_tick_purple : course_tick_white
                      }
                      className="course-list-img rounded-circle"
                      alt=""
                      loading="lazy"
                    />
                  </button>
                  <div>
                    <h4 className="course-list-heading">{item.heading}</h4>
                    <p
                      className={
                        selected === i
                          ? "course-list-para show"
                          : "course-list-para"
                      }
                    >
                      {item.desc}
                    </p>
                  </div>
                  {/* <div className="course-img">
                    <img src={course_working1} loading="lazy" alt="" />
                  </div> */}
                </div>
              ))}
            </div>

            <div
              className="course-btn"
              style={{ cursor: "pointer" }}
              onClick={handleStartSubscription}
              disabled={isButtonClicked}
            >
              {isButtonClicked && <CircularProgress color="inherit" />}
              {props.isSubscribed ? "Continue" : isPaid ? "Subscribe" : "Start"}
            </div>
          </div>

          <div className="course-img">
            <img src="/assets/img/courseDashboard.png" loading="lazy" alt="" />
          </div>
        </div>
      </section>

      {/* meet your instructor */}
      <section className="instructor-section" id="instructor">
        <div>
          {/* <h3 className="instructor-heading">
            Meet your <span style={{ color: "#541CF5" }}>Instructor</span>
          </h3> */}
        </div>
        <Swiper
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
          }}
          spaceBetween={30}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {courseData?.instructer &&
            courseData?.instructer?.map((item) => (
              <SwiperSlide>
                <div className="instructor-container ">
                  <h3 className="instructor-heading d-block d-md-none">
                    Meet your{" "}
                    <span style={{ color: "#73589B" }}>Instructor</span>
                  </h3>
                  <div className="instructor-image">
                    <img src={item?.image} loading="lazy" alt="" />
                  </div>
                  <div className="instructor-data">
                    <div className="instructor-namee">
                      <h3 className="instructor-heading d-none d-md-block">
                        Meet your{" "}
                        <span style={{ color: "#73589B" }}>Instructor</span>
                      </h3>
                      <div className="d-flex gap-2">
                        <h5 className="instructor-name">{item?.name} </h5>
                        <img
                          src={item?.flag_url}
                          width={25}
                          height={25}
                          alt="flag"
                          style={{ borderRadius: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="instructor-expertise">
                      <p>{item?.type}</p>
                    </div>
                    <div className="instructor-rating">
                      <p>{item?.rating}</p>
                      <Rating
                        initialRating={item?.rating} //
                        readonly
                        fullSymbol={
                          <img src={results_star} alt="" loading="lazy" />
                        }
                        style={{ fontSize: "30px" }}
                      />
                    </div>
                    <div className="instructor-experience">
                      <p>{item?.experience}</p>
                    </div>
                    <div className="instructor-languages">
                      <p>
                        <span style={{ fontWeight: "600" }}>Speaks </span>:{" "}
                        {item?.languages}
                      </p>
                    </div>
                    <div className="instructor-desc">
                      <p>{item?.description}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </section>

      {/* proven results */}
      <section className="results-section" id="results">
        <div className="results-container">
          <h1 className="results-heading">
            <span style={{ color: "#73589B" }}>Proven</span> Results{" "}
          </h1>
          <div className="results-list">
            {courseData?.proven_results &&
              courseData?.proven_results?.map((item, index) => (
                <div className="results-list-items">
                  <p
                    className="results-list-heading"
                    style={{
                      font: "Times New Roman",
                      color:
                        (item?.id === 6 && "#20AF7C") ||
                        (item?.id === 7 && "#CE0058") ||
                        (item?.id === 8 && "#541CF5"),
                    }}
                  >
                    {item?.percentage}
                  </p>
                  <p className="results-list-desc">{item?.description}</p>
                </div>
              ))}
            {/* <div className="results-list-items">
              <p
                className="results-list-heading"
                style={{ font: "Times New Roman", color: "#CE0058" }}
              >
                75%
              </p>
              <p className="results-list-desc">
                of people reported less stress with us
              </p>
            </div>

            <div className="results-list-items">
              <p
                className="results-list-heading"
                style={{ font: "Times New Roman", color: "#541CF5" }}
              >
                80%
              </p>
              <p className="results-list-desc">
                of people repoted improved sleeping pattern
              </p>
            </div> */}

            <div className="results-list-items">
              <div className="d-flex align-items-center gap-2">
                <p
                  className="results-list-heading"
                  style={{ font: "Times New Roman", color: "#A601A9" }}
                >
                  4.7
                </p>
                <img
                  src={results_star}
                  className="img-star"
                  style={{ marginTop: "0" }}
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className="mt-3">
                <img
                  src={google_rating}
                  className="img-google"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <SubscriptionAlertDialougBox
        description="Thank you for showing interest in our program! It is currently available exclusively for our Enterprise users. If your employer is already partnered with us, you may have access through your company's plan—please check with your HR department. We'll notify you as soon as it becomes available for individual users like you."
        courseData={courseData}
        userId={currentUser?.UserId}
        isOpen={openPaymetAlert}
        setOpenPaymetAlert={(item) => setOpenPaymetAlert(item)}
        setIsButtonClicked={(item) => setIsButtonClicked(item)}
      />
    </section>
  );
};

export default WhatToExpect;
