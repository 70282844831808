import React, { useEffect, useState } from "react";
import "../styles/Sections.css";
import Sidebar from "./Sidebar";
import {
  book,
  bookmark,
  section_bg_img,
  section_video_img,
  left_arrow,
  arrow,
} from "../assets";
import { useNavigate, useParams } from "react-router-dom/dist";
import {
  addBookmarkEntry,
  courseInternalContentView,
  getCourseContent,
} from "../_request";
import ReactPlayer from "react-player/lib";
import Iframe from "react-iframe";
import { useAuth } from "../../../auth";
import SidebarMobile from "./SidebarMobile";

import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Helmet } from "react-helmet";

const Section = () => {
  const [courseContent, setCourseContent] = useState({});
  const { id } = useParams();
  const [subLevelId, setSubLevelId] = useState(id);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setSubLevelId(id);
  }, [id]);

  useEffect(() => {
    const body = {
      userId: currentUser?.UserId,
      subLevelId: id,
    };
    //console.log("TRIGGERING!", body);
    setIsLoading(true);
    courseInternalContentView(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setCourseContent(resp?.data);
        setIsBookmarked(resp?.data?.bookmarked);
        setIsLoading(false);
      } else {
        console.error(resp?.ResponseMessage);
      }
    });
  }, [id, subLevelId]);
  //console.log("id", id)

  const handlePrevButton = () => {
    // debugger;
    if (courseContent?.previous_id?.type === "video") {
      navigate(`/course-internal/module/${courseContent?.previous_id?.id}`);
      setSubLevelId(courseContent?.previous_id?.id);
    } else if (courseContent?.previous_id?.type === "audio") {
      navigate(`/course-internal/module/${courseContent?.previous_id?.id}`);
      setSubLevelId(courseContent?.previous_id?.id);
    } else if (courseContent?.previous_id?.type === "checksheet") {
      navigate(
        `/course-internal/module/daily-practice/${courseContent?.previous_id?.id}`
      );
    } else if (courseContent?.previous_id?.type === "journal") {
      navigate(
        `/course-internal/module/my-journal/${courseContent?.previous_id?.id}`
      );
    } else if (courseContent?.previous_id?.type === "quiz") {
      navigate(
        `/course-internal/module/quiz/${courseContent?.previous_id?.id}`
      );
    } else if (courseContent?.previous_id?.type === "html") {
      navigate(
        `/course-internal/module/article/${courseContent?.previous_id?.id}`
      );
    } else if (courseContent?.previous_id?.type === "stella_assessment") {
      navigate(
        `/course-internal/talk-to-stella?assessmentID=${courseContent?.previous_id?.id}`
      );
    } else if (courseContent?.previous_id?.type === "mood_monitor") {
      navigate(
        `/course-internal/module/mood-monitor?id=${courseContent?.previous_id?.id}`
      );
    }
  };

  const handleNextButton = () => {
    //console.log("courseContent", courseContent)
    //debugger;
    if (courseContent?.next_id?.type === "video") {
      navigate(`/course-internal/module/${courseContent?.next_id?.id}`);
      setSubLevelId(courseContent?.next_id?.id);
    } else if (courseContent?.next_id?.type === "audio") {
      navigate(`/course-internal/module/${courseContent?.next_id?.id}`);
      setSubLevelId(courseContent?.next_id?.id);
    } else if (courseContent?.next_id?.type === "checksheet") {
      navigate(
        `/course-internal/module/daily-practice/${courseContent?.next_id?.id}`
      );
    } else if (courseContent?.next_id?.type === "journal") {
      navigate(
        `/course-internal/module/my-journal/${courseContent?.next_id?.id}`
      );
    } else if (courseContent?.next_id?.type === "quiz") {
      navigate(`/course-internal/module/quiz/${courseContent?.next_id?.id}`);
    } else if (courseContent?.next_id?.type === "html") {
      navigate(`/course-internal/module/article/${courseContent?.next_id?.id}`);
    } else if (courseContent?.next_id?.type === "stella_assessment") {
      navigate(
        `/course-internal/talk-to-stella?assessmentID=${courseContent?.next_id?.id}`
      );
    } else if (courseContent?.next_id?.type === "mood_monitor") {
      navigate(
        `/course-internal/module/mood-monitor?id=${courseContent?.next_id?.id}`
      );
    }
  };

  const handlePrevModuleButton = () => {
    navigate(
      `/course-internal/course-view/${courseContent?.previous_module_id?.id}`
    );
  };

  const handleNextModuleButton = () => {
    navigate(
      `/course-internal/course-view/${courseContent?.next_module_id?.id}`
    );
  };

  const handleBookmarkClick = () => {
    const bBody = { sub_level_id: subLevelId };
    addBookmarkEntry(bBody).then((resp) => {
      if (resp?.ResponseCode === 200) {
        // setBookmarkDataStatus(resp?.data);
        //console.log(resp?.data?.bookmarked)
        setIsBookmarked(resp?.data?.bookmarked);
        // console.log("resp?.dataresp?.data", resp?.data);
      } else {
        //console.log(resp?.ResponseMessage);
      }
    });
  };

  //console.log("courseContent", courseContent?.content);

  return (
    <section style={{ display: "flex", overflowX: "hidden" }}>
      <Helmet>
        <title>Program | Section</title>
      </Helmet>
      {isLoading ? (
        <div
          className="d-flex align-items-center justify-content-center mx-auto"
          style={{ minHeight: "100vh" }}
        >
          <img alt="" src="/assets/img/kareify/loader.gif" />
        </div>
      ) : (
        <>
          <section className="sections-sidebar">
            <Sidebar />
          </section>
          <SidebarMobile />
          <section className="sections--content">
            <div>
              <div className="sections-bg-img">
                {/* <img src={section_bg_img} alt="" loading="lazy" /> */}
              </div>
              <div className="sections-container">
                <div className="sections-heading">
                  {courseContent?.course_name}
                  {" >"} {courseContent?.content?.name}
                </div>

                <div className="sections-progress">
                  Section {courseContent?.current_level_index + 1}/
                  {courseContent?.level_count}
                </div>
                <div className="sections-name d-flex gap-2 align-items-center justify-content-between">
                  <h2>{courseContent?.content?.name}</h2>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handleBookmarkClick()}
                  >
                    {isBookmarked ? (
                      <BookmarkIcon color="#541cf5" />
                    ) : (
                      <BookmarkBorderOutlinedIcon />
                    )}
                  </div>
                </div>

                {/* <hr style={{ color: "#E0E0E0" }} /> */}
                <div className="sections-desc">
                  {courseContent?.description}
                </div>

                <div className="sections-video-img">
                  {/* <img src={section_video_img} alt="" loading="lazy" /> */}
                  {courseContent?.content?.video?.link ? (
                    <Iframe
                      url={courseContent?.content?.video?.player_embed_url}
                      controls={true}
                      className="VideoURL"
                    ></Iframe>
                  ) : (
                    <ReactPlayer
                      url={courseContent?.content?.audio?.audio_url}
                      controls={true}
                      className="AudioURL"
                    ></ReactPlayer>
                  )}
                </div>

                <div className="sections-btn">
                  <div>
                    {courseContent?.previous_id?.type && (
                      <div className="prev-btn" onClick={handlePrevButton}>
                        <div className="modules-text">Previous</div>
                        <div className="prev-arrow">
                          <img
                            src={left_arrow}
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                          />
                        </div>
                      </div>
                    )}
                    {courseContent?.previous_module_id?.id &&
                      !courseContent?.previous_id?.type && (
                        <div
                          className="prev-btn"
                          onClick={handlePrevModuleButton}
                        >
                          <div className="modules-text">Previous Module</div>
                          <div className="prev-arrow">
                            <img
                              src={left_arrow}
                              alt=""
                              loading="lazy"
                              className="arrow-img"
                            />
                          </div>
                        </div>
                      )}
                  </div>
                  <div>
                    {courseContent?.next_id?.type && (
                      <div className="next-btn" onClick={handleNextButton}>
                        <div className="modules-text">Next</div>
                        <div className="next-arrow">
                          <img
                            src={arrow}
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                          />
                        </div>
                      </div>
                    )}
                    {courseContent?.next_module_id?.id &&
                      !courseContent?.next_id?.type && (
                        <div
                          className="next-btn"
                          onClick={handleNextModuleButton}
                        >
                          <div className="modules-text">Next Module</div>
                          <div className="next-arrow">
                            <img
                              src={arrow}
                              alt=""
                              loading="lazy"
                              className="arrow-img"
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </section>
  );
};

export default Section;
