import React, { useEffect, useState } from 'react'
import { Badge, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getExpertRating } from './core/_request';

const DetailAboutTab = (props) => {
    const { data } = props;
    // console.log("data", data)
    const [readMoreExperties, setReadMoreExperties] = useState()
    const [readMoreAboutMeText, setReadMoreAboutMeText] = useState()
    // console.log("data.rting", data?.ratingData?.length)

    // console.log("data", data)
    var ExpertisesArrayCount = data?.Expertise?.split(',').length - 4
    var ExpertisesArray = data?.Expertise?.split(',').length > 4 ? data?.Expertise?.split(',').slice(0, 4) : data?.Expertise?.split(',');
    var ExpertisesArrayAll = data?.Expertise?.split(',')
    var LanguagesArray = data?.Languages?.split(',')
    var userReview = data?.ratingData != undefined && data?.ratingData.length > 0 ? data?.ratingData[0] : null;
    // console.log("userReview", userReview)

    return (
        // <div>
        //     {
        //         readMoreExperties &&

        //         <h6 className='row align-items-baseline gap-2 mx-0'>Expertise:
        //             {
        //                 ExpertisesArrayAll && ExpertisesArrayAll.map((Expertise) => (
        //                     <span className='font-w500 fs-6 rounded-5' style={{ border: "1px solid #60B4E7CC", width: "auto" }}>{Expertise}</span>
        //                 ))
        //             }
        //         </h6>

        //         ||

        //         <h6 className='row align-items-baseline gap-2 mx-0'>Expertise:
        //             {
        //                 ExpertisesArray && ExpertisesArray.map((Expertise) => (
        //                     <span className='font-w500 fs-6 rounded-5' style={{ border: "1px solid #60B4E7CC", width: "auto" }}>{Expertise}</span>
        //                 ))
        //             }
        //             <button className="w-auto border-0 px-3 py-1 font-w700" style={{ background: "none", color: "#3DACF0" }} onClick={() => setReadMoreExperties(true)}>Load More...</button>
        //         </h6>
        //     }
        //     <br />
        //     <h6>Language:
        //         {
        //             LanguagesArray && LanguagesArray.map((Language) => (
        //                 <span className='font-w500 fs-6 rounded-5 px-2 py-1 mx-1' style={{ border: "1px solid #60B4E7CC" }}>{Language}</span>
        //             ))
        //         }
        //     </h6><br />
        //     <h6 className='d-flex align-items-center'>Country:
        //         <span className='font-w500 fs-6 rounded-5 px-2 mx-1' style={{ border: "1px solid #60B4E7CC" }}><img src='/assets/img/india.png' alt='' /> India</span>
        //     </h6><br />
        //     {
        //         readMoreAboutMeText &&
        //         <p className='py-2 align-text-justify'><span className='font-w600 fs-5 text-black'>About Me:</span><br />
        //             {data?.AboutYourProfessionalExperience && data?.AboutYourProfessionalExperience?.length > 200 ?
        //                 <>
        //                     <span>{data?.AboutYourProfessionalExperience}</span>
        //                 </>
        //                 : data?.AboutYourProfessionalExperience
        //             }
        //         </p>
        //         ||
        //         <p className='py-2 align-text-justify'><span className='font-w600 fs-5 text-black'>About Me:</span><br />
        //             {data?.AboutYourProfessionalExperience && data?.AboutYourProfessionalExperience.length > 200 ?
        //                 <>
        //                     <span>{data?.AboutYourProfessionalExperience?.toString()?.slice(0, 200)} </span>
        //                     <button className="border-0 px-3 py-1 font-w700" style={{ background: "none", color: "#3DACF0" }} onClick={() => setReadMoreAboutMeText(true)}>Load More...</button>
        //                 </>
        //                 : data?.AboutYourProfessionalExperience
        //             }
        //         </p>
        //     }
        // </div>

        <div>
            {/* <h6 className='py-2'>About</h6>
            <p className='py-2'>Experience : {data.Experience} years</p> */}
            {
                readMoreAboutMeText &&
                <p className='font-inter fs-6 lh-base align-text-justify my-3'>
                    {data?.AboutYourProfessionalExperience && data?.AboutYourProfessionalExperience?.length > 200 ?
                        <>
                            <span className='font-inter fs-6' style={{ color: "#494747" }}>{data?.AboutYourProfessionalExperience}</span>
                        </>
                        : data?.AboutYourProfessionalExperience
                    }
                </p>
                ||
                <p className='font-inter fs-6 lh-base align-text-justify'>
                    {data?.AboutYourProfessionalExperience && data?.AboutYourProfessionalExperience.length > 300 ?
                        <>
                            <span className='font-inter fs-6' style={{ color: "#494747" }}>{data?.AboutYourProfessionalExperience?.toString()?.slice(0, 300)} </span>
                            <button className="load-more border-0 py-1 font-w500" style={{ background: "none" }} onClick={() => setReadMoreAboutMeText(true)}>Load More...</button>
                        </>
                        : data?.AboutYourProfessionalExperience
                    }
                </p>
            }
            <p className='font-inter fs-5 font-w500 my-3 text-black'>Speaks<br />
                {
                    LanguagesArray && LanguagesArray.map((Language) => (
                        <span className='font-inter fs-6 lh-base font-w400' style={{ color: "#494747" }}> {Language}</span>
                    ))
                }
                {/* <span className='font-w500 fs-6 rounded-5 px-2 py-1 mx-1' style={{ border: "1px solid #60B4E7CC" }}>Hindi</span> */}
            </p>
            {
                readMoreExperties &&
                <>
                    <p className='font-inter fs-5 font-w500 my-4 text-black d-flex align-items-center flex-wrap lh-lg'>Expertise</p>
                    {
                        ExpertisesArrayAll && ExpertisesArrayAll.map((Expertise) => (
                            <span className='font-inter font-w500 fs-6 rounded-2 me-2 my-1 px-1 py-md-1 my-md-0' style={{ border: "1px solid #60B4E7CC", width: "auto", color: "#494747" }}>{Expertise}</span>
                        ))
                    }
                </>
                ||
                <>
                    <p className='font-inter fs-5 font-w500 my-2 text-black d-flex align-items-center flex-wrap'>Area of Interest</p>
                    <div className='row px-2 align-items-center'>

                        {
                            ExpertisesArray && ExpertisesArray?.map((expertiseAll, index) => (
                                <span className='font-inter font-w500 fs-6 rounded-2 me-2 my-1 px-1 py-md-1 my-md-1' style={{ border: "1px solid #60B4E7CC", width: "auto", color: "#494747" }}>
                                    {expertiseAll}
                                </span>
                            ))
                        }
                        {
                            ExpertisesArrayCount >= 1 ?
                                <button
                                    className="rounded-1 px-2 py-0 my-3 font-w700 w-auto d-flex flex-row align-items-center justify-content-center gap-3"
                                    style={{ background: "#FFFFFF", border: "1px solid #E2E2E1" }}
                                    onClick={() => props.changeTab("expertise")}> {ExpertisesArrayCount}+ <img src='/assets/img/Vector(17).png' alt='' />
                                </button>
                                :
                                null
                        }
                    </div>
                </>
            }
            <br />

            {data?.ratingData >= 3 ?
                <div className='my-3'>
                    <p className='font-inter fs-3 font-w500 text-black d-flex align-items-center gap-3'><img src='/assets/img/image 3.png' alt='' />{data?.Rating}
                        <span className='font-inter fs-6 font-w400' style={{ color: "#494747" }}> ({data?.ratingData?.length} reviews)</span>
                    </p>
                    <div className='d-flex align-items-center gap-3 my-3'>
                        <img src='/assets/img/Ellipse 1.png' alt='' />
                        <p className='font-inter fs-6 font-w500 text-black text-capitalize lh-sm'>{userReview?.UserName} <br />
                            <span className='font-inter fs-6 font-w400' style={{ color: "#494747" }}>{userReview?.UserCountry}</span>
                        </p>
                    </div>
                    <p className='font-inter fs-6'>
                        {userReview?.Message}
                        {/* As a self-harm patient, I cannot express enough gratitude for the exceptional care and attention I received from you as my psychologist. Your unwavering support and guidance have been instrumental in helping me overcome the struggles I have faced in my life. */}
                    </p>
                    <br />
                    {
                        data?.ratingData?.length >= 1 ?
                            <Link onClick={() => props.changeTab("reviews")} className='d-flex align-items-center text-decoration-none gap-3'>
                                <p className='font-inter font-w400 fs-6' style={{ color: "#494747" }}>All Reviews ({data?.ratingData?.length}) </p><img src='/assets/img/Vector(18).png' alt='' />
                            </Link>
                            :
                            null
                    }
                </div>
                :
                null
            }
        </div>
    )
}

export default DetailAboutTab