import React from "react";
import "../../styles/courses/Testimonials.css";
import TestimonialCard from "./TestimonialCard";
import { testimonial_commas, user_image } from "./assets";
import { } from "./assets";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";

const data = [
  {
    title: "Practical tools to manage my stress and anxiety",
    desc: "I used to struggle with overwhelming anxiety that made it hard to enjoy life. This course provided me with practical tools to manage my stress and anxiety. I now feel more in control and am able to face challenges with a clearer mindset.",
    imageUrl: user_image,
    username: "John Thompson",
    courseName: "Anxiety and Stress Management Program",
  },
  {
    title: "Practical tools to manage my stress and anxiety",
    desc: "I used to struggle with overwhelming anxiety that made it hard to enjoy life. This course provided me with practical tools to manage my stress and anxiety. I now feel more in control and am able to face challenges with a clearer mindset.",
    imageUrl: user_image,
    username: "John Thompson",
    courseName: "Anxiety and Stress Management Program",
  },
  {
    title: "Practical tools to manage my stress and anxiety",
    desc: "I used to struggle with overwhelming anxiety that made it hard to enjoy life. This course provided me with practical tools to manage my stress and anxiety. I now feel more in control and am able to face challenges with a clearer mindset.",
    imageUrl: user_image,
    username: "John Thompson",
    courseName: "Anxiety and Stress Management Program",
  },
  {
    title: "Practical tools to manage my stress and anxiety",
    desc: "I used to struggle with overwhelming anxiety that made it hard to enjoy life. This course provided me with practical tools to manage my stress and anxiety. I now feel more in control and am able to face challenges with a clearer mindset.",
    imageUrl: user_image,
    username: "John Thompson",
    courseName: "Anxiety and Stress Management Program",
  },
  {
    title: "Practical tools to manage my stress and anxiety",
    desc: "I used to struggle with overwhelming anxiety that made it hard to enjoy life. This course provided me with practical tools to manage my stress and anxiety. I now feel more in control and am able to face challenges with a clearer mindset.",
    imageUrl: user_image,
    username: "John Thompson",
    courseName: "Anxiety and Stress Management Program",
  },
];

const Testimonials = (props) => {
  const { courseData } = props;
  const len = courseData?.reviews?.length;

  return (
    <section className="testimonial-section">
      <div className="testimonial-container">
        <div className="testimonial-heading">
          Hear from our <span style={{ color: "#73589B" }}>Users</span>
        </div>
        <div className="testimonial-cards" style={{}}>
          <Swiper
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              // Todo: len is less than 3 otherwise 3
              1024: {
                slidesPerView: len < 3 ? len : 3,
              },
            }}
            spaceBetween={30}
            navigation={len > 3 ? true : false}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          // style={{ width: `${len * 25}vw` }}
          >
            <div className="testimonial-card active">
              {courseData?.reviews?.map((item, i) => (
                <SwiperSlide key={i}>
                  <TestimonialCard item={item} />
                </SwiperSlide>
              ))}
            </div>
            {(len > 3 && (
              <>
                <div className="swiper-button-next custom-arrow"></div>
                <div className="swiper-button-prev custom-arrow"></div>
              </>
            )) ||
              ""}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
