import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { getCallbackCode } from './core/_request';
import { useState } from 'react';

const Callback = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState({});
    const [responseMessage, setResponseMessage] = useState("")
    let IsCallAPI = false;
    let code = searchParams.get("code");
    useEffect(() => {
        if (!IsCallAPI) {
            IsCallAPI = true;
            const body = { "code": code }
            getCallbackCode(body).then(resp => {
                if (resp?.code === 200) {
                    setData(resp?.data)
                    localStorage.setItem("profile", data?.userdetails);
                    // localStorage.setItem("profile.UserType", data?.UserType);
                    localStorage.setItem("Authorization", data?.token_type + data?.access_token);
                    let now = new Date();
                    let oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
                    let firstName = data?.userdetails?.Name.split(" ").slice(0, 1).join("")
                    document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toUTCString()};domain=.unitedwecare.com;path=/;`;
                    console.log("?.Name", data?.userdetails?.Name);
                } else {
                    setResponseMessage(resp?.ResponseMessage);
                }
            });
        }
    }, []);


    return (
        <div>
            {responseMessage}
        </div>
    )
}

export default Callback