import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Form, ToggleButton } from 'react-bootstrap'
import DatePicker from './DatePicker';
import moment from 'moment';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { getExpertbyId_WebService, AppointmentTimeService, AddAppointmentUserService, IndianpackagewithpriceService, getInstituteDiscountOODOService, AddUserPackageService, UserDetailsService, AddTransactionService, UpdateTransactionService, saveAppointmentOrder, SubscriptionDiscount } from './core/_request';
import { useAuth } from '../auth';
import { getCountryCode, getCurrencyCode } from './core/commonFun';
import { RescheduledService } from './user/core/_request';



const BookAnAppointment = () => {
    const [loading, setLoading] = useState(false);
    const [slots, setSlots] = useState();
    const { currentUser, logout } = useAuth();
    const [data2, setData2] = useState();
    const [callTime, setCallTime] = useState(60);
    const [expertDetailsData, setExpertDetailsData] = useState();
    const navigate = useNavigate();
    let { id } = useParams();
    const search = useLocation().search;
    const countryCode = getCountryCode();
    const CurrencyCode = getCurrencyCode();
    const [calenderData, setCalenderData] = useState();
    const bookAnAppointmentData = JSON.parse(localStorage.getItem("bookAnAppointmentData"));

    useEffect(() => {
        // console.log("id", id)
        IndianpackagewithpriceService(id).then((resp) => { setData2(resp.data); saveAppointmentOrdersendToServer(resp.data); });
        getExpertbyId_WebService(id).then(resp => {
            var data = resp?.data;
            if (countryCode == "IN") data["countryAmount"] = data?.Hourly_Consultaion_Fee_amount;
            else data["countryAmount"] = data?.USD_Amount;
            setExpertDetailsData(data);
        });
        console.log("setExpertDetailsData", expertDetailsData)
        binddata();
    }, []);

    const binddata = () => {

        var dates = [];
        for (var i = 0; i <= 15; i++) {
            var date = moment(new Date()).add("d", i);
            dates.push({ date, avaliableSlots: "Loading", checked: bookAnAppointmentData ? moment(bookAnAppointmentData.selectedDate).format("DD MMM") == moment(date).format("DD MMM") : (i == 0) });
        }
        setCalenderData(dates);

        dates.forEach((element, index) => {
            AppointmentTimeService(id, moment(element.date).format("YYYY/MM/DD")).then(resp => {
                if (resp.ResponseCode == 200) {
                    var length = resp?.data?.filter(o => o.IsAvailable)?.length;
                    element.avaliableSlots = length == 0 ? "Not Avaliable" : length + " Available";
                    //element.checked = bookAnAppointmentData && bookAnAppointmentData.selectSlot.Time_Duration==element.Time_Duration
                    setCalenderData([...dates]);
                    if (bookAnAppointmentData && moment(bookAnAppointmentData.selectedDate).format("DD MMM") == moment(element.date).format("DD MMM")) {
                        //bookAnAppointmentData.selectSlot.Time_Duration==element.Time_Duration
                        setSlots(resp?.data?.filter(o => o.IsAvailable)?.map(o => { o["checked"] = bookAnAppointmentData.selectSlot.Time_Duration == o.Time_Duration; return o; }))
                    }
                    else if (!bookAnAppointmentData && index == 0) {
                        setSlots(resp?.data?.filter(o => o.IsAvailable))
                    }


                    //
                }
            });
        });



    }

    const saveAppointmentOrdersendToServer = (data2s) => {
        var body = {
            ExpertId: id,
            App_Date: bookAnAppointmentData.selectedDate,
            App_Time: bookAnAppointmentData.selectSlot.Time_Duration,
            Session_Type: callTime,
            Amount: data2s.Amount
        }
        saveAppointmentOrder(body).then(resp => {
            console.log("saveAppointmentOrder resp", resp)
            if (resp.data == "") {

            }
        })
    }

    const getSlotByDate = (element) => { 
        setCalenderData(calenderData?.map(o => { if (o.date == element.date) o.checked = true; else o.checked = false; return o })); 
        AppointmentTimeService(id, moment(element.date).format("YYYY/MM/DD")).then(resp => {
            if (resp.ResponseCode == 200) {
                setSlots(resp.data.filter(o => o.IsAvailable))
            }
        });
    }

    const onCheckout = () => {
        var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId
        var user_email = JSON.parse(localStorage.getItem("profile"))?.Emailid;
        var body = {
            "params": {
                "userId": user_id,
                "email": user_email,
                "expert_id": id
            }
        }
        SubscriptionDiscount(body).then(resp => {
            if (resp?.discountPercentage === 0) {
                navigate('/plans')
            } else if (resp?.discountPercentage > 0) {
                var selectSlot = slots?.filter(o => o.checked);
                if (selectSlot?.length == 0) {
                    alert("Please select a time slot!");
                    return false;
                }
                setLoading(true);

                var book_App_datat = JSON.stringify({ selectedDate: calenderData?.find(o => o.checked)?.date, selectSlot: slots?.find(o => o.checked), callTime: callTime, data2: data2 });
                localStorage.setItem("bookAnAppointmentData", book_App_datat);

                const AppointmentId = new URLSearchParams(search).get("AppointmentId");

                if (AppointmentId) navigate(`/payment-order-preview/${id}?AppointmentId=${AppointmentId}`, {})
                else navigate(`/payment-order-preview/${id}`)
            }
        })
    }

    return (
        expertDetailsData &&
        <div className='pb-3' style={{ background: "#F7F2FF", padding: 0, }}>
            <div className='container'>
                <div className="py-md-5 py-3">
                    <h6 className="font-inter text-black font-w600 fs-4 text-center">Book a Session</h6>
                    {/* <p className="text-black text-center">Select the day, date & time for your session.</p> */}
                </div>
                <div className='bg-white mb-5' style={{ background: "#FFFFFF", boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)", borderRadius: "12px" }}>
                    <div className='py-3 mx-auto px-2 px-md-5 row gap-md-5'>
                        <div className=''>
                            <h5 className="font-inter text-black font-w500 fs-6">Choose Date</h5>
                            <ButtonGroup className='d-flex flex-row gap-3 col-12 my-2 overflowX-scroll'>
                                {
                                    calenderData?.map((item, index) =>
                                        item.avaliableSlots != 'Not Avaliable' &&
                                        <ToggleButton
                                            onClick={() => getSlotByDate(item)}
                                            type="radio"
                                            name="radio"
                                            className="date-availability rounded-2 col-2 px-1 py-1 ms-1"
                                            style={{
                                                background: item.checked ? "#CCB6EC" : "#fff",
                                                color: "#000",
                                                border: "1px solid #CCB6EC",
                                                minWidth: "110px",
                                                maxWidth: "150px"
                                            }}>
                                            <p className='font-inter fs-6 text-center'>{moment(item.date).format("DD MMM") == moment().format("DD MMM") ? "Today" : moment(item.date).format("DD MMM")}</p>
                                            <p className='font-inter fs-6 text-center'>{item?.avaliableSlots}</p>
                                        </ToggleButton>)
                                }
                            </ButtonGroup>
                        </div>
                    </div>
                    {
                        slots && slots?.length == 0 &&
                        <div className='py-3 mx-auto px-2 px-md-5 row gap-md-2'>
                            <h5 className="font-inter text-black font-w500 fs-5">Available time slots (IST)</h5>
                            <p className='font-inter fs-5 text-black font-w400'>No slot Available</p>
                        </div>
                        ||
                        <div className='py-3 mx-auto px-2 px-md-5 row gap-md-2'>
                            <h5 className="font-inter text-black font-w500 fs-6">Available time slots (IST)</h5>
                            <div>
                                <ButtonGroup className='row gap-3 col-12 mx-1 my-2'>
                                    {
                                        slots?.map((item, index) =>
                                            <ToggleButton
                                                onClick={() => setSlots(slots?.map(o => { if (o.ID == item.ID) o.checked = true; else o.checked = false; return o }))}
                                                type="radio"
                                                name="radio"
                                                className="time-availability rounded-2 py-2 px-0 col-3 d-block"
                                                style={{
                                                    background: item.checked ? "#CCB6EC" : "#fff",
                                                    color: "#000",
                                                    border: "1px solid #CCB6EC",
                                                    minWidth: "110px",
                                                    maxWidth: "150px"
                                                }}>
                                                <p>{item.Time_Duration}</p>
                                            </ToggleButton>)
                                    }
                                </ButtonGroup>
                            </div>
                        </div>
                    }
                    <div className='py-3 mx-auto px-2 px-md-5 row gap-md-2'>
                        {(data2?.AppType != 'Psychiatrist') &&
                            <h5 className="font-inter text-black font-w500 fs-6">Session Duration</h5>
                            ||
                            ""
                        }
                        <div className=''>
                            {data2 && <ButtonGroup className='d-flex flex-wrap flex-md-row gap-2'>
                                {
                                    (data2?.AppType != 'Psychiatrist') &&
                                    <>
                                        <ToggleButton
                                            type="radio"
                                            name="radio"
                                            className="py-2 my-3 px-3 col-12 col-md-5"
                                            style={
                                                callTime === 30 ? {
                                                    background: "#CCB6EC",
                                                    color: "black",
                                                    borderRadius: "100px",
                                                    border: "none",
                                                } : {
                                                    background: "#F2F4F7",
                                                    color: "black",
                                                    borderRadius: "100px",
                                                    border: "none",

                                                }}
                                            onClick={() => setCallTime(30)}>
                                            30 min
                                            {/* @ {CurrencyCode} {Math.round(expertDetailsData.countryAmount / 2)} */}
                                        </ToggleButton>
                                        <ToggleButton
                                            type="radio"
                                            name="radio"
                                            className="py-2 my-3 px-3 col-12 col-md-5"
                                            style={callTime === 60 ? {
                                                background: "#CCB6EC",
                                                color: "black",
                                                borderRadius: "100px",
                                                border: "none",
                                            } : {
                                                background: "#F2F4F7",
                                                color: "black",
                                                borderRadius: "100px",
                                                border: "none",

                                            }}
                                            onClick={() => setCallTime(60)}>
                                            60 min
                                            {/* @ {CurrencyCode} {expertDetailsData.countryAmount} */}
                                        </ToggleButton>
                                    </>
                                    // ||

                                    // <>
                                    //     <ToggleButton
                                    //         type="radio"
                                    //         name="radio"
                                    //         defaultChecked
                                    //         className="py-2 my-3 px-3 col-12 col-md-5"
                                    //         style={callTime === 60 ? {
                                    //             background: "linear-gradient(180deg, #E7F6FF 100%, #e7f6ff 0%)",
                                    //             color: "black",
                                    //             borderRadius: "100px",
                                    //             border: "none",
                                    //         } : {
                                    //             background: "#F2F4F7",
                                    //             color: "black",
                                    //             borderRadius: "100px",
                                    //             border: "none",

                                    //         }}
                                    //         onClick={() => setCallTime(60)}>
                                    //         {CurrencyCode} {expertDetailsData.countryAmount} @ Per Session
                                    //     </ToggleButton>
                                    // </>
                                }
                            </ButtonGroup>}
                        </div>
                    </div>
                    <div className='py-3 mx-auto px-2 px-md-5 row gap-md-2'>
                        <h5 className="font-inter text-black font-w500 fs-6">Describe Your Emotional Wellness Challenges In Words</h5>
                        <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                            <Form.Control as="textarea" style={{ height: "150px" }}
                                placeholder="Share your concerns & a brief about your condition here. This will give the expert an understanding of the challenges you are facing and the help you seek." rows={3} />
                        </Form.Group>
                    </div>
                    <div className="d-flex flex-row justify-content-center py-3 px-4 gap-3">
                        {loading &&
                            <Button className="px-5 border-0 font-w600 font-inter fs-6 lh-base" style={{ background: "#9FE770", borderRadius: "40px", height: '48px' }}><img src="/assets/img/loading.gif" alt="UWC" style={{ height: 26, width: 26 }} /></Button>
                            ||
                            <Button
                                id='appointment_book_now'
                                className="primary-color book-now-a-professional col-12 col-md-3 px-5 border-0 font-w600 font-inter fs-6 lh-base"
                                style={{ background: "#73589B", borderRadius: "40px", height: '48px' }}
                                onClick={onCheckout}
                            // onClick={onClickNext}
                            >Book Now
                            </Button>
                        }
                    </div>
                </div >
            </div >
        </div >
    )
}

export default BookAnAppointment