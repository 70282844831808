import React, { Component, useEffect } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import { Badge, Button } from 'react-bootstrap'

function PaymentStatus() {
    let { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            // navigate("/stella?message=Gad7-Start");
            //navigate("/dashboard/my-appointments");
            navigate("/user-dashboard");
        }, 5000);
        return () => clearTimeout()
    }, []);

    return (
        <div className='' style={{ background: "linear-gradient(180deg, #3DACF0 55.1%, rgba(99, 132, 227, 0) 100%)", padding: 0, margin: 0 }}>
            <div className='container'>
                {id == "success" &&
                    <div className="py-5">
                        <h6 className="text-white font-w600 fs-4">Thank you for booking a session.</h6>
                        <p className='text-white'>We will contact you soon.</p>
                    </div>
                }
                <div className='bg-white' style={{ background: "#FFFFFF", boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)", borderRadius: "12px" }}>
                    <div className='py-5 mx-auto px-2 px-md-5 row gap-md-5'>
                        <div className="col-xs-12 col-md-12 text-center">
                            {
                                id == "success" && <p style={{ marginBottom: 20 }}>Your payment was successful</p> || <p style={{ marginBottom: 20 }}>Payment Failure! <br /> Please try again to complete your booking.</p>
                            }

                            {/* <Link to={"/dashboard/my-appointments"}><Button className='px-4' style={{ background: "linear-gradient(90deg, #FF9C00 0%, #FFD255 100%)", border: "none", color: "#fff" }}>View Appointments</Button></Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentStatus;