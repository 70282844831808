import React, { useState } from "react";
import image1 from "../../../dashboard/images/img1.png";
import image2 from "../../../dashboard/images/img2.png"
import image3 from "../../../dashboard/images/img3.png"
import { BsArrowRight } from "react-icons/bs";

function Today_picks() {
    const [Option ,setOption]=useState(0)
      const[carddata,setcarddata]=useState([
        
        {image:image1,text:"View Details",title:"Anxiety",cardtext:"A deep understanding how anxiety <br /> gets triggered"},
        {image:image2,text:"View Details",title:"Anxiety",cardtext:"A deep understanding how anxiety <br /> gets triggered"},
        {image:image3,text:"View Details",title:"Anxiety",cardtext:"A deep understanding how anxiety <br /> gets triggered"}
  
      ])
  
 
  
      
  
      const clicked=(a)=>{
        alert(a,"clicked...")
    }
    
    return (
      <div className="my-3">
      
      <div className=""
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          boxShadow: " 2px 2px 9px 14px rgba(0,0,0,0.06);",
        }}
      >
          <div className="d-flex justify-content-between pe-2">
              <div>
              <h5 className="text-start px-3 ms-3 pt-3">Today's Picks</h5>
  
              <p className="m-0 p-0  text-start px-3 ms-3 ">Explore all programs here </p>
  
  
 
  
              </div>
         
        
  
  
          </div>
  
   
  
        <div className="row main-card p-md-4">
          <div className="col-md-12">
            <div className="">
              <div className="scroll-container d-flex vh-auto py-5 pt-0 px-2">
  
  
              {carddata.map((a)=>(
  
  
           
                <div className="">
                  <div className=" ">
                    <div
                      className="card mx-2"
                      style={{ width: "340px", height: "165px" }} onClick={()=>clicked("data")}
                    >
                      <img card-img-top src={a.image} className="img-fluid" style={{borderRadius:'20px'}}/>
                      <div className="card-img-overlay ">
                        <div className="d-flex justify-content-end">
                        <p class="badge rounded-pill   px-2 py-1 m-0" style={{ backgroundColor: "rgba(0, 0, 0, 0.316)",cursor:'pointer'}}>
                          {a.text} <BsArrowRight size={15} />
                        </p>
                        </div>
                        <div>
                          <div className="text-start mt-3 pt-2">
                            <text class="card-title text-light " style={{ fontSize: "1.2rem" }}>
                              {a.title}
                            </text>
                            <p class="card-text   text-light fw-bold" style={{ fontSize: "1.1rem" }} 
                            
                            dangerouslySetInnerHTML={{
                              __html: a.cardtext.replace(/<br>/g, '<br />')
                               }} 
                            
                            />
   
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
  ))}
  
   
  
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }

export default Today_picks