import React from "react";
import DashboardCards from "./DashboardCards";
import ExploreBlogs from "./ExploreBlogs";
import Appointments from "../Dashboard/Appointments";
import RecommendedPrograms from "./RecommendedPrograms";
import ProfessionalHelp from "./ProfessionalHelp";
import Sidebar from "../Dashboard/Sidebar";

import Navbar from "./Navbar";

function Main() {
  return (
    <>
      {/* <Navbar /> */}
      <main>
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-2 d-none d-lg-inline">
                <Sidebar />
              </div>

              <div
                className="col-lg-10 px-lg-5 py-md-3 ps-md-3 pt-md-3 pb-0 m-0"
                style={{
                  backgroundColor: "#F4F4F4",
                  //borderRadius: "8px",
                  // height: "",
                  // overflowY: "",
                }}
              >
                <DashboardCards />

                <ExploreBlogs />

                <Appointments />

                <RecommendedPrograms />

                <ProfessionalHelp />
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Main;
