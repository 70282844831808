import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';
import TextField from '@mui/material/TextField';

const OTPModal = (props) => {
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='otp--popup'
        >
            <Modal.Body className='d-flex flex-column gap-4'>
                <div className='otp--header d-flex align-items-center justify-content-between'>
                    <h3>Confirm OTP</h3>
                    <CloseButton onClick={props.onHide} />
                </div>

                <p>
                    An OTP has been sent to your email <span>sonyadawar@abc.com</span> Please enter the OTP to verify your email.
                </p>

                <div className='d-flex align-items-center gap-5 justify-content-center px-5'>
                    <TextField id="standard-basic" variant="standard" inputProps={{ maxLength: 1 }} />
                    <TextField id="standard-basic" variant="standard" inputProps={{ maxLength: 1 }} />
                    <TextField id="standard-basic" variant="standard" inputProps={{ maxLength: 1 }} />
                    <TextField id="standard-basic" variant="standard" inputProps={{ maxLength: 1 }} />
                </div>

                <div className='otp--resend d-flex align-items-center justify-content-between px-4'>
                    <p>00:30</p>
                    <p>Resend OTP</p>
                </div>

                <Button className='otp--continue-btn border-0 d-block mx-auto my-3'>
                    Continue
                </Button>
            </Modal.Body>
        </Modal>
    )
}

export default OTPModal