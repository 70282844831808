import React, { useState } from "react";
import "../../styles/courses/Details.css";
import {
  Ellipse405,
  Ellipse406,
  Ellipse407,
  Ellipse408,
  Ellipse409,
  Ellipse410,
} from "./assets";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { OODOCoursePaymentService } from "../core/_request";
import CircularProgress from "@mui/material/CircularProgress";
import SubscriptionAlertDialougBox from "./SubscriptionAlertDialougBox";

const Details = (props) => {
  const { courseData, isPaid } = props;
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [openPaymetAlert, setOpenPaymetAlert] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleStartSubscription = () => {
    setIsButtonClicked(true);
    if (!currentUser) {
      localStorage.setItem(
        "retrunurl",
        `/course-new/${props?.courseData?.course_title}/${props?.courseId}`
      );
      alert("Something went Wrong! Please try to close and re-open app.");
      setIsButtonClicked(false);
    } else {
      if (courseData?.enrolled) {
        navigate(
          `/course-internal/${courseData?.course_data_id}/${courseData?.course_title}`
        );
        let linksData = [
          {
            name: "Home",
            uri: `/course-internal/${courseData?.course_data_id}/${courseData?.course_title}`,
          },
          { name: "My Journal", uri: `/course-internal/module/my-journal/:id` },
        ];
        sessionStorage.setItem(
          "courseInternalLinks",
          JSON.stringify(linksData)
        );
        setIsButtonClicked(false);
      } else if (
        courseData?.inr_price === 0.0 ||
        courseData?.course_type === "free"
      ) {
        let request = {
          userId: currentUser?.UserId,
          courseId: props?.courseId,
        };
        OODOCoursePaymentService(request).then((res) => {
          //console.log("RES", res);
          setIsButtonClicked(false);
          navigate(
            `/course-internal/${res?.data.data.id}/${courseData?.course_title}`
          );
          let linksData = [
            {
              name: "Home",
              uri: `/course-internal/${res?.data?.data?.id}/${courseData?.course_title}`,
            },
            {
              name: "My Journal",
              uri: `/course-internal/module/my-journal/:id`,
            },
          ];
          sessionStorage.setItem(
            "courseInternalLinks",
            JSON.stringify(linksData)
          );
        });
        setIsButtonClicked(false);
      } else if (courseData?.course_type === "premium") {
        localStorage.setItem("retrunurl", `/course-new`);
        // navigate("/plans");
        setOpenPaymetAlert(true);
      }
    }
  };

  return (
    <section className="details-section">
      <div className="details-images-left">
        <img src={Ellipse405} width={200} height={200} className="img1" />
        <img src={Ellipse406} width={220} height={220} className="img2" />
        <img src={Ellipse407} width={240} height={240} className="img1" />
      </div>
      <div className="details-main">
        <h1 className="details-heading">
          Uncover the
          <span className="text-highlight"> SAFE HAVEN </span>
          for your mental health
        </h1>

        <div className="details-list">
          <ul>
            <li className="details-list-items">
              {/* <div>
                <img src={green_check} />
              </div> */}
              <span className="text-highlight">Carefully crafted </span>programs
              rigorously tested in controlled environments for proven
              effectiveness
            </li>
            <li className="details-list-items">
              <span className="text-highlight">
                24*7 AI powered wellness coach, STELLA,{" "}
              </span>{" "}
              to assist you in times of distress in your preferred language
            </li>
            <li className="details-list-items">
              <span className="text-highlight">Best in class </span> global
              therapists & coaches with diverse wisdom
            </li>
            <li className="details-list-items">
              <span className="text-highlight">Clinically proven </span>{" "}
              assessments & curated content to enhance your emotional well-being
            </li>
          </ul>
        </div>

        <button
          className="details-btn"
          onClick={handleStartSubscription}
          disabled={isButtonClicked}
        >
          {isButtonClicked && <CircularProgress color="inherit" />}
          {props.isSubscribed ? "Continue" : isPaid ? "Subscribe" : "Start"}
        </button>
      </div>

      <div className="details-images-right">
        <img src={Ellipse408} width={240} height={240} className="img3" />
        <img src={Ellipse409} width={200} height={200} className="img4" />
        <img src={Ellipse410} width={220} height={220} className="img3" />
      </div>

      <SubscriptionAlertDialougBox
        description="Thank you for showing interest in our program! It is currently available exclusively for our Enterprise users. If your employer is already partnered with us, you may have access through your company's plan—please check with your HR department. We'll notify you as soon as it becomes available for individual users like you."
        courseData={courseData}
        userId={currentUser?.UserId}
        isOpen={openPaymetAlert}
        setOpenPaymetAlert={(item) => setOpenPaymetAlert(item)}
        setIsButtonClicked={(item) => setIsButtonClicked(item)}
      />
    </section>
  );
};

export default Details;
