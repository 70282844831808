import React, { useContext, useEffect, useRef, useState } from "react";
import Sidebar from "./Sidebar";
import "../styles/TalkToStella.css";
import {
  section_bg_img,
  bookmark,
  left_arrow,
  arrow,
  stella,
  info_icon,
  completed_icon,
} from "../assets";
import Popup from "./Modal";
import { useAuth } from "../../../auth";
import { AppContext } from "../../../context";
import DelayedRender from "../../DelayedRenderer";
import jwt_decode from "jwt-decode";
import MediaCardResponse from "../../stella/MediaCardResponse";
import ContactCardResponse from "../../stella/ContactCardResponse";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../pages/Stella.css";
import { courseInternalContentView, addBookmarkEntry } from "../_request";
import "../styles/TalkToStella2.css";
import { useParams, useNavigate } from "react-router-dom";
import SidebarMobile from "./SidebarMobile";

import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Helmet } from "react-helmet";

const modal_data = [
  {
    title: "Talk to Stella",
    desc: "Your Virtual Wellness Coach. Stella can speak 29+ languages and detect underlying issues with 94% accuracy.",
    // desc: "Stella is your virtual mental health assistant",
    // subHead: "There are two different ways in which you can use your journal:",
    // step1:
    //   "1. Standard journal entries, Simply type in whatever you like as free text below.",
    // step2:
    //   "2. Journal entries linked to the program, You might like to use your journal to reflect on things you notice and learn as you go through the program content, using the 'Comment in your journal' button which sits below each block of information.",
  },
];

const TalkToStella = () => {
  const [showModal, setShowModal] = useState(false);
  const [serverAvatar, setServerAvatar] = useState("");
  const [showInput, setShowInput] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const chatHistoryRef = useRef(null);
  const { currentUser, userType, logout } = useAuth();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const [chatHistory, setChatHistory] = useState(["loading"]);
  const [renderAnimation, setRenderAnimation] = useState(false);
  const [showSend, setShowSend] = useState(true);
  const [sessionID, setSessionID] = useState("");
  const stellaAPIKey = process.env.REACT_APP_STELLAAPIKEY;
  const baseURL = "https://odoo.unitedwecare.ca";
  const userID = currentUser?.UserId;
  const myAvatar = currentUser?.ProfileImage;
  const [isLoading, setIsLoading] = useState();
  const [stellaData, setStellaData] = useState();
  const [showAssessmentScore, setShowAssessmentScore] = useState(false);

  const [subLevelId, setSubLevedId] = useState(0);

  const [isBookmarked, setIsBookmarked] = useState(false);
  // const openModal = () => setShowModal(true);
  // const closeModal = () => setShowModal(false);

  const navigate = useNavigate();

  const navigateToURL = (url) => {
    window.location.href = url;
  };

  useEffect(() => {
    const urlParams = window.location.href.split("assessmentID=");

    if (urlParams.length == 2) {
      setSubLevedId(urlParams[1]);
    }
    var body = {
      userId: currentUser?.UserId,
      subLevelId: urlParams[1],
    };
    courseInternalContentView(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setStellaData(resp?.data);
        setIsBookmarked(resp?.data?.bookmarked);
        //console.log("OKAY", resp?.data?.content?.test_score)
        if (resp?.data?.content?.test_score > 0) {
          // console.log("OKAY");
          // console.log("OKAY", resp?.data?.content);
          setShowAssessmentScore(true);
        } else {
          // console.log("Not Okay");
          // console.log("Not Okay", resp?.data?.content);
          setShowAssessmentScore(false);
        }
      }
    });
  }, []);

  //Get session ID for stella chat
  useEffect(() => {
    let isMounted = true; // Flag to check if the component is still mounted

    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": stellaAPIKey,
    };

    const getSessionValue = async () => {
      try {
        setIsLoading(true);

        const response = await fetch(`${baseURL}/im_livechat/get_session`, {
          headers,
        });

        if (!isMounted) {
          // Component has been unmounted, no need to process the response
          return;
        }

        if (!response.ok) {
          // Handle error response
          throw new Error("Error fetching languages");
        }

        const jsonData = await response.json();

        const token = jsonData.data;
        const { sender_id } = jwt_decode(token);
        setSessionID(sender_id);
        sessionStorage.setItem("stella_s_id", sender_id);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getSessionValue();

    return () => {
      // Cleanup function to run when component is unmounted
      isMounted = false; // Set the flag to false when component is unmounted
    };
  }, []);

  //Initial chat API useEffect
  useEffect(() => {
    let payload;
    let key = "";
    let value = "";

    if (window.location.href.includes("stella?")) {
      let locationFragment = window.location.href.split("?")[1];

      //console.log(first);
      if (locationFragment !== undefined) {
        let URLStrip = locationFragment.split("&");
        URLStrip.map((item) => {
          if (!item.includes("face-id")) {
            key = item.split("=")[0];
            value = item.split("=")[1];
          }
        });
      }
    }

    if (
      key == "" ||
      value == "" ||
      (key !== "message" && key !== "button_id" && key !== "notification_id")
    ) {
      payload = {
        jsonrpc: "2.0",
        params: {
          sender_id: sessionID,
          //button_id: 1909,
          // message: stellaData?.content?.code,
          payload: stellaData?.content?.payload,
        },
      };
    } else {
      payload = {
        jsonrpc: "2.0",
        params: {
          sender_id: sessionID,
          [key]: value,
        },
      };
    }

    const fetchConversation = async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
          "X-API-Key": stellaAPIKey,
        };

        const response = await fetch(
          `${baseURL}/stella/conversation/${userID}`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );

        const postResponse = await response.json();
        let data = jwt_decode(postResponse?.data).responses;
        //console.log("postResponse", postResponse);

        setChatHistory([
          { response: "server", data: data, avatar: serverAvatar },
        ]);
      } catch (error) {
        console.log(error);
      }
    };

    if (sessionID !== "" && !showAssessmentScore) {
      setTimeout(async () => {
        fetchConversation();
      }, 1500);
    }
  }, [sessionID, showAssessmentScore]);

  const onEnterClicked = () => {
    // Perform your desired actions here
    const newMessage = {
      response: "self",
      type: "text",
      message: inputValue,
      avatar: currentUser?.ProfileImage,
    };
    setChatHistory([...chatHistory, newMessage]);
    sendMessageToApi("message", inputValue);
    //setIsAllAudiosPlayed(true)
  };

  const handleRenderComplete = (isCompleted) => {
    if (isCompleted) {
      // Perform actions or handle completion status
      scrollChatToBottom();
      setRenderAnimation(false);
    } else {
      scrollChatToBottom();
      setRenderAnimation(true);
    }
  };

  useEffect(() => {
    scrollChatToBottom();
  }, [chatHistory]);

  const scrollChatToBottom = () => {
    if (chatHistoryRef?.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef?.current.scrollHeight;
    }
  };

  const handleEmotion = (emotion) => {
    const newMessage = {
      response: "self",
      type: "text",
      message: emotion.button_text,
      avatar: currentUser?.ProfileImage,
    };
    setChatHistory([...chatHistory, newMessage]);
    sendMessageToApi("button_id", emotion.button_id);
    //sendMessageToApi("button_id", 4060);
  };

  const sendMessageToApi = async (key, value) => {
    setChatHistory((prevChatHistory) => [...prevChatHistory, "loading"]);

    const payload = {
      jsonrpc: "2.0",
      params: {
        sender_id: sessionID,
        [key]: value,
      },
    };

    //console.log("payload", payload)

    setTimeout(async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
          "X-API-Key": stellaAPIKey,
        };

        const response = await fetch(
          `${baseURL}/stella/conversation/${userID}`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );

        const postResponse = await response.json();

        let data = jwt_decode(postResponse?.data).responses;
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory.slice(0, prevChatHistory.length - 1),
          { response: "server", data: data, avatar: serverAvatar },
        ]);
      } catch (error) {
        // Handle any errors
        console.error("Error sending message:", error);
      }
    }, 2000);
  };

  const handlePrevButton = () => {
    if (stellaData?.previous_id?.type === "video") {
      navigate(`/course-internal/module/${stellaData?.previous_id?.id}`);
    } else if (stellaData?.previous_id?.type === "audio") {
      navigate(`/course-internal/module/${stellaData?.previous_id?.id}`);
    } else if (stellaData?.previous_id?.type === "checksheet") {
      navigate(
        `/course-internal/module/daily-practice/${stellaData?.previous_id?.id}`
      );
    } else if (stellaData?.previous_id?.type === "journal") {
      navigate(
        `/course-internal/module/my-journal/${stellaData?.previous_id?.id}`
      );
    } else if (stellaData?.previous_id?.type === "quiz") {
      navigate(`/course-internal/module/quiz/${stellaData?.previous_id?.id}`);
    } else if (stellaData?.previous_id?.type === "html") {
      navigate(
        `/course-internal/module/article/${stellaData?.previous_id?.id}`
      );
    } else if (stellaData?.previous_id?.type === "stella_assessment") {
      navigate(
        `/course-internal/talk-to-stella?assessmentID=${stellaData?.previous_id?.id}`
      );
    } else if (stellaData?.previous_id?.type === "mood_monitor") {
      navigate(
        `/course-internal/module/mood-monitor?id=${stellaData?.previous_id?.id}`
      );
    }
  };

  const handleNextButton = () => {
    if (stellaData?.next_id?.type === "video") {
      navigate(`/course-internal/module/${stellaData?.next_id?.id}`);
    } else if (stellaData?.next_id?.type === "audio") {
      navigate(`/course-internal/module/${stellaData?.next_id?.id}`);
    } else if (stellaData?.next_id?.type === "checksheet") {
      navigate(
        `/course-internal/module/daily-practice/${stellaData?.next_id?.id}`
      );
    } else if (stellaData?.next_id?.type === "journal") {
      navigate(`/course-internal/module/my-journal/${stellaData?.next_id?.id}`);
    } else if (stellaData?.next_id?.type === "quiz") {
      navigate(`/course-internal/module/quiz/${stellaData?.next_id?.id}`);
    } else if (stellaData?.next_id?.type === "html") {
      navigate(`/course-internal/module/article/${stellaData?.next_id?.id}`);
    } else if (stellaData?.next_id?.type === "stella_assessment") {
      navigate(
        `/course-internal/talk-to-stella?assessmentID=${stellaData?.next_id?.id}`
      );
    } else if (stellaData?.next_id?.type === "mood_monitor") {
      navigate(
        `/course-internal/module/mood-monitor?id=${stellaData?.next_id?.id}`
      );
    }
  };

  const handlePrevModuleButton = () => {
    navigate(
      `/course-internal/course-view/${stellaData?.previous_module_id?.id}`
    );
  };

  const handleNextModuleButton = () => {
    navigate(`/course-internal/course-view/${stellaData?.next_module_id?.id}`);
  };

  //console.log("stellaData", stellaData);

  const handleBookmarkClick = () => {
    var bBody = { sub_level_id: subLevelId };
    addBookmarkEntry(bBody).then((resp) => {
      if (resp?.ResponseCode === 200) {
        // setBookmarkDataStatus(resp?.data);
        //console.log(resp?.data?.bookmarked)
        setIsBookmarked(resp?.data?.bookmarked);
        // console.log("resp?.dataresp?.data", resp?.data);
      } else {
        //console.log(resp?.ResponseMessage);
      }
    });
  };

  return (
    <section className="stella" style={{ overflow: "hidden" }}>
      <Helmet>
        <title>Program | Talk to Stella</title>
      </Helmet>
      <section className="stella-sidebar">
        <Sidebar />
      </section>
      <SidebarMobile />
      <section
        className="stella-section talk-to-stella-screen"
        style={showAssessmentScore ? { height: "53rem" } : { height: "53rem" }}
      >
        <div className="stella-bg-img">
          <img src={section_bg_img} alt="" loading="lazy" />
        </div>
        {/* <div className="home-upper-section">
              <div className="home-heading">
                <p className="home-heading1">
                  COURSE • SELF CARE • {courseContent?.preview_video?.duration}{" "}
                  WEEKS
                </p>
              </div>
              <div className="home-sub-heading">
                <p className="home-heading2">{courseContent?.title}</p>
                <div className="home-btns">
                  <img src={home_wp} alt="" loading="lazy" />
                  <img src={home_copy} alt="" loading="lazy" />
                </div>
              </div>
            </div> */}
        <div className="stella-container chat-box">
          <div className="sections-heading">
            {stellaData?.course_name}
            <span>
              {" > "} {stellaData?.content?.name}
            </span>
          </div>
          <div className="white-layout">
            <div className="stella-sections-progress">
              Section {stellaData?.current_level_index + 1}/
              {stellaData?.level_count}
            </div>
            <div className="stella-sections-name d-flex align-items-center justify-content-center gap-2">
              <div className="d-flex gap-1">
                Start questionnaire with STELLA!
                <img
                  src={info_icon}
                  alt=""
                  loading="lazy"
                  className="quiz-info-icon"
                  onClick={() => setShowModal(true)}
                />
                <Popup
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  data={modal_data[0]}
                />
              </div>
              <hr style={{ color: "#E0E0E0" }} />
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleBookmarkClick()}
              >
                {isBookmarked ? (
                  <BookmarkIcon color="#541cf5" />
                ) : (
                  <BookmarkBorderOutlinedIcon />
                )}
                {/* {(!courseContent?.bookmark && (
                  <BookmarkBorderOutlinedIcon
                    className={`modules-bookmark ${
                      isBookmarked ? "bookmarked" : ""
                    }`}
                  />
                )) || <BookmarkIcon color="#541cf5" />} */}
              </div>
            </div>
          </div>
          <hr style={{ color: "#E0E0E0" }} />

          <div
            className={`${
              showAssessmentScore ? "showAssessment" : ""
            } chat-box--content`}
            style={
              !showAssessmentScore
                ? { overflowX: "hidden", background: "#FFF", height: "60vh" }
                : { background: "#FFF", height: "60vh" }
            }
          >
            {/* <div className="chat-content--buttonArea px-3 d-flex align-items-center gap-2 bg-white">
              <div className="chat-box--logo  d-flex align-items-center gap-3">
                <span className="pingContainer">
                  <span className="animate-ping"></span>
                  <span className="pingDot"></span>
                </span>
                
              </div>

              <div className="d-flex align-items-center gap-1">
                <Button
                  sx={{ minWidth: "max-content" }}
                  onClick={() => setIsAudioMode(!isAudioMode)}
                >
                  {isAudioMode ? <HeadphonesIcon /> : <HeadsetOffIcon />}
                </Button>

                <Button
                  variant="outline-secondary type-btn"
                  onClick={handleViewChatBox}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    />
                  </svg>
                </Button>
              </div>
            </div> */}

            {showAssessmentScore ? (
              <div className="Stella--AssessmentScore">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="103"
                  height="102"
                  viewBox="0 0 103 102"
                  fill="none"
                >
                  <path
                    d="M51.5 102C79.6665 102 102.5 79.1665 102.5 51C102.5 22.8335 79.6665 0 51.5 0C23.3335 0 0.5 22.8335 0.5 51C0.5 79.1665 23.3335 102 51.5 102Z"
                    fill="#1CB004"
                  />
                  <path
                    d="M51.5036 96.347C26.4995 96.347 6.15625 76.0039 6.15625 50.9997C6.15625 25.9943 26.4995 5.65234 51.5036 5.65234C76.509 5.65234 96.8522 25.9955 96.8522 50.9997C96.8522 76.0051 76.509 96.347 51.5036 96.347Z"
                    fill="#1CB004"
                    stroke="white"
                    stroke-width="4.52187"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M26.1406 45.52L46.15 69.0955L76.8651 32.9062"
                    fill="#1CB004"
                  />
                  <path
                    d="M26.1406 45.52L46.15 69.0955L76.8651 32.9062"
                    stroke="white"
                    stroke-width="9.37316"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <h3>
                  Your {stellaData?.content?.name} score is{" "}
                  <b>
                    {stellaData?.content?.test_score}/
                    {stellaData?.content?.total_score}
                  </b>
                </h3>
                <p>{stellaData?.content.interpretation_description}</p>
                <button
                  onClick={() => {
                    setShowAssessmentScore(false);
                  }}
                >
                  Retake Assessment
                </button>
              </div>
            ) : (
              <div ref={chatHistoryRef} className="chat-content--chats">
                {/* Binding Conversation API */}
                {currentUser == undefined ? (
                  <div className="response-area">
                    <div className="avatar">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Fstella%2Ffaces%2F1.png?alt=media"
                        alt="My Avatar"
                      />
                    </div>

                    <div className="space-y-5">
                      <div className={`response server-response`}>
                        Hi, nice to meet you. Please login so I can get to know
                        you better.
                      </div>

                      <div className="response-buttons">
                        <div
                          className="button--item"
                          onClick={() => {
                            setShowLloginPopup(true);
                            if (
                              sessionStorage.getItem("Login-Invoked") ==
                              undefined
                            ) {
                              sessionStorage.setItem(
                                "Login-Invoked",
                                "/stella"
                              );
                            }
                          }}
                        >
                          Login
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  chatHistory?.map((chat) => {
                    // console.log("CHAT HERE: ", chat?.data)
                    if (chat?.response === "server") {
                      return (
                        <div key={chat.id} className="response-area">
                          {chat?.data !== undefined && (
                            <div className="avatar">
                              <img
                                src="https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Fstella%2Ffaces%2F1.png?alt=media"
                                alt="My Avatar"
                              />
                              <span></span>
                            </div>
                          )}

                          <div className="space-y-5">
                            {chat?.data?.map((item, i) => (
                              <>
                                <DelayedRender
                                  key={i}
                                  delay={i * 1500}
                                  onComplete={handleRenderComplete}
                                >
                                  <div className={`response server-response`}>
                                    {item.text && item.text}

                                    {/* {item.audio && isAudioMode && (
                                    <audio
                                      className="audio-player"
                                      src={item.audio}
                                      controls={true}
                                      controlsList="nodownload"
                                    />
                                  )} */}

                                    {item.buttons && (
                                      <div className="response-buttons">
                                        {item.buttons.map((btn) => (
                                          <div
                                            key={btn.button_id}
                                            className="button--item"
                                            onClick={() => handleEmotion(btn)}
                                          >
                                            {btn.button_text}
                                          </div>
                                        ))}
                                      </div>
                                    )}

                                    {item?.html && (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: item.html,
                                        }}
                                      ></div>
                                    )}

                                    {item?.content && (
                                      <MediaCardResponse
                                        categoryID={item.content.category_id}
                                        id={item.content.content_id}
                                        title={item.content.title}
                                        type={item.content.type}
                                        description={item.content.description}
                                        thumbnail={item.content.thumb_image}
                                        largeImage={item.content.large_image}
                                        videoURL={item.content.video_url}
                                        audioURL={item.content.audio_url}
                                        video={item.content.video}
                                      />
                                    )}

                                    {item?.user && (
                                      <ContactCardResponse
                                        key={item.user.id}
                                        id={item.user.id}
                                        fee={item.user.fee}
                                        description={item.user.description}
                                        name={`${item.user.name}`}
                                        image={`${item.user.image}`}
                                        profession={`${item.user.category}`}
                                        expInYears={`${item.user.experience}`}
                                        languages={`${item.user.language}`}
                                        url={item.user.expert_detail_page}
                                      />
                                    )}
                                  </div>
                                </DelayedRender>
                                {item.web_url && navigateToURL(item.web_url)}
                              </>
                            ))}
                          </div>
                        </div>
                      );
                    } else if (chat === "loading") {
                      return (
                        <div id="wave-animation">
                          <div id="loading-bubble" className="grey">
                            <div className="spinner">
                              <div className="bounce1"></div>
                              <div className="bounce2"></div>
                              <div className="bounce3"></div>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div>
                          {chat?.type == "text" && (
                            <div className="response-area">
                              <div
                                className={`response ${
                                  chat.response === "server"
                                    ? "server-response"
                                    : "my-response"
                                }`}
                              >
                                {chat.message}
                              </div>

                              {/* <div className="avatar ml-auto">
                          <img src={myAvatar} alt="My Avatar" />
                          <span></span>
                        </div> */}
                            </div>
                          )}

                          {chat.type === "audio" && (
                            <div className="response-area">
                              <div
                                className={`response ${
                                  chat?.response === "server"
                                    ? "server-response"
                                    : "my-response"
                                }`}
                              >
                                <audio
                                  className="audio-player"
                                  src={chat.url}
                                  controls={true}
                                  controlsList="nodownload"
                                />
                              </div>

                              <div className="avatar ml-auto">
                                <img
                                  src="https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Fstella%2Ffaces%2F1.png?alt=media"
                                  alt="My Avatar"
                                />
                                <span></span>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    }
                  })
                )}

                {renderAnimation && (
                  <div id="wave-animation">
                    <div id="loading-bubble" className="grey">
                      <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            className="footer-btn"
            style={
              showAssessmentScore
                ? { marginTop: "60px" }
                : { marginTop: "30px" }
            }
          >
            <div>
              {stellaData?.previous_id?.type && (
                <div className="prev-btn" onClick={handlePrevButton}>
                  <div className="modules-prev-text">Previous</div>
                  <div className="prev-arrow">
                    <img
                      src={left_arrow}
                      alt=""
                      loading="lazy"
                      className="arrow-img"
                    />
                  </div>
                </div>
              )}
              {stellaData?.previous_module_id?.id &&
                !stellaData?.previous_id?.type && (
                  <div className="prev-btn" onClick={handlePrevModuleButton}>
                    <div className="modules-text">Previous Module</div>
                    <div className="prev-arrow">
                      <img
                        src={left_arrow}
                        alt=""
                        loading="lazy"
                        className="arrow-img"
                      />
                    </div>
                  </div>
                )}
            </div>
            <div>
              {stellaData?.next_id?.type && (
                <div className="next-btn" onClick={handleNextButton}>
                  <div className="modules-text">Next</div>
                  <div className="next-arrow">
                    <img
                      src={arrow}
                      alt=""
                      loading="lazy"
                      className="arrow-img"
                    />
                  </div>
                </div>
              )}
              {stellaData?.next_module_id?.id && !stellaData?.next_id?.type && (
                <div className="next-btn" onClick={handleNextModuleButton}>
                  <div className="modules-text">Next Module</div>
                  <div className="next-arrow">
                    <img
                      src={arrow}
                      alt=""
                      loading="lazy"
                      className="arrow-img"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default TalkToStella;
