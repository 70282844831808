import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import "../styles/Library.css";
import { module_bg_img } from "../assets";
import { useNavigate } from "react-router-dom";
import { listOfBookmarksEntry } from "../_request";
import { useAuth } from "../../../auth";
import SidebarMobile from "./SidebarMobile";
import { Helmet } from "react-helmet";

const Library = () => {
  const navigate = useNavigate();
  const [bookmarkData, setBookmarkData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { currentUser } = useAuth();

  useEffect(() => {
    const body = { user_id: currentUser?.UserId };
    listOfBookmarksEntry(body).then((resp) => {
      if (resp.ResponseCode === 200) {
        setBookmarkData(resp.data);
        setIsLoading(false);
        //console.log("resp.data", resp.data);
      }
    });
  }, []);

  const handleClickOnLevel = (item) => {
    if (item.content_type === "video") {
      navigate(`/course-internal/module/${item?.id}`);
    } else if (item.content_type === "audio") {
      navigate(`/course-internal/module/${item?.id}`);
    } else if (item.content_type === "checksheet") {
      navigate(`/course-internal/module/daily-practice/${item?.id}`);
    } else if (item.content_type === "journal") {
      navigate(`/course-internal/module/my-journal/${item?.id}`);
    } else if (item.content_type === "quiz") {
      navigate(`/course-internal/module/quiz/${item?.id}`);
    } else if (item.content_type === "stella_assessment") {
      navigate(`/course-internal/talk-to-stella?assessmentID=${item?.id}`);
    } else if (item.content_type === "mood_monitor") {
      navigate(`/course-internal/module/mood-monitor?id=${item?.id}`);
    }
  };

  return (
    <section className="library">
      <Helmet>
        <title>Program | Library</title>
      </Helmet>
      {isLoading ? (
        <div
          className="d-flex align-items-center justify-content-center mx-auto"
          style={{ minHeight: "100vh" }}
        >
          <img alt="" src="/assets/img/kareify/loader.gif" />
        </div>
      ) : (
        <>
          <section className="library-sidebar">
            <Sidebar />
          </section>
          <SidebarMobile />
          <section className="library-section">
            {/* <div className="library-sections-heading">
          Space from Depression and Anxiety
          <span className="journal-sections-heading-span">
            {" > "} Getting Started
          </span>
        </div> */}
            <div className="library-bg-img">
              <img src={module_bg_img} alt="" loading="lazy" />
            </div>
            <div className="library-container">
              <div className="library-mob">
                <h2>My Library</h2>
              </div>

              <div className="library-name">My Library</div>
              <hr style={{ color: "#E0E0E0" }} />
              <div className="library-sections-items">
                {bookmarkData?.bookmarked_data?.map((item, i) => (
                  <div
                    className="library-sections-item"
                    onClick={() => {
                      if (item?.type === "course") {
                        navigate(`/course-internal/${item?.id}/${item?.name}`);
                      } else {
                        handleClickOnLevel(item);
                      }
                    }}
                  >
                    <div className="items">
                      {(item?.content_type === "video" && (
                        <VideocamOutlinedIcon />
                      )) ||
                        (item?.content_type === "audio" && (
                          <HeadphonesOutlinedIcon />
                        )) ||
                        (item?.content_type === "mood monitor" && (
                          <SentimentSatisfiedOutlinedIcon />
                        )) ||
                        (item?.content_type === "checkSheet" && (
                          <MenuBookIcon />
                        )) || <DescriptionOutlinedIcon />}

                      <div className="modules-text">{item.name}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </>
      )}
    </section>
  );
};

export default Library;
