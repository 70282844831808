import React, { useEffect, useState } from 'react'
import { Badge } from 'react-bootstrap'
import { getDashboardAppointmentsHistory_WebService } from '../core/_request';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const DashboardAppointmentHistoryTab = () => {

    const [appointmentData, setAppointmentData] = useState();
    useEffect(() => {
        getDashboardAppointmentsHistory_WebService().then(resp => {
            // console.log("resp Data", resp.data)
            setAppointmentData(resp.data);
        });
        // console.log("appointmentData", appointmentData)
    }, [])

    return (
        <div>
            <Helmet>
                <title>
                    My Appointments - United We Care
                </title>
            </Helmet>
            <div className='d-none d-md-flex align-items-center gap-3 py-4 px-5' style={{ boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25", borderBottom: "2px solid #FF9C00" }}><img src='/assets/img/vector(11).png' alt='' />
                <p className='font-w500'>Appointment History</p>
            </div>
            {appointmentData?.map((data) => (
                <div className='row justify-content-between align-items-center px-md-5 py-3 gap-3 gap-md-0 mx-1' style={{ boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)" }}>
                    <div className='d-flex align-items-center gap-4 col-md-5 justify-content-between'>
                        <div className='d-flex align-items-center gap-3'>
                            <img src={data?.ProfileImage} height="64" width={64} className='rounded-5' alt='' />
                            <p>
                                {data?.Name}
                            </p>
                        </div>
                        <Badge bg='warning' text='dark' className='py-1 d-block d-md-none'>
                            {data?.Status}
                        </Badge>
                    </div>
                    <p className='col-md-5 d-flex align-items-center justify-content-between'>
                        {data?.AppointmentDate}
                        <span>
                            {data?.App_StartTime} - {data?.App_EndTime}
                        </span>
                        <img src='/assets/img/video-icon.png' alt='' className='d-block d-md-none'/>
                    </p>
                    <div className='d-flex align-items-center gap-4 col-md-2'>
                        <Badge bg='warning' text='dark' className='py-1 d-none d-md-block'>
                            {data?.Status}
                        </Badge>
                        <img src='/assets/img/video-icon.png' alt='' className='d-none d-md-block'/>
                    </div>
                </div>
            ))
            }
            {/* <div className='d-flex justify-content-between px-5 py-3' style={{ boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)" }}>
                <div className='d-flex align-items-center gap-4'><img src='/assets/img/profile.png' alt='' /> <p>Priadarshini Pathak</p></div>
                <div className='d-flex align-items-center gap-4'><p>23 Jun’22</p> <p>12PM - 1:00 PM</p></div>
                <div className='d-flex align-items-center gap-4'>
                    <Badge bg='warning' text='dark' className='py-1'>Upcoming</Badge> <img src='/assets/img/video-icon.png' alt='' />
                </div>
            </div>
            <div className='d-flex justify-content-between px-5 py-3' style={{ boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)" }}>
                <div className='d-flex align-items-center gap-4'><img src='/assets/img/profile.png' alt='' /> <p>Priadarshini Pathak</p></div>
                <div className='d-flex align-items-center gap-4'><p>23 Jun’22</p> <p>12PM - 1:00 PM</p></div>
                <div className='d-flex align-items-center gap-4'>
                    <Badge bg='warning' text='dark' className='py-1'>Upcoming</Badge> <img src='/assets/img/video-icon.png' alt='' />
                </div>
            </div> */}
        </div>
    )
}

export default DashboardAppointmentHistoryTab