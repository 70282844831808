import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import "../styles/Quiz.css";
import {
  bookmark,
  home_wp,
  home_copy,
  module_bg_img,
  next_que_arrow,
  left_arrow,
  arrow,
  correct_ans,
  info_icon,
  wrong_ans,
} from "../assets";
import CompleteQuiz from "./CompleteQuiz";
import { courseInternalContentView, submitQuizAnswers } from "../_request";
import Popup from "./Modal";
import { useNavigate, useParams } from "react-router-dom/dist";
import SidebarMobile from "./SidebarMobile";
import { useAuth } from "../../../auth";

import { addBookmarkEntry } from "../_request";

import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Helmet } from "react-helmet";

const modal_data = [
  {
    title: "Quiz Time",
    desc: "Answer these questions to understand how much of the information disseminated within the program stuck with you.",
  },
];

const Quiz = () => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(true);
  const [quizData, setQuizData] = useState();
  const [currentCustomDataIndex, setCurrentCustomDataIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [quizAnswers, setQuizAnswers] = useState([]);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { id } = useParams();
  const { currentUser } = useAuth();

  const [subLevelId, setSubLevelId] = useState(id);

  const currentData =
    quizData?.content?.quiz_questions &&
    quizData?.content?.quiz_questions[currentCustomDataIndex];

  useEffect(() => {
    setSubLevelId(id);
  }, [id]);

  useEffect(() => {
    const body = {
      userId: currentUser?.UserId,
      subLevelId: id,
    };
    courseInternalContentView(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setQuizData(resp?.data);
        setIsBookmarked(resp?.data?.bookmarked);
        setIsLoading(false);
      } else {
        console.error(resp?.ResponseMessage);
      }
    });
  }, [subLevelId, id]);

  // console.log("currentDatacurrentData", quizData?.content?.quiz_questions);

  const handleNextModule = () => {
    //debugger;
    // Check if there is a "next" module available
    if (quizData?.content.quiz_questions && currentData) {
      //&& currentData.next !== null
      setCurrentCustomDataIndex(currentCustomDataIndex + 1);
    }
  };

  const handleAnswerSelection = (answer, question) => {
    setSelectedAnswer(answer);

    setQuizAnswers((prevAnswers) => {
      const existingAnswerIndex = prevAnswers.findIndex(
        (item) => item.question_id === question
      );

      if (existingAnswerIndex !== -1) {
        // If the question_id already exists, update the existing object
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingAnswerIndex] = {
          question_id: question,
          response: answer,
        };
        return updatedAnswers;
      } else {
        // If the question_id does not exist, add a new object
        return [
          ...prevAnswers,
          {
            question_id: question,
            response: answer,
          },
        ];
      }
    });

    if (currentData?.answer === answer) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
  };

  const iconHandler = (answer) => {
    return currentData?.content?.quiz_questions.answer === answer ? (
      <img src={correct_ans} alt="" loading="lazy" className="correct-ans" />
    ) : (
      <img src={wrong_ans} alt="" loading="lazy" className="wrong-ans" />
    );
  };

  const clickHandler = (answer, question) => {
    handleAnswerSelection(answer, question);
    iconHandler(answer);
  };

  const handleNextQuestionClick = () => {
    if (quizData?.content.quiz_questions && currentData) {
      //&& currentData.next !== null
      setCurrentCustomDataIndex(currentCustomDataIndex + 1);
      setIsCorrect(null);
      setSelectedAnswer(null);
    }
  };

  const handleFinishQuiz = () => {
    let body = {
      quiz_data_id: quizData?.content?.quiz_data_id,
      responses: quizAnswers,
    };
    submitQuizAnswers(body).then((resp) => {
      console.log("resp", resp);
      if (resp?.ResponseCode == 200) {
        navigate(`/course-internal/module/quiz-completed/${subLevelId}`);
      }
    });
  };

  const handlePrevButton = () => {
    if (quizData?.previous_id?.type === "video") {
      navigate(`/course-internal/module/${quizData?.previous_id?.id}`);
    } else if (quizData?.previous_id?.type === "audio") {
      navigate(`/course-internal/module/${quizData?.previous_id?.id}`);
    } else if (quizData?.previous_id?.type === "checksheet") {
      navigate(
        `/course-internal/module/daily-practice/${quizData?.previous_id?.id}`
      );
    } else if (quizData?.previous_id?.type === "journal") {
      navigate(
        `/course-internal/module/my-journal/${quizData?.previous_id?.id}`
      );
    } else if (quizData?.previous_id?.type === "quiz") {
      navigate(`/course-internal/module/quiz/${quizData?.previous_id?.id}`);
      setSubLevelId(quizData?.previous_id?.id);
    } else if (quizData?.previous_id?.type === "html") {
      navigate(`/course-internal/module/article/${quizData?.previous_id?.id}`);
    } else if (quizData?.previous_id?.type === "stella_assessment") {
      navigate(
        `/course-internal/talk-to-stella?assessmentID=${quizData?.previous_id?.id}`
      );
    } else if (quizData?.previous_id?.type === "mood_monitor") {
      navigate(
        `/course-internal/module/mood-monitor?id=${quizData?.previous_id?.id}`
      );
    }
  };

  const handleNextButton = () => {
    if (quizData?.next_id?.type === "video") {
      navigate(`/course-internal/module/${quizData?.next_id?.id}`);
    } else if (quizData?.next_id?.type === "audio") {
      navigate(`/course-internal/module/${quizData?.next_id?.id}`);
    } else if (quizData?.next_id?.type === "checksheet") {
      navigate(
        `/course-internal/module/daily-practice/${quizData?.next_id?.id}`
      );
    } else if (quizData?.next_id?.type === "journal") {
      navigate(`/course-internal/module/my-journal/${quizData?.next_id?.id}`);
    } else if (quizData?.next_id?.type === "quiz") {
      navigate(`/course-internal/module/quiz/${quizData?.next_id?.id}`);
      setSubLevelId(quizData?.next_id?.id);
    } else if (quizData?.next_id?.type === "html") {
      navigate(`/course-internal/module/article/${quizData?.next_id?.id}`);
    } else if (quizData?.next_id?.type === "stella_assessment") {
      navigate(
        `/course-internal/talk-to-stella?assessmentID=${quizData?.next_id?.id}`
      );
    } else if (quizData?.next_id?.type === "mood_monitor") {
      navigate(
        `/course-internal/module/mood-monitor?id=${quizData?.next_id?.id}`
      );
    }
  };

  const handlePrevModuleButton = () => {
    navigate(
      `/course-internal/course-view/${quizData?.previous_module_id?.id}`
    );
  };

  const handleNextModuleButton = () => {
    navigate(`/course-internal/course-view/${quizData?.next_module_id?.id}`);
  };

  const handleBookmarkClick = () => {
    const bBody = { sub_level_id: subLevelId };
    addBookmarkEntry(bBody).then((resp) => {
      if (resp?.ResponseCode === 200) {
        // setBookmarkDataStatus(resp?.data);
        //console.log(resp?.data?.bookmarked)
        setIsBookmarked(resp?.data?.bookmarked);
        // console.log("resp?.dataresp?.data", resp?.data);
      } else {
        //console.log(resp?.ResponseMessage);
      }
    });
  };

  // console.log("quizData kkk", quizData);

  return (
    <section className="quiz">
      <Helmet>
        <title>Program ! Quiz</title>
      </Helmet>
      {isLoading ? (
        <div
          className="d-flex align-items-center justify-content-center mx-auto"
          style={{ minHeight: "100vh" }}
        >
          <img alt="" src="/assets/img/kareify/loader.gif" />
        </div>
      ) : (
        <>
          <section className="quiz-sidebar">
            <Sidebar />
          </section>
          <SidebarMobile />
          <section className="quiz-section">
            <div>
              <div className="quiz-bg-img">
                <img src={module_bg_img} alt="" loading="lazy" />
              </div>
              <div className="quiz-container">
                {/* <div className="home-upper-section">
              <div className="home-heading">
                <p className="home-heading1">
                  COURSE • SELF CARE • {courseContent?.preview_video?.duration}{" "}
                  WEEKS
                </p>
              </div>
              <div className="home-sub-heading">
                <p className="home-heading2">{courseContent?.title}</p>
                <div className="home-btns">
                  <img src={home_wp} alt="" loading="lazy" />
                  <img src={home_copy} alt="" loading="lazy" />
                </div>
              </div>
            </div> */}
                {/* <h6 className="sections--title">{quizData?.course_name}</h6> */}
                <div className="sections-heading">
                  {quizData?.course_name}
                  <span>
                    {" > "}
                    {quizData?.content?.name}
                  </span>
                </div>
                <div className="sections-progress">
                  Section {quizData?.current_level_index + 1}/
                  {quizData?.level_count}
                </div>
                <div className="quiz-sections-name">
                  <div style={{ width: "calc(100% - 20px)" }}>
                    {quizData?.content?.name}
                  </div>
                  {/* <hr style={{ color: "#E0E0E0" }} /> */}
                  <div style={{display:"flex",alignItems:"center",justifyContent:"flex-start",gap:"8px"}}>
                    <img
                      src={info_icon}
                      alt=""
                      loading="lazy"
                      className="quiz-info-icon"
                      onClick={() => setShowModal(true)}
                    />
                    <Popup
                      show={showModal}
                      onHide={() => setShowModal(false)}
                      data={modal_data[0]}
                    />
                  <div
                    className="quiz-save"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleBookmarkClick()}
                  >
                    {isBookmarked ? (
                      <BookmarkIcon color="#541cf5" />
                    ) : (
                      <BookmarkBorderOutlinedIcon />
                    )}
                  </div>
                  </div>
                </div>
                <hr style={{ color: "#E0E0E0" }} />
                <div className="d-flex flex-column justify-content-center align-content-center">
                  <div className="quiz-section-desc">
                    {/* <div className="sections-desc"> */}
                    {quizData?.content?.description}
                    {/* </div> */}
                    {/* This quiz aims to help you to learn more about the true
                    nature of depression and anxiety. */}
                  </div>
                  <div className="quiz-question-numbers">
                    Question {currentCustomDataIndex + 1} /{" "}
                    {quizData?.content?.quiz_questions?.length}
                  </div>
                  <div className="quiz-question-container">
                    {/* {data.map((quiz, i) => ( */}
                    <div>
                      <div className="quiz-que">
                        {/* <div> */}
                        {currentCustomDataIndex + 1}
                        {". "}
                        {/* </div> */}

                        <div>{currentData?.name}</div>
                      </div>
                      <div className="quiz-btns">
                        <button
                          className="quiz-true-btn"
                          onClick={() => clickHandler("true", currentData?.id)}
                          style={{
                            backgroundColor:
                              isCorrect === null
                                ? "#A68DEF"
                                : selectedAnswer === "true" && !isCorrect
                                ? "#cc0000"
                                : isCorrect && selectedAnswer === "true"
                                ? "#34A001"
                                : "#A68DEF",
                            marginBottom: "25px",
                          }}
                        >
                          True
                          {selectedAnswer === "true" && isCorrect !== null && (
                            <img
                              alt=""
                              className="quiz-btn-icons"
                              src={
                                isCorrect === null
                                  ? null
                                  : selectedAnswer === "true" && !isCorrect
                                  ? wrong_ans
                                  : isCorrect && selectedAnswer === "true"
                                  ? correct_ans
                                  : null
                              }
                            />
                          )}
                        </button>
                        <button
                          className="quiz-false-btn"
                          onClick={() => clickHandler("false", currentData?.id)}
                          style={{
                            backgroundColor:
                              isCorrect === null
                                ? "#A68DEF"
                                : selectedAnswer === "false" && !isCorrect
                                ? "#cc0000"
                                : isCorrect && selectedAnswer === "false"
                                ? "#34A001"
                                : "#A68DEF",
                          }}
                        >
                          False
                          {selectedAnswer === "false" && isCorrect !== null && (
                            <img
                              alt=""
                              className="quiz-btn-icons"
                              src={
                                isCorrect === null
                                  ? null
                                  : selectedAnswer === "false" && !isCorrect
                                  ? wrong_ans
                                  : isCorrect && selectedAnswer === "false"
                                  ? correct_ans
                                  : null
                              }
                            />
                          )}
                        </button>
                      </div>
                      {console.log("currentData", currentData)}
                      {selectedAnswer !== null && (
                        <div className="quiz-explanation">
                          {isCorrect ? (
                            <p> {currentData?.summary}</p>
                          ) : (
                            <p> {currentData?.summary}</p>
                          )}
                        </div>
                      )}
                    </div>
                    {/* ))} */}

                    {(currentCustomDataIndex + 1 !==
                      quizData?.content?.quiz_questions?.length && (
                      <button
                        disabled={selectedAnswer == null ? true : false}
                        className="quiz-next-btn"
                        onClick={() => handleNextQuestionClick()}
                      >
                        <div>Next Question</div>
                        <img
                          src={next_que_arrow}
                          className="quiz-next-arrow"
                          alt=""
                          loading="lazy"
                        />
                      </button>
                    )) || (
                      <button
                        disabled={selectedAnswer == null ? true : false}
                        className="quiz-next-btn"
                        onClick={() => handleFinishQuiz()}
                      >
                        <div>Finish Quiz</div>
                        <img
                          src={next_que_arrow}
                          className="quiz-next-arrow"
                          alt=""
                          loading="lazy"
                        />
                      </button>
                    )}
                  </div>
                </div>

                <div className="quiz-footer-btns">
                  <div>
                    {quizData?.previous_id.type && (
                      <div className="prev-btn" onClick={handlePrevButton}>
                        <div className="modules-prev-text">Previous</div>
                        <div className="prev-arrow">
                          <img
                            src={left_arrow}
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                          />
                        </div>
                      </div>
                    )}
                    {quizData?.previous_module_id?.id &&
                      !quizData?.previous_id?.type && (
                        <div
                          className="prev-btn"
                          onClick={handlePrevModuleButton}
                        >
                          <div className="modules-text">Previous Module</div>
                          <div className="prev-arrow">
                            <img
                              src={left_arrow}
                              alt=""
                              loading="lazy"
                              className="arrow-img"
                            />
                          </div>
                        </div>
                      )}
                  </div>

                  <div>
                    {quizData?.next_id.type && (
                      <div className="next-btn" onClick={handleNextButton}>
                        <div className="modules-text">Next</div>
                        <div className="next-arrow">
                          <img
                            src={arrow}
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                          />
                        </div>
                      </div>
                    )}
                    {quizData?.next_module_id?.id &&
                      !quizData?.next_id?.type && (
                        <div
                          className="next-btn"
                          onClick={handleNextModuleButton}
                        >
                          <div className="modules-text">Next Module</div>
                          <div className="next-arrow">
                            <img
                              src={arrow}
                              alt=""
                              loading="lazy"
                              className="arrow-img"
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </section>
  );
};

export default Quiz;
