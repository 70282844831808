import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import TalkToStella from "../components/courseInternalJourney/components/TalkToStella";
import Journal from "../components/courseInternalJourney/components/Journal";
import DailyPractice from "../components/courseInternalJourney/components/DailyPractice";
import CompleteQuiz from "../components/courseInternalJourney/components/CompleteQuiz";
import MoodMonitor from "../components/courseInternalJourney/components/MoodMonitor";
import CourseInternal from "../components/courseInternalJourney/CourseInternal";
import Modules from "../components/courseInternalJourney/components/Modules";
import Section from "../components/courseInternalJourney/components/Section";
import Quiz from "../components/courseInternalJourney/components/Quiz";
import Library from "../components/courseInternalJourney/components/Library";
import ModuleArticle from "../components/courseInternalJourney/components/ModuleArticle";

const CourseInternalRouting = () => {
  return (
    <Routes>
      <Route path=":id/:urlTitle" element={<CourseInternal />} />
      <Route path="course-view/:id" element={<Modules />} />
      <Route path="module/:id" element={<Section />} />
      <Route path="module/mood-monitor" element={<MoodMonitor />} />
      <Route path="module/quiz/:id" element={<Quiz />} />
      <Route path="module/article/:id" element={<ModuleArticle />} />
      <Route path="module/quiz-completed/:id" element={<CompleteQuiz />} />
      <Route path="library" element={<Library />} />
      <Route path="module/daily-practice/:id" element={<DailyPractice />} />
      <Route path="module/my-journal/:id" element={<Journal />} />
      <Route path="talk-to-stella" element={<TalkToStella />} />
      <Route path="course-preview/:id/:urlTitle" element={<CourseInternal />} />
    </Routes>
  );
};

export default CourseInternalRouting;
