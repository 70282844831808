import React, { useEffect, useState } from "react";
import MoodBar from "./MoodBar";
import ProgressBar from "@ramonak/react-progress-bar";
import "../styles/Home.css";
import Sidebar from "./Sidebar";
import {
  home_user,
  home_wp,
  home_copy,
  icon_stroke,
  card,
  module1,
  keep_going,
  completed_icon,
} from "../assets";
import { addBookmarkEntry, getCourseContent } from "../_request";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import { courseData } from "../../course/core/models";
import SidebarMobile from "./SidebarMobile";
import BookmarkBorderRoundedIcon from "@mui/icons-material/BookmarkBorderRounded";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";

import TurnedInRoundedIcon from "@mui/icons-material/TurnedInRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Helmet } from "react-helmet";

const Home = () => {
  const [courseContent, setCourseContent] = useState();
  const [totalModule, setTotalModule] = useState();
  const [viewedModule, setViewedModule] = useState();
  const [percentageViewedContent, setPercentageViewedContent] = useState();
  const [bookmarkDataStatus, setBookmarkDataStatus] = useState();
  const [isBookmarked, setIsBookmarked] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { id, urlTitle } = useParams();

  useEffect(() => {
    var body = { course_id: id };
    getCourseContent(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setCourseContent(resp?.data);
        //console.log("HOME", resp?.data);
        setIsLoading(false);
      } else {
        //console.log(resp?.ResponseMessage);
      }
    });
  }, [bookmarkDataStatus]);

  const handleClickOnModule = (item) => {
    navigate(`/course-internal/course-view/${item.id}`);
  };

  useEffect(() => {
    let total = 0;
    let viewed = 0;

    courseContent?.levels?.forEach((level) => {
      total += level?.total;
      viewed += level?.viewed;
    });

    setTotalModule(total);
    setViewedModule(viewed);

    const percentage = Math.round((viewed / total) * 100);
    // console.log(`Percentage viewed: ${percentage}%`);

    setPercentageViewedContent(percentage);
  }, [courseContent]);

  // Function to call when attempting to open WhatsApp
  const sendMessageToReactNative = (message) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(message);
    }
  };

  const onClickWhatsAppOnMobileWeb = () => {
    const whatsappURL = encodeURI(
      `whatsapp://send?text=${courseContent?.course_url}`
    );

    //console.log("whatsappURL", whatsappURL)

    sendMessageToReactNative(
      JSON.stringify({ action: "openWhatsApp", url: whatsappURL })
    );
  };

  const onClickWhatsAppOnWeb = () => {
    const whatsappURL = encodeURI(
      `https://web.whatsapp.com/send?text=${courseContent?.course_url}`
    );

    // const a = document.createElement("a");
    // a.href = whatsappURL;
    // a.target = "_blank";
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);

    sendMessageToReactNative(
      JSON.stringify({ action: "openWhatsApp", url: whatsappURL })
    );
  };

  const handleBookmarkClick = () => {
    var body = { course_data_id: courseContent?.course_id };
    addBookmarkEntry(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setBookmarkDataStatus(resp?.data);
        // console.log("resp?.dataresp?.data", resp?.data);
      } else {
        //console.log(resp?.ResponseMessage);
      }
    });
  };

  const moodAddCallback = () => {
    //console.log("Mood Added!");
  };

  return (
    <section style={{ display: "flex", overflowX: "hidden", width: "100%" }}>
      <Helmet>
        <title>Program | Home</title>
      </Helmet>
      {isLoading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            height: "100vh",
            width: "100vw",
            background: "#FFF",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: "1000",
          }}
        >
          <img alt="" src="/assets/img/kareify/loader.gif" width={250} />
        </div>
      ) : (
        <>
          <section className="home-sidebar">
            <Sidebar id={id} urlTitle={urlTitle} />
          </section>
          <SidebarMobile id={id} urlTitle={urlTitle} />
          <section className="home-section">
            <div className="home-container">
              <div className="home-upper-section">
                <div className="home-heading">
                  <p className="home-heading1">
                    PROGRAM • {courseContent?.duration}
                  </p>
                </div>
                <div className="home-sub-heading">
                  <p className="home-heading2">{courseContent?.title}</p>
                  <div className="home-btns">
                    <img
                      src={home_wp}
                      alt=""
                      loading="lazy"
                      className="d-none d-md-block"
                      onClick={onClickWhatsAppOnWeb}
                    />
                    <img
                      src={home_wp}
                      alt=""
                      loading="lazy"
                      className="d-block d-md-none"
                      onClick={onClickWhatsAppOnMobileWeb}
                    />
                    {/* <div onClick={onClickWhatsAppOnWeb} className="d-none d-md-block rounded-circle p-1 d-flex align-items-center justify-content-center" style={{ background: "linear-gradient(180deg, rgba(216, 216, 216, 0.38) 0%, rgba(202, 184, 255, 0.38) 100%)" }}>
                </div>
                <div onClick={onClickWhatsAppOnMobileWeb} className="d-block d-md-none rounded-circle p-1 d-flex align-items-center justify-content-center" style={{ background: "linear-gradient(180deg, rgba(216, 216, 216, 0.38) 0%, rgba(202, 184, 255, 0.38) 100%)" }}>
                </div> */}
                    <div
                      onClick={handleBookmarkClick}
                      className="rounded-circle p-1 d-flex align-items-center justify-content-center"
                      style={{ cursor: "pointer" }}
                    >
                      {courseContent?.bookmarked ? (
                        <BookmarkIcon />
                      ) : (
                        <BookmarkBorderOutlinedIcon />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Mood Monitor */}
            <section className="home--mood__monitor">
              <MoodBar
                moodCallback={moodAddCallback}
                courseID={courseContent?.course_id}
              />
            </section>

            {/* your progress */}
            <section className="progress-section">
              <div className="progress-heading">Your Progress</div>
              <div className="progress-container">
                <div className="progress-sub">Modules</div>
                <div className="progress-text">
                  {percentageViewedContent}% completed
                </div>
                <div className="progress-bar">
                  <ProgressBar
                    completed={percentageViewedContent}
                    bgColor="#51AC10"
                    customLabel=" "
                  />
                </div>
              </div>
            </section>

            <section className="video-section">
              <iframe
                src={courseContent?.preview_video?.video?.player_embed_url}
                title={courseContent?.title}
              ></iframe>
              {/* <ReactPlayer
                url={courseContent?.preview_video?.video?.player_embed_url}
                controls={true}
              ></ReactPlayer> */}
            </section>

            {/* module section */}
            <section className="modulesss">
              <div>
                <div className="module-main-heading">All Modules</div>
                <div className="module-sub">
                  {/* {viewedModule} of {totalModule} sections completed */}
                  {viewedModule == 0
                    ? `${totalModule} sections`
                    : `${viewedModule} of ${totalModule} sections completed`}
                </div>
                <div className="module-container">
                  {courseContent?.levels?.map((item, i) => (
                    <div
                      className="module--item"
                      onClick={() => handleClickOnModule(item)}
                    >
                      <img
                        src={item.image_url}
                        className="module-img"
                        alt=""
                        loading="lazy"
                      />
                      <div className="module-text d-flex justify-content-between align-items-center gap-1 py-2">
                        <div>
                          <p className="module--heading">{item.name} </p>
                          <p className="module-sub-heading mt-1">
                            {item?.viewed == 0
                              ? `${item?.total} sections`
                              : `${item?.viewed} of ${item?.total} sections viewed`}
                          </p>
                          <div
                            className="d-flex align-items-center gap-2 text-white mt-3"
                            style={{ fontSize: "0.95rem" }}
                          >
                            {item?.viewed == 0
                              ? "Start"
                              : item?.viewed == item?.total
                              ? "Completed"
                              : "Continue"}
                            {item?.viewed !== item?.total && (
                              <KeyboardArrowRightIcon
                                style={{ fontSize: "1.2rem", marginTop: "3px" }}
                              />
                            )}
                          </div>
                        </div>
                        <div>
                          {item.viewed > 0 && item.viewed < item.total && (
                            <img
                              src={keep_going}
                              alt=""
                              width={40}
                              height={40}
                              style={{ minWidth: "40px", minHeight: "40px" }}
                            />
                          )}
                        </div>
                        {item.viewed == item.total && (
                          <img
                            src={completed_icon}
                            alt=""
                            style={{
                              padding: "6px",
                              minWidth: "40px",
                              minHeight: "40px",
                            }}
                            width={40}
                            height={40}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </section>
        </>
      )}
    </section>
  );
};

export default Home;
