import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import "../styles/Journal.css";
import {
  section_bg_img,
  bookmark,
  left_arrow,
  arrow,
  dlt_icon,
  edit_icon,
  see_more_icon,
  see_less_icon,
  calendar_icon,
  info_icon,
} from "../assets";
import Popup from "./Modal";
import {
  addEntrytoJournal,
  courseInternalContentView,
  deleteEntrytoJournal,
  editEntrytoJournal,
  getCourseContent,
} from "../_request";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../auth";
import SidebarMobile from "./SidebarMobile";

import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";

import { addBookmarkEntry } from "../_request";
import { Alert, Snackbar } from "@mui/material";
import { Helmet } from "react-helmet";
const modal_data = [
  {
    title: "Your personal journal",
    desc: "Dive into your virtual journal space where only your thoughts matter. Type in whatever's on your mind based on the brief provided below.",
    // subHead: "There are two different ways in which you can use your journal:",
    // step1:
    //   "1. Standard journal entries, Simply type in whatever you like as free text below.",
    // step2:
    //   "2. Journal entries linked to the program, You might like to use your journal to reflect on things you notice and learn as you go through the program content, using the 'Comment in your journal' button which sits below each block of information.",
  },
];

const prev_entries = [
  {
    date: "6th Sep",
    title: "Where I want to be in life",
    desc: "Feel better about myself Improve my relationships Do a better job of setting boundaries, and facing confrontation in a healthy way.",
  },
  {
    date: "6th Sep",
    title: "Where I want to be in life",
    desc: "Feel better about myself Improve my relationships Do a better job of setting boundaries, and facing confrontation in a healthy way.",
  },
  {
    date: "6th Sep",
    title: "Where I want to be in life",
    desc: "Feel better about myself Improve my relationships Do a better job of setting boundaries, and facing confrontation in a healthy way.",
  },
  {
    date: "6th Sep",
    title: "How do I improve in life and do good",
    desc: "Feel better about myself Improve my relationships Do a better job of setting boundaries, and facing confrontation in a healthy way.",
  },
  {
    date: "6th Sep",
    title: "How do I improve in life and do good",
    desc: "Feel better about myself Improve my relationships Do a better job of setting boundaries, and facing confrontation in a healthy way.",
  },
  {
    date: "6th Sep",
    title: "Where I want to be in life",
    desc: "Feel better about myself Improve my relationships Do a better job of setting boundaries, and facing confrontation in a healthy way.",
  },
  {
    date: "6th Sep",
    title: "How do I improve in life and do good",
    desc: "Feel better about myself Improve my relationships Do a better job of setting boundaries, and facing confrontation in a healthy way.",
  },
  {
    date: "6th Sep",
    title: "How do I improve in life and do good",
    desc: "Feel better about myself Improve my relationships Do a better job of setting boundaries, and facing confrontation in a healthy way.",
  },
];

const Journal = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [journalData, setJournalData] = useState();
  const [selected, setSelected] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [textValue, setTextValue] = useState("");
  const [addedJournalData, setAddedJournalData] = useState();
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    const body = {
      subLevelId: id,
      userId: currentUser?.UserId,
    };
    courseInternalContentView(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setJournalData(resp?.data);
        setIsBookmarked(resp?.data?.bookmarked);
        setIsLoading(false);
      } else {
        console.log(resp?.ResponseMessage);
      }
    });
  }, [addedJournalData]);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleDescChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmitJournal = () => {
    const body = {
      journal_data_id: journalData?.content?.journal_data_id,
      title: title,
      description: description,
    };
    if (title.trim() === "") {
      setSnackbarMessage("Title is Empty !");
    } else if (description.trim() === "") {
      setSnackbarMessage("Description is Empty !");
    } else {
      addEntrytoJournal(body).then((resp) => {
        if (resp?.ResponseCode === 200) {
          //alert();
          setOpenSnackbar(true);
          setSnackbarMessage(resp?.data?.message);

          setTitle("");
          setDescription("");
          setAddedJournalData(resp?.data);
        }
      });
    }
  };

  const handleEditJournal = (item) => {
    setIsEditing(!isEditing);
    const body = {
      journal_data_id: 3,
      entry_id: item.id,
      title: item.title,
      description: item.description,
    };
    editEntrytoJournal(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        console.log(resp.ResponseMessage);
      }
    });
  };

  const handleDeleteJournal = (item) => {
    const body = {
      journal_data_id: 3,
      entry_id: item.id,
    };
    deleteEntrytoJournal(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        console.log(resp.ResponseMessage);
      }
    });
  };

  const handleSaveClick = () => {
    // var body = {
    //   checksheet_data_id: journalData?.content?.checksheet_data_id,
    //   point_name: textValue,
    // };
    // addPointToCheckSheet(body).then((resp) => {
    //   if (resp.ResponseCode === 200) {
    //     console.log(resp.ResponseMessage);
    //   }
    // });
    setIsEditing(false);
    setTextValue("");
  };

  const handlePrevButton = () => {
    if (journalData?.previous_id?.type === "video") {
      navigate(`/course-internal/module/${journalData?.previous_id?.id}`);
    } else if (journalData?.previous_id?.type === "audio") {
      navigate(`/course-internal/module/${journalData?.previous_id?.id}`);
    } else if (journalData?.previous_id?.type === "checksheet") {
      navigate(
        `/course-internal/module/daily-practice/${journalData?.previous_id?.id}`
      );
    } else if (journalData?.previous_id?.type === "journal") {
      navigate(
        `/course-internal/module/my-journal/${journalData?.previous_id?.id}`
      );
    } else if (journalData?.previous_id?.type === "quiz") {
      navigate(`/course-internal/module/quiz/${journalData?.previous_id?.id}`);
    } else if (journalData?.previous_id?.type === "stella_assessment") {
      navigate(
        `/course-internal/talk-to-stella?assessmentID=${journalData?.previous_id?.id}`
      );
    } else if (journalData?.previous_id?.type === "mood_monitor") {
      navigate(
        `/course-internal/module/mood-monitor?id=${journalData?.previous_id?.id}`
      );
    } else if (journalData?.previous_id?.type === "html") {
      navigate(
        `/course-internal/module/article/${journalData?.previous_id?.id}`
      );
    }
  };

  const handleNextButton = () => {
    if (journalData?.next_id?.type === "video") {
      navigate(`/course-internal/module/${journalData?.next_id?.id}`);
    } else if (journalData?.next_id?.type === "audio") {
      navigate(`/course-internal/module/${journalData?.next_id?.id}`);
    } else if (journalData?.next_id?.type === "checksheet") {
      navigate(
        `/course-internal/module/daily-practice/${journalData?.next_id?.id}`
      );
    } else if (journalData?.next_id?.type === "journal") {
      navigate(
        `/course-internal/module/my-journal/${journalData?.next_id?.id}`
      );
    } else if (journalData?.next_id?.type === "quiz") {
      navigate(`/course-internal/module/quiz/${journalData?.next_id?.id}`);
    } else if (journalData?.next_id?.type === "stella_assessment") {
      navigate(
        `/course-internal/talk-to-stella?assessmentID=${journalData?.next_id?.id}`
      );
    } else if (journalData?.next_id?.type === "mood_monitor") {
      navigate(
        `/course-internal/module/mood-monitor?id=${journalData?.next_id?.id}`
      );
    } else if (journalData?.next_id?.type === "html") {
      navigate(`/course-internal/module/article/${journalData?.next_id?.id}`);
    }
  };

  const handlePrevModuleButton = () => {
    navigate(
      `/course-internal/course-view/${journalData?.previous_module_id?.id}`
    );
  };

  const handleNextModuleButton = () => {
    navigate(`/course-internal/course-view/${journalData?.next_module_id?.id}`);
  };

  const handleBookmarkClick = () => {
    const bBody = { sub_level_id: id };
    addBookmarkEntry(bBody).then((resp) => {
      if (resp?.ResponseCode === 200) {
        // setBookmarkDataStatus(resp?.data);
        //console.log(resp?.data?.bookmarked)
        setIsBookmarked(resp?.data?.bookmarked);
        // console.log("resp?.dataresp?.data", resp?.data);
      } else {
        //console.log(resp?.ResponseMessage);
      }
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <section className="journal">
      <Helmet>
        <title>Program | Journal</title>
      </Helmet>
      {isLoading ? (
        <div
          className="d-flex align-items-center justify-content-center mx-auto"
          style={{ minHeight: "100vh" }}
        >
          <img alt="" src="/assets/img/kareify/loader.gif" />
        </div>
      ) : (
        <>
          <section className="journal-sidebar">
            <Sidebar />
          </section>
          <SidebarMobile />
          <section className="journal-section">
            <div className="journal-bg-img">
              <img src={section_bg_img} alt="" loading="lazy" />
            </div>
            <div className="journal-container">
              <div className="journal-sections-heading">
                {journalData?.course_name}
                <span>
                  {" > "} {journalData?.content?.name}
                </span>
              </div>
              <div className="sections-progress">
                Section {journalData?.current_level_index + 1}/
                {journalData?.level_count}
              </div>
              <div className="journal-section-name">
                <div style={{ width: "calc(100% - 20px)" }}>
                  {journalData?.content?.name}
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img
                    src={info_icon}
                    alt=""
                    loading="lazy"
                    className="quiz-info-icon"
                    onClick={() => setShowModal(true)}
                  />
                  <Popup
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    data={modal_data[0]}
                  />
                  <div
                    className="quiz-save"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleBookmarkClick()}
                  >
                    {isBookmarked ? (
                      <BookmarkIcon color="#541cf5" />
                    ) : (
                      <BookmarkBorderOutlinedIcon />
                    )}
                  </div>
                </div>
              </div>
              <hr style={{ color: "#E0E0E0" }} />
              <div className="sections-desc">{journalData?.description}</div>
              <div className="journal-entries">
                <div className="new-entry">
                  <div className="new-entry-title">Add a new journal entry</div>
                  <div className="new-entry-input-title">
                    <input
                      type="text"
                      className="title-input"
                      name="title"
                      value={title}
                      placeholder="Add title*"
                      onChange={(e) => handleTitleChange(e)}
                    />
                  </div>
                  <div className="new-entry-input-desc">
                    <div
                      style={{
                        height: "14.6875rem",
                        overflow: "hidden",
                      }}
                    >
                      <textarea
                        className="text-input"
                        value={description}
                        name="thoughts"
                        placeholder="Write your thoughts*"
                        rows="50"
                        cols="50"
                        onChange={(e) => handleDescChange(e)}
                      ></textarea>
                    </div>
                  </div>
                  <button
                    className="new-entry-btn"
                    style={
                      title.trim() === "" || description.trim() === ""
                        ? { backgroundColor: "#73589b75" }
                        : {}
                    }
                    onClick={handleSubmitJournal}
                    disabled={title.trim() === "" || description.trim() === ""}
                  >
                    Submit
                  </button>
                </div>
                {journalData?.content?.entries?.length > 0 && (
                  <div className="previous-entries mt-2">
                    <div className="prev-entries-header">
                      <div className="prev-entries-title">Previous Entries</div>
                    </div>
                    <div className="prev-entries-items ">
                      {journalData?.content?.entries?.map((item, i) => (
                        <div className="prev-entries-item">
                          <div className="prev-item-date">{item.date}</div>
                          <div className="prev-item-content">
                            <div className="prev-item-text">
                              <div className="prev-item-title">
                                <img
                                  src={
                                    selected === i
                                      ? see_less_icon
                                      : see_more_icon
                                  }
                                  alt=""
                                  loading="lazy"
                                  className="see-more-icon"
                                  onClick={() => toggle(i)}
                                />
                                <div className="prev-item-title-text">
                                  {i === selected
                                    ? item.title
                                    : `${item.title.slice(0, 18)}...`}
                                </div>
                              </div>
                              <div
                                className={
                                  selected === i
                                    ? "prev-item-desc show"
                                    : "prev-item-desc"
                                }
                              >
                                {/* {isEditing ? (
                            <div className="add-step-textarea">
                              <textarea
                                className="add-step-text"
                                value={textValue}
                                onChange={(e) => setTextValue(e.target.value)}
                                rows="1"
                                cols="30"
                              />
                              <div
                                className="add-step-save-text"
                                onClick={handleSaveClick}
                              >
                                Save
                              </div>
                            </div>
                          ) : (
                            <p>{item.desc}</p>
                          )} */}
                                <p>{item?.description}</p>
                              </div>
                            </div>

                            {/* <div className="prev-item-icons">
                        <img
                          src={edit_icon}
                          alt=""
                          loading="lazy"
                          className="edit-icon"
                          onClick={() => handleEditJournal(item)}
                        />
                        <img
                          src={dlt_icon}
                          alt=""
                          loading="lazy"
                          className="dlt-icon"
                          onClick={() => handleDeleteJournal(item)}
                        />
                      </div> */}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="mood-monitor-btn">
                <div>
                  {journalData?.previous_id.type && (
                    <div className="prev-btn" onClick={handlePrevButton}>
                      <div className="modules-prev-text">Previous</div>
                      <div className="prev-arrow">
                        <img
                          src={left_arrow}
                          alt=""
                          loading="lazy"
                          className="arrow-img"
                        />
                      </div>
                    </div>
                  )}
                  {journalData?.previous_module_id?.id &&
                    !journalData?.previous_id?.type && (
                      <div
                        className="prev-btn"
                        onClick={handlePrevModuleButton}
                      >
                        <div className="modules-text">Previous Module</div>
                        <div className="prev-arrow">
                          <img
                            src={left_arrow}
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                          />
                        </div>
                      </div>
                    )}
                </div>

                <div>
                  {journalData?.next_id.type && (
                    <div className="next-btn" onClick={handleNextButton}>
                      <div className="modules-text">Next</div>
                      <div className="next-arrow">
                        <img
                          src={arrow}
                          alt=""
                          loading="lazy"
                          className="arrow-img"
                        />
                      </div>
                    </div>
                  )}
                  {journalData?.next_module_id?.id &&
                    !journalData?.next_id?.type && (
                      <div
                        className="next-btn"
                        onClick={handleNextModuleButton}
                      >
                        <div className="modules-text">Next Module</div>
                        <div className="next-arrow">
                          <img
                            src={arrow}
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </section>

          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              sx={{ width: "100%", background: "#111", color: "#FFF" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </>
      )}
    </section>
  );
};

export default Journal;
