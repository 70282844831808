import React, { useState, useEffect, useRef } from "react";
import {
  Navbar,
  Nav,
  Button,
  NavDropdown,
  Container,
  Offcanvas,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useAuth } from "../auth";
import { SignInDrawer } from "./SignInDrawer";
import { AppContext } from "../context";
import { useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./Header.css";
import LoggedInNavbar from "../components/dashboard/Components/Dashboard/Navbar";
import SignInComponent from "./SignInComponent";
import { validate_token } from "./core/_request";

const searchPlaceholder = ["an Expert", "a course", "a blog"];

function Header2() {
  const { currentUser, userType, logout } = useAuth();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const [changingPlaceholder, setChangingPlaceholder] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [b2BImage, setB2BImage] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const search = useLocation().search;
  const token_url = new URLSearchParams(search).get("token");
  const searchParams = new URLSearchParams(location.search);

  const [previousLocation, setPreviousLocation] = useState(null);

  const stellaAPIKey = process.env.REACT_APP_STELLAAPIKEY;
  const baseURL = "https://odoo.unitedwecare.ca";

  // const headerSearch = (e) => {
  // debugger
  // if (e.key === 'Enter') {
  //   window.location.href = `https://www.unitedwecare.com/search/?query=${searchText}`
  // } else {
  //   setSearchText(e.target.value);
  // }
  // }
  useEffect(() => {
    const profileData = localStorage.getItem("profile");
    // console.log("profileData", profileData);

    const parsedProfile = JSON.parse(profileData);
    //console.log("parsedProfile", parsedProfile);

    const headerImage = parsedProfile?.header_image;
    // console.log("headerImage", headerImage);

    if (headerImage) {
      setB2BImage(headerImage);
    }
  }, [b2BImage]);

  useEffect(() => {
    // console.log("validate_token calling !!!!!!!!", token_url);
    if (token_url) {
      validate_token(token_url).then((resp) => {
        //console.log("resp", resp);
        if (resp?.ResponseCode === 200) {
          localStorage.setItem("Authorization", resp?.data?.usertoken);

          localStorage.setItem(
            "profile",
            JSON.stringify(resp?.data?.userdetails)
          );

          searchParams.delete("token");
          // Convert the searchParams object back to a string
          const searchString = searchParams.toString();
          // Split the parameters
          const paramsArray = searchString.split("&");

          // Filter out the token parameter
          const filteredParamsArray = paramsArray.filter(
            (param) => !param.startsWith("token=")
          );

          // Reconstruct the query string
          const queryString = filteredParamsArray
            .join("&")
            .replace(/\+/g, "%20");

          // Create the updated URL

          const updatedURL = `${location.pathname}?${queryString}${location.hash}`;

          window.location.href = updatedURL;
          // window.location.href = window.location.href = window.location.origin + window.location.pathname;

          let now = new Date();
          let oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
          let firstName = resp?.data?.userdetails?.Name.split(" ")
            .slice(0, 1)
            .join("");

          // if (resp?.data?.userdetails.header_image) {
          //   navigate("/user-dashboard");
          // }
          //saveCookie(firstName, oneYearFromNow);

          //console.log(firstName, oneYearFromNow.toUTCString())

          document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toUTCString()};domain=.unitedwecare.com;path=/;`;
        }
      });
    }
  }, []);

  const onHeaderSearchIcon = () => {
    window.location.href = `https://www.unitedwecare.com/search/?query=${searchText}`;
  };

  const handleEnterKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      onHeaderSearchIcon(); // Trigger the search function
    }
  };

  //const [show, setShow] = useState(false);
  const onLogout = () => {
    //console.log("logout")
    window.location.href = "https://www.unitedwecare.com/";
    logout();
    // navigate("/");
  };

  useEffect(() => {
    const timer = () => {
      setChangingPlaceholder((prevIndex) => {
        if (prevIndex === searchPlaceholder.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      });
    };
    setInterval(timer, 5000);

    //cleanup function in order clear the interval timer
    //when the component unmounts
    return () => {
      clearInterval(timer);
    };
  }, []);

  const togglePopup = (state) => {
    setMenuOpen(false);
    setShowLloginPopup(state);
  };
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => setMenuOpen(false);

  useEffect(() => {
    if (
      previousLocation &&
      previousLocation.pathname == "/onboarding-stella" &&
      location.pathname !== "/onboarding-stella"
    ) {
      // Alert or perform any other action when the user navigates from the previous location
      //console.log("Kimochi!!")

      const headers = {
        "Content-Type": "application/json",
        "X-API-Key": stellaAPIKey,
      };

      const sessionID = sessionStorage.getItem("stella_s_id");

      const body = {
        senderId: sessionID,
      };

      const getStellaClose = async () => {
        try {
          //setIsLoading(true);

          const response = await fetch(
            `${baseURL}/new-uwc/stella/close/session`,
            {
              method: "POST",
              headers,
              body: JSON.stringify(body),
            }
          );

          if (!response.ok) {
            // Handle error response
            throw new Error("Error fetching languages");
          }

          const jsonData = await response.json();
          console.log("RESPONSE", response);

          if (response.status == 200) {
            console.log("CLEAR");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      getStellaClose();
    }

    // Save the current location as the previous location
    setPreviousLocation(location);
  }, [location, previousLocation]);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  // const urlSplit = window.location.href.split("?")[0].split("/").includes("user-dashboard");

  return (
    <>
      {(!currentUser && (
        <div
          style={{
            background: "#FFFFFF",
            boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.09)",
          }}
          className="header-container kareify-Menu-header d-none"
        >
          <Navbar key={"sm"} expand={"sm"} className={`col-12 Header--2 p-0`}>
            <div
              className="d-flex align-items-center justify-content-between col-12 my-2 my-md-0"
              style={{ padding: "0px 5%" }}
            >
              {/* logo Desktop */}
              <Navbar.Brand className="d-none d-md-flex gap-4 align-items-center kareify-logo-container p-0">
                <a
                  href="https://www.unitedwecare.com/"
                  className="text-decoration-none"
                >
                  <img
                    src="/assets/img/kareify/KareifyMobilelogo1.png"
                    alt=""
                    width={150}
                    height={65}
                    style={{ objectFit: "contain" }}
                  />
                </a>
              </Navbar.Brand>
              {/* logo Mobile */}
              <Navbar.Brand className="d-block d-md-none">
                <a
                  href="https://www.unitedwecare.com/"
                  className="text-decoration-none"
                >
                  <img
                    src="/assets/img/kareify/KareifyMobilelogo1.png"
                    alt=""
                    width={102}
                    style={{ objectFit: "contain" }}
                  />
                </a>
              </Navbar.Brand>

              {b2BImage && (
                <img
                  src={b2BImage}
                  alt=""
                  width={110}
                  height={65}
                  style={{ objectFit: "contain" }}
                />
              )}

              {/* Angel One Logo */}
              {b2BImage && (
                <Navbar.Brand className="d-block d-md-none">
                  <a href="" className="text-decoration-none">
                    <img
                      src={b2BImage}
                      alt=""
                      style={{ objectFit: "contain" }}
                    />
                  </a>
                </Navbar.Brand>
              )}
              <>
                <Navbar.Toggle
                  aria-controls={"offcanvasNavbar-expand-sm"}
                  onClick={toggleMenu}
                />
                <Navbar.Offcanvas
                  id={"offcanvasNavbar-expand-sm"}
                  aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                  placement="top"
                  // style={{ backgroundColor: "#C4E5F9" }}
                  className="header-offcanvas"
                  restoreFocus={false}
                  show={menuOpen}
                  onHide={handleClose}
                >
                  <Offcanvas.Header closeButton>
                    <Navbar.Brand className="d-block d-md-none">
                      <a
                        href="https://www.kareify.com/"
                        className="text-decoration-none"
                      >
                        <img
                          src="/assets/img/kareify/KareifyMobilelogo1.png"
                          alt=""
                          width={102}
                          style={{ objectFit: "contain" }}
                        />
                      </a>
                    </Navbar.Brand>
                  </Offcanvas.Header>

                  <Nav className="offcanvas-body align-items-baseline align-items-md-center justify-content-center px-4">
                    {/* Mobile View Start */}
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Employers"
                      className="font-inter text-decoration-none lh-lg d-block d-md-none mx-1 dropdown-header"
                      // menuVariant="dark"
                    >
                      <div className="kareify-menu-companySize-text">
                        Company Size
                      </div>
                      <NavDropdown.Item href="https://www.unitedwecare.com/employers/">
                        Small Business
                        <br />
                        <span className="small-business-text">
                          Less than 100 employees
                        </span>
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/employers-large-business/">
                        Med/Large Business
                        <br />
                        <span className="small-business-text">
                          100+ employees
                        </span>
                      </NavDropdown.Item>
                      <div className="kareify-menu-companySize-text">
                        By Industry
                      </div>
                      <NavDropdown.Item href="https://www.unitedwecare.com/business-services/">
                        Business Services
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/consumer-services/">
                        Consumer Services
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/technology/">
                        Technology
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/retail/">
                        Retail
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/finance/">
                        Finance
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link
                      className="mx-1 d-block d-md-none text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/insurance/"
                    >
                      Insurance
                    </Nav.Link>
                    <Nav.Link
                      className="mx-1 d-block d-md-none text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/alliance/"
                    >
                      Alliance Partners
                    </Nav.Link>
                    <Nav.Link
                      className="mx-1 d-block d-md-none text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/healthcare-hospitals/"
                    >
                      Healthcare Systems
                    </Nav.Link>
                    <Nav.Link
                      className="mx-1 d-block d-md-none text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href=""
                    >
                      Individuals
                    </Nav.Link>
                    {/* Mobile View End */}
                    {/* Desktop View Start */}
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Employers"
                      className="kareify-Menu-header-text font-inter text-decoration-none lh-lg d-none d-md-block mx-1 dropdown-header"
                      show={showDropdown}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      // menuVariant="dark"
                    >
                      <div className="kareify-menu-companySize-text">
                        Company Size
                      </div>
                      <NavDropdown.Item href="https://www.unitedwecare.com/employers/">
                        Small Business
                        <br />
                        <span className="small-business-text">
                          Less than 100 employees
                        </span>
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/employers-large-business/">
                        Med/Large Business
                        <br />
                        <span className="small-business-text">
                          100+ employees
                        </span>
                      </NavDropdown.Item>
                      <div className="kareify-menu-companySize-text">
                        By Industry
                      </div>
                      <NavDropdown.Item href="https://www.unitedwecare.com/business-services/">
                        Business Services
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/consumer-services/">
                        Consumer Services
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/technology/">
                        Technology
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.unitedwecare.com/retail/">
                        Retail
                      </NavDropdown.Item>
                      <hr className="m-0" />
                      <NavDropdown.Item href="https://www.kareify.com/finance/">
                        Finance
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link
                      className="kareify-Menu-header-text mx-1 d-none d-md-block text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/insurance/"
                    >
                      Insurance
                    </Nav.Link>
                    <Nav.Link
                      className="kareify-Menu-header-text mx-1 d-none d-md-block text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/alliance/"
                    >
                      Alliance Partners
                    </Nav.Link>
                    <Nav.Link
                      className="kareify-Menu-header-text mx-1 d-none d-md-block text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/healthcare-hospitals/"
                    >
                      Healthcare Systems
                    </Nav.Link>
                    <Nav.Link
                      className="kareify-Menu-header-text mx-1 d-none d-md-block text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="/plans"
                    >
                      Individuals
                    </Nav.Link>
                    {/* <Nav.Link onClick={() => {togglePopup(true);toggleMenu();}} className="text-white d-block d-md-none"><Link className="text-decoration-none lh-lg"><Button className='text-black border-0 rounded-4' style={{ backgroundColor: "#EDEFEB" }}>Login</Button></Link></Nav.Link> */}
                    {/* Desktop View End */}
                    {/* {currentUser && (<Nav.Link className="mx-2 d-none d-md-block"><Link to="/onboarding-stella" className='text-decoration-none lh-lg'>Stella</Link></Nav.Link>)} */}

                    {/* <Nav.Link onClick={() => {togglePopup(true);toggleMenu();}} className="text-white"><Link className="text-decoration-none lh-lg"><Button className='text-black border-0 rounded-4' style={{ backgroundColor: "#EDEFEB" }}>Login</Button></Link></Nav.Link> */}
                  </Nav>
                  <Modal
                    show={ShowLloginPopup}
                    onHide={() => togglePopup(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    className="m-0 col-4 signInModal"
                    style={{ height: "100vh" }}
                  >
                    <SignInDrawer />
                    {/* <SignInComponent /> */}
                  </Modal>
                </Navbar.Offcanvas>
              </>
              <div className="align-items-center gap-3 d-none d-md-flex">
                <Form
                  className="d-none d-md-flex align-items-center ms-2 rounded-3"
                  style={{ border: "1px solid #73589B", height: "45px" }}
                >
                  <InputGroup>
                    <SearchIcon
                      sx={{
                        color: "#73589B",
                        display: "block",
                        marginY: "auto",
                        marginLeft: "10px",
                        fontSize: "14px",
                      }}
                      onClick={onHeaderSearchIcon}
                    />
                    <FormControl
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyDown={handleEnterKeyDown}
                      type="search"
                      className="ms-2 border-0 kareify-headerSearch"
                      style={{ borderRight: "none" }}
                      placeholder={`Search for ${searchPlaceholder[changingPlaceholder]}`}
                    />
                    {/* <InputGroup.Text className="border-0" style={{ background: "none" }} > */}

                    {/* </InputGroup.Text> */}
                  </InputGroup>
                </Form>
                <Nav.Link
                  onClick={() => {
                    togglePopup(true);
                  }}
                  className="text-white"
                >
                  <Link className="text-decoration-none lh-lg">
                    <Button
                      id="header_login"
                      className="text-white border-0 rounded-3 px-4 font-inter"
                      style={{
                        backgroundColor: "#73589B",
                        height: "45px",
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      Login
                    </Button>
                  </Link>
                </Nav.Link>
              </div>
            </div>
            {/* <div className="d-flex d-md-none flex-column align-items-center justify-content-center col-12">
              <Form
                className="d-flex d-md-none align-items-center justify-content-center rounded-2 col-11 my-2"
                style={{ border: "0.5px solid #541CF5", height: "45px" }}
              >
                <InputGroup>
                  <FormControl
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={handleEnterKeyDown}
                    type="search"
                    className="ms-2 border-0 headerSearch"
                    style={{ borderRight: "none" }}
                    placeholder={`Search for ${searchPlaceholder[changingPlaceholder]}`}
                  />
                  <SearchIcon
                    sx={{
                      color: "#541CF5",
                      display: "block",
                      marginY: "auto",
                      marginRight: "10px",
                    }}
                    onClick={onHeaderSearchIcon}
                  />
                </InputGroup>
              </Form>
            </div> */}
          </Navbar>
        </div>
      )) || <LoggedInNavbar />}
    </>
  );
}

export default Header2;
