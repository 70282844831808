import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import {
  emoji_angry,
  emoji_anxious,
  emoji_sad,
  emoji_happy,
  emoji_unsure,
  delete_icon,
  disappointed_face,
  arrow,
  left_arrow,
  module_bg_img,
  info_icon,
  angry,
  disgust,
  happy,
  sad,
  shocked,
  surprised,
} from "../assets";
import Popup from "./Modal";
import MoodBar from "./MoodBar";
import { getMoodData, removeMoodTrackerRecord } from "../_request";
import SidebarMobile from "./SidebarMobile";
import { useAuth } from "../../../auth";
import { courseInternalContentView } from "../_request";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const modal_data = [
  {
    title: "Mood Monitor",
    desc: "Keep a tab on your emotions with our mood monitor.",
    subHead:
      "You can select an emoticon which is closest to how you feel today and keep a check on how you've been feeling over a period of time.",
  },
];

const moods = [
  {
    emoji: disappointed_face,
    title: "Sad",
    date: "6th Sept, 14:58",
  },
  {
    emoji: disappointed_face,
    title: "Sad",
    date: "6th Sept, 14:58",
  },
  {
    emoji: disappointed_face,
    title: "Sad",
    date: "6th Sept, 14:58",
  },
  {
    emoji: disappointed_face,
    title: "Sad",
    date: "6th Sept, 14:58",
  },
  {
    emoji: disappointed_face,
    title: "Sad",
    date: "6th Sept, 14:58",
  },
];

const MoodMonitor = () => {
  const [moodData, setMoodData] = useState("");
  const [moodMonitorData, setMoodMonitorData] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;

  const { currentUser, userType, logout } = useAuth();
  // const { id } = useParams();
  const id = new URLSearchParams(window.location.href.split('?')[1]).get("id");

  const navigate = useNavigate();

  useEffect(() => {
    fetchMoodSummary();
  }, [moodMonitorData]);

  useEffect(() => {
    let body = {
      userId: currentUser?.UserId,
      subLevelId: id,
    };
    courseInternalContentView(body).then((resp) => {
      if (resp?.ResponseCode == 200) {
        setMoodMonitorData(resp?.data);
        // console.log("resp", resp)
      }
    });

    setIsLoading(false);
  }, [id]);

  const fetchMoodSummary = () => {
    const body = {
      user_id: user_id,
      course_id: moodMonitorData?.course_data_id,
    };
    getMoodData(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setMoodData(resp?.data);
      } else {
        console.error(resp?.ResponseMessage);
      }
    });
  };

  const handelDelete = (item) => {
    var body = { user_id: user_id, course_id: 644, mood_tracker_id: item };
    removeMoodTrackerRecord(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        alert(resp?.data.message);
        window.location.reload();
      } else {
        console.log(resp?.ResponseMessage);
      }
    });
  };

  const handlePrevButton = () => {
    if (moodMonitorData?.previous_id?.type === "video") {
      navigate(`/course-internal/module/${moodMonitorData?.previous_id?.id}`);
    } else if (moodMonitorData?.previous_id?.type === "audio") {
      navigate(`/course-internal/module/${moodMonitorData?.previous_id?.id}`);
    } else if (moodMonitorData?.previous_id?.type === "checksheet") {
      navigate(
        `/course-internal/module/daily-practice/${moodMonitorData?.previous_id?.id}`
      );
    } else if (moodMonitorData?.previous_id?.type === "journal") {
      navigate(
        `/course-internal/module/my-journal/${moodMonitorData?.previous_id?.id}`
      );
    } else if (moodMonitorData?.previous_id?.type === "quiz") {
      navigate(
        `/course-internal/module/quiz/${moodMonitorData?.previous_id?.id}`
      );
    } else if (moodMonitorData?.previous_id?.type === "stella_assessment") {
      navigate(
        `/course-internal/talk-to-stella?assessmentID=${moodMonitorData?.previous_id?.id}`
      );
    } else if (moodMonitorData?.previous_id?.type === "mood_monitor") {
      navigate(
        `/course-internal/module/mood-monitor?id=${moodMonitorData?.previous_id?.id}`
      );
    } else if (moodMonitorData?.previous_id?.type === "html") {
      navigate(`/course-internal/module/article/${moodMonitorData?.previous_id?.id}`);
    }
  };

  const handleNextButton = () => {
    if (moodMonitorData?.next_id?.type === "video") {
      navigate(`/course-internal/module/${moodMonitorData?.next_id?.id}`);
    } else if (moodMonitorData?.next_id?.type === "audio") {
      navigate(`/course-internal/module/${moodMonitorData?.next_id?.id}`);
    } else if (moodMonitorData?.next_id?.type === "checksheet") {
      navigate(
        `/course-internal/module/daily-practice/${moodMonitorData?.next_id?.id}`
      );
    } else if (moodMonitorData?.next_id?.type === "journal") {
      navigate(
        `/course-internal/module/my-journal/${moodMonitorData?.next_id?.id}`
      );
    } else if (moodMonitorData?.next_id?.type === "quiz") {
      navigate(`/course-internal/module/quiz/${moodMonitorData?.next_id?.id}`);
    } else if (moodMonitorData?.next_id?.type === "stella_assessment") {
      navigate(
        `/course-internal/talk-to-stella?assessmentID=${moodMonitorData?.next_id?.id}`
      );
    } else if (moodMonitorData?.next_id?.type === "mood_monitor") {
      navigate(
        `/course-internal/module/mood-monitor?id=${moodMonitorData?.next_id?.id}`
      );
    } else if (moodMonitorData?.next_id?.type === "html") {
      navigate(`/course-internal/module/article/${moodMonitorData?.next_id?.id}`);
    }
  };

  const handlePrevModuleButton = () => {
    navigate(
      `/course-internal/course-view/${moodMonitorData?.previous_module_id?.id}`
    );
  };

  const handleNextModuleButton = () => {
    navigate(
      `/course-internal/course-view/${moodMonitorData?.next_module_id?.id}`
    );
  };

  const moodAddCallback = () => {
    fetchMoodSummary();
  };

  //console.log("moodMonitorData", moodMonitorData);
  return (
    <section style={{ display: "flex", overflowX: "hidden", height: "100%" }}>
      <Helmet>
        <title>Program | Mood monitor</title>
      </Helmet>

      {isLoading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            height: "100vh",
            width: "100vw",
            background: "#FFF",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: "1000",
          }}
        >
          <img alt="" src="/assets/img/kareify/loader.gif" width={250} />
        </div>
      ) : (
        <>
          <section className="mood-monitor-sidebar">
            <Sidebar />
          </section>
          <SidebarMobile />
          <section className="mood-monitor-section">
            <div className="mood-monitor-container">
              <div className="mood-monitor-bg-img">
                <img src={module_bg_img} alt="" loading="lazy" />
              </div>
              {moodMonitorData !== null && (
                <>
                  <div className="sections-heading">
                    {moodMonitorData?.course_name}
                    <span>
                      {" >"} {moodMonitorData?.content?.name}
                    </span>
                  </div>

                  <div className="sections-progress">
                    Section {moodMonitorData?.current_level_index + 1}/
                    {moodMonitorData?.level_count}
                  </div>
                </>
              )}

              <div className="mood-monitor ">
                {/* <div className="sections-heading">
              Space from Depression and Anxiety
              <span style={{ color: "#541CF5" }}>{" >"} Getting Started</span>
            </div>
            <div className="sections-progress">Section 4/6</div> */}
                <div className="mood-monitor-name">
                  <h2>Mood Monitor</h2>
                  <img
                    src={info_icon}
                    alt=""
                    loading="lazy"
                    className="info-icon"
                    onClick={() => setShowModal(true)}
                  />
                  <Popup
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    data={modal_data[0]}
                  />
                </div>
                <div className="mood-monitor-desc">
                  The first step towards understanding how you are feeling is to
                  track your moods.
                </div>
                <div>
                  <MoodBar
                    moodCallback={moodAddCallback}
                    courseID={moodMonitorData?.course_data_id}
                  />
                </div>
                <div className="mood-analysis-heading">
                  <div>Mood analysis</div>
                </div>
                <div className="mood-analysis-container">
                  {moodData?.mood_summary
                    ?.sort((a, b) => b.id - a.id)
                    .map((mood, i) => (
                      <div className="mood-analysis-item">
                        <div className="mood-analysis-text">
                          <img
                            alt=""
                            style={{ background: "#79579e", borderRadius: '10px' }}
                            src={
                              (mood?.name === "Angry" &&
                                "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Femojis%2FAngry.png?alt=media") ||
                              ((mood?.name === "Disgusted" ||
                                mood?.name === "Disgust") &&
                                "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Femojis%2FDisgusted.png?alt=media") ||
                              (mood?.name === "Happy" &&
                                "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Femojis%2FHappy.png?alt=media") ||
                              (mood?.name === "Sad" &&
                                "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Femojis%2FSad.png?alt=media") ||
                              (mood?.name === "Afraid" &&
                                "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Femojis%2FAfraid.png?alt=media") ||
                              (mood?.name === "Surprised" &&
                                "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2Femojis%2FSurprised.png?alt=media")
                            }
                            className="mood-monitor-emoji"
                          />
                          <p className="mood-icon">{mood?.name}</p>
                          <p>{mood?.date_time}</p>
                        </div>
                        <div onClick={() => handelDelete(mood?.id)}>
                          <img src={delete_icon} className="mood-dlt-icon" />
                        </div>
                      </div>
                    ))}
                </div>
                <div className="mood-monitor-btn">
                  <div>
                    {moodMonitorData?.previous_id.type && (
                      <div className="prev-btn" onClick={handlePrevButton}>
                        <div className="modules-prev-text">Previous</div>
                        <div className="prev-arrow">
                          <img
                            src={left_arrow}
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                          />
                        </div>
                      </div>
                    )}
                    {moodMonitorData?.previous_module_id?.id &&
                      !moodMonitorData?.previous_id?.type && (
                        <div
                          className="prev-btn"
                          onClick={handlePrevModuleButton}
                        >
                          <div className="modules-text">Previous Module</div>
                          <div className="prev-arrow">
                            <img
                              src={left_arrow}
                              alt=""
                              loading="lazy"
                              className="arrow-img"
                            />
                          </div>
                        </div>
                      )}
                  </div>
                  <div>
                    {moodMonitorData?.next_id.type && (
                      <div
                        className="next-btn"
                        style={{ marginLeft: "auto" }}
                        onClick={handleNextButton}
                      >
                        <div className="modules-text">Next</div>
                        <div className="next-arrow">
                          <img
                            src={arrow}
                            alt=""
                            loading="lazy"
                            className="arrow-img"
                          />
                        </div>
                      </div>
                    )}
                    {moodMonitorData?.next_module_id?.id &&
                      !moodMonitorData?.next_id?.type && (
                        <div
                          className="next-btn"
                          onClick={handleNextModuleButton}
                        >
                          <div className="modules-text">Next Module</div>
                          <div className="next-arrow">
                            <img
                              src={arrow}
                              alt=""
                              loading="lazy"
                              className="arrow-img"
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </section>
  );
};

export default MoodMonitor;
