import React, { useEffect, useState } from "react";
import "../styles/Modules.css";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import Sidebar from "./Sidebar";
import {
  module_bg_img,
  left_arrow,
  arrow,
  home_wp,
  home_copy,
  completed_icon,
} from "../assets";
import { useNavigate, useParams } from "react-router-dom/dist";
import { addBookmarkEntry, getCourseContent } from "../_request";
import { menu_book } from "@mui/icons-material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import SidebarMobile from "./SidebarMobile";
import { Helmet } from "react-helmet";
const data = [
  {
    icon: <VideocamOutlinedIcon />,
    text: "Welcome to United We Care!",
  },
  {
    icon: <VideocamOutlinedIcon />,
    text: "Start questionnaire with STELLA!",
  },
  {
    icon: <DescriptionOutlinedIcon />,
    text: "How can CBT help you?",
  },
  {
    icon: <SentimentSatisfiedOutlinedIcon />,
    text: "Mood Monitor",
  },
  {
    icon: <MenuBookIcon />,
    text: "Checksheet",
  },
  {
    icon: <HeadphonesOutlinedIcon />,
    text: "How to overcome depression?",
  },
];

const bookmarkIconSvg = () => {
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_1665_6571)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 5C4 4.20435 4.31607 3.44129 4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2H17C17.7956 2 18.5587 2.31607 19.1213 2.87868C19.6839 3.44129 20 4.20435 20 5V21.028C20 22.248 18.62 22.958 17.628 22.249L12 18.229L6.372 22.249C5.379 22.959 4 22.249 4 21.029V5ZM7 4C6.73478 4 6.48043 4.10536 6.29289 4.29289C6.10536 4.48043 6 4.73478 6 5V20.057L11.128 16.394C11.3824 16.2122 11.6873 16.1145 12 16.1145C12.3127 16.1145 12.6176 16.2122 12.872 16.394L18 20.057V5C18 4.73478 17.8946 4.48043 17.7071 4.29289C17.5196 4.10536 17.2652 4 17 4H7Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1665_6571">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>;
};

const Modules = () => {
  const [active, setActive] = useState("true");
  const [isBookmarked, setBookmarked] = useState(false);
  const [courseContent, setCourseContent] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentLevelId, setCurrentLevelId] = useState(id); // Track the current level ID
  const [bookmarkDataStatus, setBookmarkDataStatus] = useState();
  const [isLoading, setIsLoading] = useState(true);
  // const activeHandler() => {
  //   if(active === )
  // }

  useEffect(() => {
    const body = { level_id: currentLevelId };
    setIsLoading(true)
    getCourseContent(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setCourseContent(resp?.data);
        //console.log("resp?.dataresp?.data www", resp?.data);
        setIsLoading(false);
      } else {
        console.error(resp?.ResponseMessage);
      }
    });
  }, [id, currentLevelId, bookmarkDataStatus]);

  const handleClickOnLevel = (item) => {
    if (item.type === "video") {
      navigate(`/course-internal/module/${item?.id}`);
    } else if (item.type === "audio") {
      navigate(`/course-internal/module/${item?.id}`);
    } else if (item.type === "checksheet") {
      navigate(`/course-internal/module/daily-practice/${item?.id}`);
    } else if (item.type === "journal") {
      navigate(`/course-internal/module/my-journal/${item?.id}`);
    } else if (item.type === "quiz") {
      navigate(`/course-internal/module/quiz/${item?.id}`);
    } else if (item.type === "stella_assessment") {
      navigate(`/course-internal/talk-to-stella?assessmentID=${item?.id}`);
    } else if (item.type === "html") {
      navigate(`/course-internal/module/article/${item?.id}`);
    } else if (item.type === "mood_monitor") {
      navigate(`/course-internal/module/mood-monitor?id=${item?.id}`);
    }
  };

  const handleNextModule = () => {
    // Increase the level_id and trigger the API call
    if (courseContent?.next_level) {
      setCurrentLevelId(courseContent?.next_level?.id);
      navigate(`/course-internal/course-view/${courseContent?.next_level?.id}`);
    }
  };

  const handlePreviousModule = () => {
    // Increase the level_id and trigger the API call
    if (courseContent?.prev_level) {
      setCurrentLevelId(courseContent?.prev_level?.id);
      navigate(`/course-internal/course-view/${courseContent?.prev_level?.id}`);
      
    }
  };

  const handleBookmarkClick = (item) => {
    const body = { sub_level_id: item.id };
    addBookmarkEntry(body).then((resp) => {
      if (resp?.ResponseCode === 200) {
        setBookmarkDataStatus(resp?.data);
        //console.log("resp?.dataresp?.data", resp?.data);
      } else {
        console.log(resp?.ResponseMessage);
      }
    });
    setBookmarked(!isBookmarked);
  };
  //console.log("courseContent?.customLevels", courseContent?.customLevels);

  return (
    <section style={{ display: "flex", overflow: "hidden" }}>
      <Helmet>
        <title>Program ! Module</title>
      </Helmet>
      {isLoading ? (
        <div
          className="d-flex align-items-center justify-content-center mx-auto"
          style={{ minHeight: "100vh" }}
        >
          <img alt="" src="/assets/img/kareify/loader.gif" />
        </div>
      ) : (
        <>
          <section className="modules-sidebar">
            <Sidebar />
          </section>
          <SidebarMobile />
          <section className="modules-section">
            <div>
              {/* <div className="home-upper-section">
            <div className="home-heading">
                <p className="home-heading1">
                  COURSE • SELF CARE • {courseContent?.preview_video?.duration}{" "}
                  WEEKS
                </p>
              </div>
              <div className="home-sub-heading">
                <p className="home-heading2">{courseContent?.title}</p>
                <div className="home-btns">
                  <img src={home_wp} alt="" loading="lazy" />
                  <img src={home_copy} alt="" loading="lazy" />
                </div>
              </div>
            </div> */}
              <div className="modules-bg-img">
                <img src={module_bg_img} alt="" loading="lazy" />
              </div>
              <div className="modules-container">
                <div className="modules-heading">
                  {courseContent?.course_name} {"  "}
                  <span className="">
                    {"  "}
                    {">"} {courseContent?.name}
                  </span>
                </div>

                <div className="modules-sub-heading">{courseContent?.name}</div>
                <hr style={{ color: "#E0E0E0" }} />

                <div className="modules-sections">
                  <div className="modules-sections-heading">Sections</div>

                  <div className="modules-sections-items">
                    {courseContent?.customLevels?.map((item, i) => {
                      if (item.type !== "worksheet") {
                        return (
                          <div className="modules-sections-item align-items-center">
                            <div
                              className="d-flex align-items-center gap-2"
                              style={{ flex: 1 }}
                              onClick={() => handleClickOnLevel(item)}
                            >
                              <span className="">
                                {(item?.type === "video" && (
                                  <VideocamOutlinedIcon />
                                )) ||
                                  (item?.type === "audio" && (
                                    <HeadphonesOutlinedIcon />
                                  )) ||
                                  (item?.type === "mood monitor" && (
                                    <SentimentSatisfiedOutlinedIcon />
                                  )) ||
                                  (item?.type === "checkSheet" && (
                                    <MenuBookIcon />
                                  )) || <DescriptionOutlinedIcon />}
                              </span>
                              <div className="modules-text">{item?.name}</div>
                              <div>
                                {item?.completed ? (
                                  <img
                                    src={completed_icon}
                                    alt=""
                                    width={20}
                                    height={20}
                                    className="module-completed-icon"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            {/* <div onClick={() => handleBookmarkClick(item)}>
                              {(!item?.bookmark && (
                                <BookmarkBorderOutlinedIcon
                                  className={`modules-bookmark ${isBookmarked ? "bookmarked" : ""
                                    }`}
                                />
                              )) || <BookmarkIcon color="#541cf5" />}
                            </div> */}
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>

                <div className="modules-btn">
                  {courseContent?.prev_level && (
                    <div className="prev-btn " onClick={handlePreviousModule}>
                      <div className="modules-text">Previous Module</div>
                      <div className="prev-arrow">
                        <img
                          src={left_arrow}
                          alt=""
                          loading="lazy"
                          className="arrow-img"
                        />
                      </div>
                    </div>
                  )}
                  {courseContent?.next_level && (
                    <div
                      className="next-btn ms-auto"
                      onClick={handleNextModule}
                    >
                      <div className="modules-text">Next Module</div>
                      <div className="next-arrow">
                        <img
                          src={arrow}
                          alt=""
                          loading="lazy"
                          className="arrow-img"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </section>
  );
};

export default Modules;
