import React, { useEffect, useState } from "react";
import Hero from "./Hero";
import Details from "./Details";
import WhatToExpect from "./WhatToExpect";
import Testimonials from "./Testimonials";
import Faq from "./Faq";
import RelatedCourses from "./RelatedCourses";
import { getCourseDetailsV2 } from "./_request";
import { useParams } from "react-router-dom";
import { useAuth } from "../../auth";
import { getDashboardCourseList_WebService } from "../core/_request";
import { Helmet } from "react-helmet";

const Course = () => {
  const [courseData, setCourseData] = useState(null);
  //const [myCoursesData, setMyCoursesData] = useState();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isPaid, setIsPaidCourse] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const { currentUser } = useAuth();

  useEffect(() => {
    const body = { courseId: id, userId: currentUser?.UserId || "" };
    getCourseDetailsV2(body).then((resp) => {
      if (resp.ResponseCode === 200) {
        setCourseData(resp?.data);
        setIsLoading(false);
      } else {
        console.log(resp?.ResponseMessage);
      }
    });

    // getDashboardCourseList_WebService({
    //   userId: currentUser?.UserId || "",
    // }).then((resp) => {
    //  // console.log("resp Data", resp.data)
    //   setMyCoursesData(resp.data);
    // });
  }, [id]);

  useEffect(() => {
    if (
      currentUser !== undefined &&
      currentUser !== null &&
      currentUser !== ""
    ) {
      setIsSubscribed(courseData?.enrolled);

      if (currentUser?.Country === "India") {
        if (courseData?.inr_price !== 0) {
          setIsPaidCourse(true);
        } else {
          setIsPaidCourse(false);
        }
      } else {
        if (courseData?.usd_price !== 0) {
          setIsPaidCourse(true);
        } else {
          setIsPaidCourse(false);
        }
      }
    } else {
      setIsSubscribed(false);
    }
  }, [currentUser]);

  //console.log("courseData", courseData)
  return (
    <div style={{ overflow: "hidden" }}>
      <Helmet>
        <title>Program | {courseData?.course_title || "Description"}</title>
      </Helmet>
      {isLoading ? (
         <div
         className="d-flex align-items-center justify-content-center"
         style={{ height: "100vh", width: '100vw', background: "#FFF", position: 'fixed', top: 0, left: 0, zIndex: '1000' }}
       >
         <img alt="" src="/assets/img/kareify/loader.gif" width={250} />
       </div>
      ) : (
        <>
          <Hero
            courseData={courseData}
            courseId={id}
            isSubscribed={isSubscribed}
            isPaid={isPaid}
          />
          <Details
            courseData={courseData}
            courseId={id}
            isSubscribed={isSubscribed}
            isPaid={isPaid}
          />
          <WhatToExpect
            courseData={courseData}
            courseId={id}
            isSubscribed={isSubscribed}
            isPaid={isPaid}
          />
          <Testimonials
            courseData={courseData}
            courseId={id}
            isSubscribed={isSubscribed}
            isPaid={isPaid}
          />
          <Faq />
          <RelatedCourses courseData={courseData} courseId={id} />
        </>
      )}
    </div>
  );
};

export default Course;
